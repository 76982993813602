import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';

const SelectTemplateDialog = ({
    open,
    title,
    templates,
    notSet,
    onSubmit,
    onClose
}) => {
    const [templateId, setTemplateId] = useState(-1);
    const { t } = useTranslation();

    const changeHandler = (e) => {
        setTemplateId(e.target.value);
    }

    const closeHandler = () => {        
        onClose();
        setTemplateId(-1);
    }

    const submitHandler = () => {        
        onSubmit(templateId);
        setTemplateId(-1);
    }

    return (
        <Dialog
            open={ open }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{ title }</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    { t('TemplateDialog.message') }
                </DialogContentText>
                <TextField
                    select
                    id="template_id"
                    name="template_id"
                    label={ t('EslView.template') }
                    value={ templateId }
                    onChange={ changeHandler }
                    margin="normal"
                    fullWidth>
                    { notSet &&
                        <MenuItem key={ -1 } value={ -1 }>
                            { t('EslView.notset') }</MenuItem>
                    }
                    { templates && templates.map(template => (
                        <MenuItem key={ template.id } value={ template.id }>
                            { `${template.name} (${template.color}, ${template.height}*${template.width})` }
                        </MenuItem>
                    )) }
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button onClick={ submitHandler } variant="contained" color="primary">
                    { t('TemplateDialog.set') }</Button>
                <Button onClick={ closeHandler } variant="contained" color="primary">
                    { t('TemplateDialog.cancel') }</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SelectTemplateDialog;
