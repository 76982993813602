import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { Label, LinearGauge, Range, RangeContainer, Scale, Size } from 'devextreme-react/linear-gauge';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    paper: {
        minWidth: 310,
        padding: 15
    },
    resourcesCount: {
        textAlign: "right",
        fontSize: "32px",
        fontWeight: 300
    },
    linear: {
        marginTop: 54,
        marginBottom: 20
    },
    timeText: {
        color: "#909090",
        fontSize: "12px",
        marginTop: 10
    },
    row: {
        height: 30
    },
    cell: {
        padding: 4
    }
});

const QueueCard = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const total = props.ok + props.renderer_waiting + props.star_waiting + props.renderer_error + props.star_error;
    let renderWaitingStart = 0, starWaitingStart = 0, rendererErrorStart = 0, starErrorStart = 0, starErrorStop = 0;
    if (total > 0) {
        renderWaitingStart = props.ok / total * 100;
        starWaitingStart = ((props.ok + props.renderer_waiting) / total) * 100;
        rendererErrorStart = ((props.ok + props.renderer_waiting + props.star_waiting) / total) * 100;
        starErrorStart = ((props.ok + props.renderer_waiting + props.star_waiting + props.renderer_error) / total) * 100;
        starErrorStop = 100;
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography variant="h1">{t('QueueCard.update')}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className={classes.resourcesCount} variant="body1">{total}</Typography>
                    </Grid>
                </Grid>
                <LinearGauge
                    className={classes.linear}
                    id="gauge">
                    <Scale startValue={0} endValue={100} >
                        <Label visible={false} />
                    </Scale>
                    <Size height={25} />
                    <RangeContainer offset={10} width={23}>
                        <Range startValue={0} endValue={renderWaitingStart} color="#9C27B0" />
                        <Range startValue={renderWaitingStart} endValue={starWaitingStart} color="#718792" />
                        <Range startValue={starWaitingStart} endValue={rendererErrorStart} color="#FFEB3B" />
                        <Range startValue={rendererErrorStart} endValue={starErrorStart} color="#FB8C00" />
                        <Range startValue={starErrorStart} endValue={starErrorStop} color="#E53935" />
                    </RangeContainer>
                </LinearGauge>
                <Table>
                    <TableBody>
                        <TableRow className={classes.row}>
                            <TableCell className={classes.cell}><FontAwesomeIcon icon={faCircle} color="#9C27B0" /></TableCell>
                            <TableCell className={classes.cell}>
                                <Button onClick={(e) => props.onFilter('queue', 'ok')} size="small">
                                    {t('QueueCard.statusOk')}
                                </Button>
                            </TableCell>
                            <TableCell className={classes.cell}>{props.ok}</TableCell>
                        </TableRow>
                        <TableRow className={classes.row}>
                            <TableCell className={classes.cell}><FontAwesomeIcon icon={faCircle} color="#718792" /></TableCell>
                            <TableCell className={classes.cell}>
                                <Button onClick={(e) => props.onFilter('queue', 'renderer_waiting')} size="small">
                                    {t('QueueCard.statusRendererWaiting')}
                                </Button>
                            </TableCell>
                            <TableCell className={classes.cell}>{props.renderer_waiting}</TableCell>
                        </TableRow>
                        <TableRow className={classes.row}>
                            <TableCell className={classes.cell}><FontAwesomeIcon icon={faCircle} color="#FFEB3B" /></TableCell>
                            <TableCell className={classes.cell}>
                                <Button onClick={(e) => props.onFilter('queue', 'star_waiting')} size="small">
                                    {t('QueueCard.statusStarWaiting')}
                                </Button>
                            </TableCell>
                            <TableCell className={classes.cell}>{props.star_waiting}</TableCell>
                        </TableRow>
                        <TableRow className={classes.row}>
                            <TableCell className={classes.cell}><FontAwesomeIcon icon={faCircle} color="#FB8C00" /></TableCell>
                            <TableCell className={classes.cell}>
                                <Button onClick={(e) => props.onFilter('queue', 'renderer_error')} size="small">
                                    {t('QueueCard.statusRendererError')}
                                </Button>
                            </TableCell>
                            <TableCell className={classes.cell}>{props.renderer_error}</TableCell>
                        </TableRow>
                        <TableRow className={classes.row}>
                            <TableCell className={classes.cell}><FontAwesomeIcon icon={faCircle} color="#E53935" /></TableCell>
                            <TableCell className={classes.cell}>
                                <Button onClick={(e) => props.onFilter('queue', 'star_error')} size="small">
                                    {t('QueueCard.statusStarError')}
                                </Button>
                            </TableCell>
                            <TableCell className={classes.cell}>{props.star_error}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        </div>
    )
}

export default QueueCard;
