import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import { faEdit, faTrashAlt, faPaintBrush } from '@fortawesome/free-solid-svg-icons';

import { useConfirmation, withConfirmation } from '../../services/ConfirmationService';
import { useTemplateSelection, withTemplateSelection } from '../../services/TemplateSelectionService';
import PageLayout from '../../components/Layout/PageLayout/PageLayout';
import { routePaths } from '../../routePaths';
import itemColumns from './itemColumns';
import ItemTable from '../../components/ItemTable/ItemTable';
import axios, { getStoreLink } from '../../axios';

const useStyles = makeStyles({

});

const Items = (props) => {
    const [items, setItems] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [refreshDate, setRefreshDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();
    const confirm = useConfirmation();
    const templateSelection = useTemplateSelection();

    useEffect(() => {
        axios.get(`${getStoreLink()}/item`, { params: { type: "esl" } })
            .then((response) => {
                const data = [...response.data.data];
                data.forEach((d) => {
                    d.serial = d.serial.length > 0 ? d.serial.join(",\r\n") : "";
                    d.change_tm = d.change_tm * 1000;
                });
                setItems(data);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
        axios.get(`${getStoreLink()}/template`, {
            params: {
                type: "list"
            }
        })
            .then((response) => {
                setTemplates(response.data.data);
            });
    }, [refreshDate]);

    const addHandler = () => {
        props.history.push(routePaths.ItemsNew);
    }

    const editHandler = (id) => {
        const path = routePaths.ItemsEdit.replace(":id", id.toString());
        props.history.push(path);
    }

    const templateHandler = (ids) => {
        templateSelection({
            catchOnCancel: false,
            title: t('TemplateDialog.title_render'),
            templates: templates
        })
            .then((templateId) => {
                if (templateId === -1)
                    return;

                let identifiers = ids;
                if (!Array.isArray(ids))
                    identifiers = [ids];
                axios.post(`${getStoreLink()}/custom_rasterization`, {
                    template: +templateId,
                    items: identifiers
                })
                    .then((response) => {
                        alert(`${t('Items.sent')} ${response.data.data.item_count}. ${t('Items.result')} ${response.data.data.catalog}`);
                    });
            })
    }

    const removeHandler = (ids) => {
        confirm({
            variant: "danger",
            catchOnCancel: false,
            title: t('Items.removetitle'),
            description: t('Items.removetext')
        })
            .then(() => {
                if (Array.isArray(ids)) {
                    // mass remove
                    axios.delete(`${getStoreLink()}/item/`, { data: { items: ids } })
                        .then((response) => {
                            const tmpItems = [...items];
                            ids.forEach(e => {
                                const index = tmpItems.findIndex((item) => item.code === e.toString());
                                if (index !== -1) {
                                    tmpItems.splice(index, 1);
                                }
                            });
                            setItems(tmpItems);
                        });
                } else {
                    axios.delete(`${getStoreLink()}/item/` + ids)
                        .then(() => {
                            const tmpItems = [...items];
                            const index = tmpItems.findIndex((item) => item.code === ids);
                            if (index !== -1) {
                                tmpItems.splice(index, 1);
                                setItems(tmpItems);
                            }
                        });
                }
            })
    }

    const refreshHandler = () => {
        setIsLoading(true);
        setRefreshDate(new Date());
    }

    const menu = [
        {
            text: t('Items.edit'),
            icon: faEdit,
            onAction: editHandler
        },
        {
            text: t('Items.render'),
            icon: faPaintBrush,
            onAction: templateHandler,
            isMass: true
        },
        {
            text: t('Items.remove'),
            icon: faTrashAlt,
            onAction: removeHandler,
            isMass: true
        }
    ];

    const classes = useStyles();

    return (
        <>
            <Button
                onClick={ addHandler }
                variant="contained"
                color="primary">
                { t('Items.add') }</Button>
            {/*<Button
                variant="text">
                { t('Items.import') }</Button>
            <Button
                variant="text">
            { t('Items.export') }</Button>*/}
            <PageLayout
                date={ refreshDate }
                onRefresh={ refreshHandler }
                name={ t('Items.items') }>
                { isLoading &&
                    <CircularProgress />
                }
                <ItemTable
                    columns={ itemColumns }
                    data={ items }
                    keyExpr="code"
                    menu={ menu }
                    onDblClick={ editHandler }
                    isSelectable={ true }
                    storageKey="itemStateStorage"
                />
            </PageLayout>
        </>
    );
}

export default withTemplateSelection(
    withConfirmation(Items)
);
