import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import axios, { getStoreLink } from '../../axios';

const useStyles = makeStyles({
    search: {
        display: "flex",
        alignItems: "center"
    }
});

const LinkDialog = ({
    open,
    onSubmit,
    onClose
}) => {
    const [item, setItem] = useState({
        code: "",
        name: "",
        category: "",
        price: ""
    });
    const [error, setError] = useState('');

    const classes = useStyles();
    const { t } = useTranslation();

    const clear = () => {
        setError("");
        setItem({
            code: "",
            name: "",
            category: "",
            price: ""
        });
    }

    const submitHandler = () => {
        onSubmit(item.code);
        clear();
    }

    const closeHandler = () => {
        onClose();
        clear();
    }

    const changeHandler = (e) => {
        setItem({ ...item, code: e.target.value });
    }

    const searchHandler = () => {
        if (item.code.length < 3) {
            setError(t('Esls.3chars'));
            setItem({ ...item, name: "", category: "", price: "" });

            return;
        }
        axios.get(`${getStoreLink()}/item`, {
            params: {
                search: item.code
            }
        })
            .then((response) => {
                const result = response.data.data;
                if (result.length === 0) {
                    setError(t('Esls.notfound'));
                    setItem({ ...item, name: "", category: "", price: "" });
                } else {
                    setError("");
                    setItem(result[0]);
                }
            })
    }

    return (
        <Dialog
            open={ open }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{ t('LinkDialog.link') }</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    { t('LinkDialog.input') }</DialogContentText>
                <div className={ classes.search }>
                    <TextField
                        fullWidth
                        required
                        id="code"
                        name="code"
                        label={ t('LinkDialog.search') }
                        value={ item.code }
                        onChange={ changeHandler }
                        margin="normal"
                        error={ error !== "" }
                        helperText={ error } />
                    <Button onClick={ searchHandler }>
                        <FontAwesomeIcon icon={ faSearch } size="lg" /></Button>
                </div>
                <TextField
                    disabled
                    id="name"
                    name="name"
                    label={ t('LinkDialog.name') }
                    value={ item.name }
                    margin="normal"
                    fullWidth />
                <TextField
                    disabled
                    id="category"
                    name="category"
                    label={ t('LinkDialog.category') }
                    value={ item.category }
                    margin="normal"
                    fullWidth />
                <TextField
                    disabled
                    id="price"
                    name="price"
                    label={ t('LinkDialog.price') }
                    value={ item.price }
                    margin="normal"
                    fullWidth />
            </DialogContent>
            <DialogActions>
                <Button onClick={ submitHandler } variant="contained" color="primary">
                    { t('LinkDialog.save') }</Button>
                <Button onClick={ closeHandler } variant="contained" color="primary">
                    { t('LinkDialog.cancel') }</Button>
            </DialogActions>
        </Dialog>
    )
}

export default LinkDialog;
