import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    root: {
        marginBottom: 25
    },
    formControl: {
        paddingLeft: 8,
        width: 225
    },
    selectEmpty: {

    }
});

const EslFilter = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const createChangeHandler = (filter) => (event) => {
        if (filter === 'import')
            props.onChange(filter, event.target.checked);
        else
            props.onChange(filter, event.target.value);
    };

    return (
        <div className={ classes.root }>
            <FormControl className={ classes.formControl }>
                <Select value={ props.filter.status ? props.filter.status : '' } onChange={ createChangeHandler('status') } displayEmpty className={ classes.selectEmpty }>
                    <MenuItem value={ '' }>{ t('EslFilter.all') }</MenuItem>
                    <MenuItem value={ 'linked' }>{ t('EslFilter.linked') }</MenuItem>
                    <MenuItem value={ 'notlinked' }>{ t('EslFilter.notlinked') }</MenuItem>
                </Select>
                <FormHelperText>{ t('EslFilter.state') }</FormHelperText>
            </FormControl>
            <FormControl className={ classes.formControl }>
                <Select value={ props.filter.connection ? props.filter.connection : '' } onChange={ createChangeHandler('connection') } displayEmpty className={ classes.selectEmpty }>
                    <MenuItem value={ '' }>{ t('EslFilter.all') }</MenuItem>
                    <MenuItem value={ 'ok' }>{ t('EslFilter.online') }</MenuItem>
                    {/*<MenuItem value={ 'low' }>{ t('EslFilter.low') }</MenuItem>*/}
                    <MenuItem value={ 'offline' }>{ t('EslFilter.offline') }</MenuItem>
                </Select>
                <FormHelperText>{ t('EslFilter.connection') }</FormHelperText>
            </FormControl>
            <FormControl className={ classes.formControl }>
                <Select value={ props.filter.queue ? props.filter.queue : '' } onChange={ createChangeHandler('queue') } displayEmpty className={ classes.selectEmpty }>
                    <MenuItem value={ '' }>{ t('EslFilter.all') }</MenuItem>
                    <MenuItem value={ 'ok' }>{ t('QueueCard.statusOk') }</MenuItem>
                    <MenuItem value={ 'renderer_waiting' }>{ t('QueueCard.statusRendererWaiting') }</MenuItem>
                    <MenuItem value={ 'star_waiting' }>{ t('QueueCard.statusStarWaiting') }</MenuItem>
                    <MenuItem value={ 'renderer_error' }>{ t('QueueCard.statusRendererError') }</MenuItem>
                    <MenuItem value={ 'star_error' }>{ t('QueueCard.statusStarError') }</MenuItem>
                </Select>
                <FormHelperText>{ t('EslFilter.update') }</FormHelperText>
            </FormControl>
            <FormControlLabel
                className={ classes.formControl }
                control={
                    <Checkbox
                        checked={ props.filter.import }
                        onChange={ createChangeHandler('import') }
                        name="import"
                        color="primary"
                    />
                }
                label={ t('EslFilter.lastimport') }
            />
        </div>
    )
}

export default EslFilter;
