import React from 'react';
import SelectTemplateDialog from '../components/Dialogs/SelectTemplateDialog';

const TemplateSelectionServiceContext = React.createContext(Promise.reject);

export const useTemplateSelection = () =>
    React.useContext(TemplateSelectionServiceContext);

export const TemplateSelectionServiceProvider = ({ children }) => {
    const [templateSelectionState, setTemplateSelectionState] = React.useState(null);

    const awaitingPromiseRef = React.useRef();

    const openTemplateSelection = (options) => {
        setTemplateSelectionState(options);
        
        return new Promise((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    };

    const handleClose = () => {
        if (templateSelectionState.catchOnCancel && awaitingPromiseRef.current) {
            awaitingPromiseRef.current.reject();
        }

        setTemplateSelectionState(null);
    };

    const handleSubmit = (id) => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve(id);
        }

        setTemplateSelectionState(null);
    };

    return (
        <>
            <TemplateSelectionServiceContext.Provider
                value={ openTemplateSelection }
                children={ children }
            />

            <SelectTemplateDialog
                open={ Boolean(templateSelectionState) }
                onSubmit={ handleSubmit }
                onClose={ handleClose }
                { ...templateSelectionState }
            />
        </>
    );
};

export const withTemplateSelection = (Component) => {
    const WithTemplateSelection = (props) => {
        return (
            <TemplateSelectionServiceProvider>
                <Component { ...props } />
            </TemplateSelectionServiceProvider>
        );
    }

    return WithTemplateSelection;
};
