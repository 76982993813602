import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';

import { routePaths } from '../../routePaths';
import axios from '../../axios';

const styles = () => ({
    paper: {
        margin: 30,
        padding: 30
    },
    subheader: {
        marginTop: 10
    },
    centered: {
        textAlign: "center",
        marginTop: 30
    },
    button: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: 10
    }
});

class StoreView extends Component {
    state = {
        store: {
            name: "",
            path: "",
            user_id: "",
            pass: ""
        },
        error: ""
    };

    componentDidMount() {
        if (this.props.match.path === routePaths.StoresEdit) {
            axios.get('/store', { params: { id: this.props.match.params.id } })
                .then((response) => {
                    this.setState({ store: response.data.data });
                });
        }
    }

    submitHandler = (e) => {
        e.preventDefault();
        if (this.state.store.user_id && this.state.store.user_id !== "") {
            if (!/^(?:[A-Za-z0-9\_\-]+)$/.test(this.state.store.user_id)) {
                this.setState({ error: "Допустимые символы: 0-9,A-Z,a-z,_,-" });
                return;
            }
        }

        if (this.props.match.path === routePaths.StoresNew) {
            axios.post('/store', this.state.store)
                .then(() => this.props.history.push(routePaths.Stores));
        } else {
            axios.put('/store', this.state.store)
                .then(() => this.props.history.push(routePaths.Stores));
        }
    }

    changeHandler = (e) => {
        this.setState({
            store: {
                ...this.state.store,
                [e.target.name]: e.target.value
            }
        });
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;

        const isNew = this.props.match.path !== routePaths.StoresEdit;
        const { store, error } = this.state;
        const CancelLink = (props) => <Link to={routePaths.Stores} {...props} />;

        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    {isNew
                        ?
                        <Typography variant="h1">{t('StoreView.add')}</Typography>
                        :
                        <Typography variant="h1">{t('StoreView.edit')}</Typography>
                    }
                    <form onSubmit={this.submitHandler} noValidate>
                        <TextField
                            id="name"
                            name="name"
                            label={t('StoreView.name')}
                            value={store.name}
                            onChange={this.changeHandler}
                            margin="normal"
                            fullWidth />
                        <TextField
                            id="path"
                            name="path"
                            label={t('StoreView.path')}
                            value={store.path}
                            onChange={this.changeHandler}
                            margin="normal"
                            fullWidth />
                        <TextField
                            id="user_id"
                            name="user_id"
                            error={error !== ""}
                            helperText={error}
                            label={t('StoreView.userid')}
                            value={store.user_id}
                            onChange={this.changeHandler}
                            margin="normal"
                            fullWidth />
                        <TextField
                            id="pass"
                            name="pass"
                            label={t('StoreView.pass')}
                            value={store.pass}
                            onChange={this.changeHandler}
                            margin="normal"
                            fullWidth />
                        <div className={classes.centered}>
                            <Button
                                className={classes.button}
                                color="primary"
                                type="submit"
                                variant="contained">
                                {t('StoreView.save')}</Button>
                            <Button
                                className={classes.button}
                                color="primary"
                                component={CancelLink}
                                variant="contained">
                                {t('StoreView.cancel')}</Button>
                        </div>
                    </form>
                </Paper >
            </div>
        )
    }
}

export default withTranslation()(withRouter(withStyles(styles)(StoreView)));
