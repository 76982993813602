import React, { useState, useEffect, useRef } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import axios, { getStoreLink } from '../../axios';
import ledColumns from './ledColumns';
import PageLayout from '../Layout/PageLayout/PageLayout';
import ItemTable from '../ItemTable/ItemTable';
import { useConfirmation, withConfirmation } from '../../services/ConfirmationService';
import { faBan, faCircle, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { useLedSelection, withLedSelection } from '../../services/LedService';


const Leds = (props) => {
    const [esls, setEsls] = useState([]);
    const [modes, setModes] = useState([]);
    const [refreshDate, setRefreshDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();
    const confirm = useConfirmation();
    const ledSelection = useLedSelection()

    useEffect(() => {
        loadEsls();

    }, [refreshDate]);

    const loadEsls = () => {
        let params = {
            params: {
                type: "item"
            }
        };
        axios.get(`${getStoreLink()}/esl`, params)
            .then((response) => {
                const data = [...response.data.data];
                data.forEach((led) => {
                    led.led_mode_start_time = led.led_mode_start_time === 0 ? null : +led.led_mode_start_time * 1000;
                    led.led_mode_stop_time = led.led_mode_stop_time === 0 ? null : +led.led_mode_stop_time * 1000;
                    console.log(led.led_mode_start_time);
                    console.log(led.led_mode_stop_time);

                    const unixNow = moment.utc().valueOf();
                    console.log(unixNow);

                    if (led.led_mode_id === 0) {
                        // there is no led mode
                        led.picture_state = 5;
                        led.led_mode_start_time = null;
                        led.led_mode_stop_time = null;
                    }
                    else if (led.led_mode_status === 0)
                        // waiting for delivery
                        led.picture_state = 0;
                    else if (led.led_mode_status === 2)
                        // error
                        led.picture_state = 4;
                    else if (led.led_mode_status === 1) {
                        // loaded
                        if (unixNow < led.led_mode_start_time && led.led_mode_start_time !== null)
                            led.picture_state = 1;
                        else if (unixNow > led.led_mode_stop_time && led.led_mode_stop_time !== null)
                            led.picture_state = 3;
                        else
                            led.picture_state = 2;
                    }
                    led.state_image = getStateIcon(led.picture_state);

                });
                setEsls(data);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
        axios.get(`${getStoreLink()}/led_mode`)
            .then((response) => {
                setModes(response.data.data);
            });
    }

    const getStateIcon = (state) => {
        let state_image = null;
        // waiting for delivery
        if (state === 0)
            state_image = { icon: faCircle, color: "#FB8C00", hint: t('Leds.stateDelivery') };
        // waiting for start
        else if (state === 1)
            state_image = { icon: faCircle, color: "#FFEB3B", hint: t('Leds.stateWaiting') };
        // in progress
        else if (state === 2)
            state_image = { icon: faCircle, color: "#008000", hint: t('Leds.stateStarted') };
        // finished
        else if (state === 3)
            state_image = { icon: faCircle, color: "#000000", hint: t('Leds.stateFinished') };
        // error
        else if (state === 4)
            state_image = { icon: faCircle, color: "#FF0000", hint: t('Leds.stateError') };

        return state_image;
    }

    const refreshHandler = () => {
        setIsLoading(true);
        setRefreshDate(new Date());
    }

    const loadHandler = (ids) => {
        ledSelection({ modes })
            .then(({
                modeId,
                startDate,
                stopDate
            }) => {
                // /led_esl
                // { "serial":"12345", "led_mode_id": 56, "start_time": 1523234234, "stop_time": 1523234523 } 
                const identifiers = Array.isArray(ids) ? ids : [ids];
                let promises = [];
                identifiers.forEach((i) => (
                    promises.push(axios.post(`${getStoreLink()}/led_esl`, {
                        serial: i,
                        led_mode_id: modeId,
                        start_time: startDate,
                        stop_time: stopDate
                    }))
                ));
                Promise.all(promises).then(() => {
                    const temp = [...esls];
                    identifiers.forEach(i => {
                        const index = temp.findIndex(t => t.serial === i);
                        if (index !== -1) {
                            temp[index].picture_state = 0;
                            temp[index].state_image = getStateIcon(0);

                            temp[index].led_mode_start_time = startDate === 0 ? null : startDate * 1000;
                            temp[index].led_mode_stop_time = stopDate === 0 ? null : stopDate * 1000;
                        }
                    });
                    setEsls(temp);
                });
            });
    }

    const unloadHandler = (ids) => {
        confirm({
            variant: "danger",
            catchOnCancel: false,
            title: t('Leds.removetitle'),
            description: t('Leds.removetext')
        }).then(() => {
            const identifiers = Array.isArray(ids) ? ids : [ids];
            let promises = [];
            identifiers.forEach((i) => (
                promises.push(axios.delete(`${getStoreLink()}/led_esl/${i}`))
            ));
            Promise.all(promises).then(() => {
                const temp = [...esls];
                identifiers.forEach(i => {
                    const index = temp.findIndex(t => t.serial === i);
                    if (index !== -1) {
                        temp[index].picture_state = 5;
                        temp[index].state_image = getStateIcon(5);

                        temp[index].led_mode_start_time = null;
                        temp[index].led_mode_stop_time = null;
                    }
                });
                setEsls(temp);
            });
        });
    }

    const menu = [{
        text: t('Leds.load'),
        icon: faLightbulb,
        onAction: loadHandler,
        isMass: true,
    }, {
        text: t('Leds.unload'),
        icon: faBan,
        onAction: unloadHandler,
        isMass: true,
    }];

    return (
        <PageLayout
            date={refreshDate}
            onRefresh={refreshHandler}
            name={t('Leds.Leds')}>

            {isLoading &&
                <CircularProgress />
            }
            <ItemTable
                columns={ledColumns}
                data={esls}
                keyExpr="serial"
                menu={menu}
                //onDblClick={editHandler}
                //onClick={clickHandler}
                isSelectable={true}
                storageKey="ledsStateStorage"
            />
        </PageLayout>
    )
}

export default withConfirmation(
    withLedSelection(Leds)
);
