import { translateCell } from '../../const/i18n';

const controllerColumns = [
    {
        name: translateCell('Controllers.serial'),
        field: "serial",
        type: "string"
    },
    {
        name: translateCell('Controllers.count'),
        field: "labels_count",
        type: "number"
    },
    {
        name: translateCell('Controllers.type'),
        field: "label_type",
        type: "string"
    },
    {
        name: translateCell('Controllers.battery'),
        field: "last_bat",
        type: "number"
    },
    {
        name: translateCell('Controllers.state'),
        field: "status",
        type: "string"
    },
    {
        name: translateCell('Controllers.mac'),
        field: "mac",
        type: "string"
    },
    {
        name: translateCell('Controllers.status'),
        field: "view_tm",
        type: "datetime"
    },
];

export default controllerColumns;
