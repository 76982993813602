import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

import axios, { getStoreLink } from '../../axios';
import { routePaths } from '../../routePaths';
import { Typography } from '@material-ui/core';
import colors from '../../const/Colors';

const styles = () => ({
    paper: {
        margin: "30px",
        padding: "30px"
    },
    centered: {
        textAlign: "center",
        marginTop: 30
    },
    button: {
        marginLeft: 15,
        marginRight: 15,
    }
});

class EslView extends Component {
    state = {
        esl: {
            serial: "",
            color: "",
            template_id: -1,
            diagonal: "",
            height: 0,
            width: 0,
            rotation: 0,
            controller_layer: 1
        },
        templates: []
    };

    componentDidMount() {
        axios.get(`${getStoreLink()}/template`, {
            params: {
                type: "list"
            }
        })
            .then((response) => {
                this.setState({ templates: response.data.data });
            })
        if (this.props.match.path === routePaths.EslsEdit) {
            axios.get(`${getStoreLink()}/esl/` + this.props.match.params.id)
                .then((response) => {
                    if (!response.data.data.template_id)
                        response.data.data.template_id = -1;
                    this.setState({ esl: response.data.data });
                });
        }
    }

    submitHandler = (e) => {
        e.preventDefault();
        const esl = { ...this.state.esl };
        if (esl.template_id === -1) esl.template_id = null;

        if (this.props.match.path === routePaths.EslsNew) {
            axios.post(`${getStoreLink()}/esl`, esl)
                .then(() => this.props.history.push(routePaths.Esls));
        } else {
            axios.put(`${getStoreLink()}/esl/` + this.props.match.params.id, esl)
                .then(() => this.props.history.push(routePaths.Esls));
        }
    }

    changeHandler = (e) => {
        this.setState({
            esl: {
                ...this.state.esl,
                [e.target.name]: e.target.value
            }
        });
    }

    changeNumericHandler = (e) => {
        this.setState({
            esl: {
                ...this.state.esl,
                [e.target.name]: +e.target.value
            }
        });
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;

        const isNew = this.props.match.path !== routePaths.EslsEdit;
        const { esl } = this.state;
        const CancelLink = (props) => <Link to={ routePaths.Esls } { ...props } />;

        return (
            <Paper className={ classes.paper }>
                { isNew
                    ?
                    <Typography variant="h1">{ t('EslView.add') }</Typography>
                    :
                    <Typography variant="h1">{ t('EslView.edit') }</Typography>
                }
                <form onSubmit={ this.submitHandler } noValidate>
                    <TextField
                        disabled={ !isNew }
                        required
                        id="serial"
                        name="serial"
                        label={ t('EslView.barcode') }
                        value={ esl.serial }
                        onChange={ this.changeHandler }
                        type="number"
                        margin="normal"
                        fullWidth />
                    <TextField
                        select
                        id="template_id"
                        name="template_id"
                        label={ t('EslView.template') }
                        value={ esl.template_id }
                        onChange={ this.changeNumericHandler }
                        margin="normal"
                        fullWidth>
                        <MenuItem key={ -1 } value={ -1 }>
                            { t('EslView.notset') }</MenuItem>
                        { this.state.templates.map(template => (
                            <MenuItem key={ template.id } value={ template.id }>
                                { `${template.name} (${template.color}, ${template.height}*${template.width})` }
                            </MenuItem>
                        )) }
                    </TextField>
                    <TextField
                        select
                        id="color"
                        name="color"
                        label={ t('EslView.color') }
                        value={ esl.color }
                        onChange={ this.changeHandler }
                        margin="normal"
                        fullWidth>
                        { colors.map(color => (
                            <MenuItem key={ color.value } value={ color.value }>
                                { `${color.value} (${color.description})` }
                            </MenuItem>
                        )) }
                    </TextField>
                    <TextField
                        required
                        id="controller_layer"
                        name="controller_layer"
                        label={ t('EslView.layercount') }
                        value={ esl.controller_layer }
                        onChange={ this.changeNumericHandler }
                        margin="normal"
                        type="number"
                        fullWidth />
                    <Grid container spacing={ 2 }>
                        <Grid item xs={ 4 }>
                            <TextField
                                required
                                id="height"
                                name="height"
                                label={ t('EslView.height') }
                                value={ esl.height }
                                onChange={ this.changeNumericHandler }
                                margin="normal"
                                type="number"
                                fullWidth />
                        </Grid>
                        <Grid item xs={ 4 }>
                            <TextField
                                required
                                id="width"
                                name="width"
                                label={ t('EslView.width') }
                                value={ esl.width }
                                onChange={ this.changeNumericHandler }
                                margin="normal"
                                type="number"
                                fullWidth />
                        </Grid>
                        <Grid item xs={ 4 }>
                            <TextField
                                select
                                id="rotation"
                                name="rotation"
                                label={ t('EslView.orientation') }
                                value={ esl.rotation }
                                onChange={ this.changeHandler }
                                margin="normal"
                                fullWidth>
                                { [0, 90, 180, 270].map(r => (
                                    <MenuItem key={ r } value={ r }>
                                        { `${r} °` }
                                    </MenuItem>
                                )) }
                            </TextField>
                        </Grid>
                    </Grid>
                    <div className={ classes.centered }>
                        <Button
                            className={ classes.button }
                            color="primary"
                            type="submit"
                            variant="contained">
                            { t('EslView.save') }</Button>
                        <Button
                            className={ classes.button }
                            color="primary"
                            component={ CancelLink }
                            variant="contained">
                            { t('EslView.cancel') }</Button>
                    </div>
                </form>
            </Paper >
        )
    }
}

export default withTranslation()(withRouter(withStyles(styles)(EslView)));
