import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FileUploader } from 'devextreme-react/file-uploader';
import JSZip from 'jszip';
import { useTranslation } from 'react-i18next';

import axios, { getStoreLink } from '../../axios';
import UpdateState from './UpdateState';
import { isCentralServer } from '../../const';
import PageLayout from '../Layout/PageLayout/PageLayout';


const useStyles = makeStyles({
    uploader: {
        "& .dx-fileuploader-upload-button.dx-button-has-icon": {
            display: "none"
        },
        "& .dx-fileuploader-upload-button.dx-button-has-text": {
            color: "#fff",
            backgroundColor: "#E53935",
            borderRadius: 16
        }
    },
    block: {
        marginTop: 50
    }
});

const UpdateTab = (props) => {
    const [lastUpdateId, setLastUpdateId] = useState(-1);
    const [lastUpdateState, setLastUpdateState] = useState("");
    //const [files, setFiles] = useState([]);
    const [zipFiles, setZipFiles] = useState([]);
    const [manifest, setManifest] = useState(null);
    const refUpload = useRef(null);

    useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        axios.get(`${getStoreLink()}/firmware`)
            .then((response) => {
                if (Array.isArray(response.data.data) && response.data.data.length > 0) {
                    setLastUpdateId(response.data.data[0].id);
                    setLastUpdateState(response.data.data[0].status);
                }
            });
    }

    const filesHandler = (e) => {
        //setFiles(e.value);
        if (e.value.length > 0)
            JSZip.loadAsync(e.value[0])
                .then((zip) => {
                    let zfiles = [];
                    zip.forEach((relativePath, zipEntry) => {
                        zfiles.push(relativePath);
                        if (relativePath.includes('manifest'))
                            zipEntry.async("text").then((text) => {
                                setManifest(JSON.parse(text));
                            })
                    });
                    setZipFiles(zfiles);
                });
        else {
            setManifest(null);
            setZipFiles([]);
        }
    }

    const uploadedHandler = (e) => {
        refresh();
    }

    const stopHandler = () => {
        if (lastUpdateId !== -1) {
            axios.delete(`${getStoreLink()}/firmware/` + lastUpdateId);
            refresh();
        }
    }

    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <PageLayout
            name={ t('Diagnostics.update') }
            withoutRefresh={ true }>
            { lastUpdateState !== "in progress" &&
                <>
                    <FileUploader
                        uploadButtonText={ t('Diagnostics.now') }
                        ref={ refUpload }
                        className={ classes.uploader }
                        multiple={ false }
                        accept=".zip"
                        uploadMode="useButtons"
                        uploadMethod="POST"
                        uploadUrl={ `${isCentralServer ? '/api' : '/api3'}${getStoreLink()}/firmware` }
                        uploadHeaders={ { Qpstoretoken: sessionStorage.getItem('qpstkn') } }
                        onUploaded={ uploadedHandler }
                        onValueChanged={ filesHandler } />
                    { zipFiles.length > 0 &&
                        <div className={ classes.block }>
                            <Typography variant="h3">{ t('Diagnostics.files') }</Typography>
                            <List>
                                { zipFiles.map((f) => (
                                    <ListItem key={ f }>
                                        <ListItemIcon>
                                            <FontAwesomeIcon icon={ faFile } />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={ f } />
                                    </ListItem>
                                )) }
                            </List>
                        </div>
                    }
                    { manifest &&
                        <div className={ classes.block }>
                            <Typography variant="h3">{ t('Diagnostics.manifest') }</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{ t('Diagnostics.file') }</TableCell>
                                        <TableCell>{ t('Diagnostics.version') }</TableCell>
                                        <TableCell>{ t('Diagnostics.hw_version') }</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { Object.keys(manifest).map((key) => (
                                        <TableRow key={ key }>
                                            <TableCell>{ key }</TableCell>
                                            <TableCell>{ manifest[key].version ? manifest[key].version : "" }</TableCell>
                                            <TableCell>{ manifest[key].hw_version ? manifest[key].hw_version : "" }</TableCell>
                                        </TableRow>
                                    )) }
                                </TableBody>
                            </Table>
                        </div>
                    }
                </>
            }
            { lastUpdateState === "in progress" &&
                <Button
                    variant="contained"
                    color="primary"
                    onClick={ stopHandler }>
                    { t('Diagnostics.stop') }</Button>
            }
            { lastUpdateId !== -1 &&
                <UpdateState
                    updateId={ lastUpdateId }
                    updateState={ lastUpdateState } />
            }
        </PageLayout>
    );
}

export default UpdateTab;
