import 'babel-polyfill';
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import './index.css';
import App from './App';
import ScrollToTop from './hoc/ScrollToTop/ScrollToTop';
import i18n from './const/i18n';
import * as serviceWorker from './serviceWorker';

const app = (
    <BrowserRouter /*basename="/sum"*/>
        <ScrollToTop>
            <I18nextProvider i18n={ i18n }>
                <MuiPickersUtilsProvider utils={ MomentUtils }>
                    <App />
                </MuiPickersUtilsProvider>
            </I18nextProvider>
        </ScrollToTop>
    </BrowserRouter>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
