import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';

import { Graph } from 'react-d3-graph';
import { useTranslation } from 'react-i18next';

import PageLayout from '../Layout/PageLayout/PageLayout';
import axios, { getStoreLink } from '../../axios';

const useStyles = makeStyles({
    graph: {
        width: 800,
        border: "1px solid #000"
    },
    invisible: {
        display: "none"
    },
    script: {
        paddingBottom: 25
    },
    showButton: {
        marginRight: 25
    }
});

const DiagnosticsTab = (props) => {
    const [refreshDate, setRefreshDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);
    const [showGraph, setShowGraph] = useState(false);
    const [tree, setTree] = useState({
        nodes: [],
        links: []
    });

    const loadHandler = () => {
        if (showGraph) {
            setShowGraph(false);
            setTree({
                nodes: [],
                links: []
            });
        } else {
            setShowGraph(true);
            setIsLoading(true);
            setRefreshDate(new Date());
            loadGraph();
        }
    };

    const loadGraph = () => {
        axios.get(`${getStoreLink()}/meshtree`)
            .then((response) => {
                const tree = {
                    nodes: [],
                    links: []
                };

                for (let i = 0; i < response.data.length; i++) {
                    if (tree.nodes.findIndex((u) => u.id === response.data[i]) === -1)
                        tree.nodes.push({ id: response.data[i] });
                }
                for (let i = 0; i < response.data.length; i += 2) {
                    tree.links.push({ source: response.data[i], target: response.data[i + 1] });
                }

                setTree(tree);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }

    const refreshHandler = () => {
        if (showGraph) {
            setIsLoading(true);
            setRefreshDate(new Date());
            loadGraph();
        }
    }

    const scriptHandler = () => {
        setIsLoading(true);
        axios.get(`${getStoreLink()}/runscript`)
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
        ;

    }

    const { t } = useTranslation();
    const classes = useStyles();

    const graphConfig = {
        directed: true,
        nodeHighlightBehavior: true,
        node: {
            color: 'lightgreen',
            size: 120,
            highlightStrokeColor: 'blue'
        },
        link: {
            highlightColor: 'lightblue'
        }
    };

    return (
        <PageLayout
            date={ refreshDate }
            onRefresh={ refreshHandler }
            name={ t('Diagnostics.diagnostics') }>

            <div className={ classes.script }>
                <Button
                    className={ classes.showButton }
                    variant="contained"
                    color="primary"
                    onClick={ loadHandler }>
                    { showGraph ? t('Diagnostics.hide') : t('Diagnostics.show') } </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={ scriptHandler }>
                    { t('Diagnostics.run') }</Button>
            </div>
            { isLoading &&
                <CircularProgress />
            }
            <div className={ classNames(classes.graph, {
                [classes.invisible]: !showGraph
            }) }>
                { tree.nodes.length > 0 && !isLoading &&
                    <Graph
                        width={ "100%" }
                        id="graph-id"
                        data={ tree }
                        config={ graphConfig } />
                }
            </div>
        </PageLayout>
    );
}

export default DiagnosticsTab;
