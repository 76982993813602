import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { translateCell } from '../../const/i18n';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        borderBottom: 'solid 1px #fff',
    },
    line: {
        display: "flex",
        alignItems: "center"  
    },
    clear: {
        color: "#fff",
        borderColor: "#fff",
        marginLeft: 10,
        marginRight: 10,
    },
    highlight: {
        color: '#fff',
        backgroundColor: '#E53935',
        minHeight: 45
    },
    invisible: {
        display: "none"
    },
    spacer: {
        flex: '1 1 40%',
    },
    actions: {
    },
    title: {
        flex: '0 0 auto',
    }
}));

const ItemTableToolbar = (props) => {
    const classes = useStyles();
    const { selected, menu, onClear } = props;
    const numSelected = selected ? selected.length : props.numSelected;

    const menuItemClickHandler = (e, element) => {
        element.onAction(selected);
    }

    return (
        <Toolbar
            className={ classNames(classes.root, {
                [classes.highlight]: numSelected > 0,
                [classes.invisible]: numSelected === 0,
            }) }
        >
            <div className={ classes.title }>
                { numSelected > 0 &&
                    <div className={ classes.line }>
                        <Typography color="inherit" variant="subtitle1">
                            { numSelected } { translateCell('ItemTableToolbar.selected') }
                        </Typography>
                        <Button
                            className={ classes.clear }
                            variant="outlined"
                            onClick={ onClear }>
                            Снять выделение</Button>
                    </div>
                }
            </div>
            <div className={ classes.spacer } />
            <div className={ classes.actions }>
                { numSelected > 0 && menu &&
                    menu.map((el, index) => (
                        <Tooltip key={ index } title={ el.text }>
                            <IconButton onClick={ (e) => menuItemClickHandler(e, el) }>
                                <FontAwesomeIcon color="white" size="sm" icon={ el.icon } />
                            </IconButton>
                        </Tooltip>
                    ))
                }
            </div>
        </Toolbar>
    );
};

export default ItemTableToolbar;
