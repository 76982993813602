import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles({
    cell: {
        whiteSpace: "pre"
    },
    icon: {
        paddingRight: 8,
        paddingLeft: 8,
        paddingTop: 2,
        paddingBottom: 2,
    }
});

const ShortcutCell = (props) => {
    const classes = useStyles();

    const menuItemClickHandler = (id, element) => (event) => {
        element.onAction(id);
    }

    return (
        <div className={ classes.cell }>
            { props.menu.map((el, index) => (
                <IconButton
                    className={ classes.icon }
                    key={ index }
                    onClick={ menuItemClickHandler(props.cellData.key, el) }>
                    <FontAwesomeIcon icon={ el.icon } size="xs" />
                </IconButton>
            )) }
        </div>
    );
}

export default ShortcutCell;
