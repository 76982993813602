import { translateCell } from '../../const/i18n';

const fontColumns = [
    {
        name: translateCell('Library.crenderer'),
        field: "renderer",
        type: "string"
    }, {
        name: translateCell('Library.cfontpath'),
        field: "path",
        type: "string"
    }, {
        name: translateCell('Library.cfontfamily'),
        field: "fonts",
        type: "string"
    }, {
        name: translateCell('Library.cfontstyles'),
        field: "style",
        type: "string"
    }
];

export default fontColumns;
