import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { translateCell } from '../../const/i18n';
import TabPanel from '../TabPanel/TabPanel';
import DiagnosticsTab from './DiagnosticsTab';
import UpdateTab from './UpdateTab';

const useStyles = makeStyles({
    root: {

    }
});

const tabList = [
    {
        label: translateCell('Diagnostics.diagnostics'),
        value: 0
    }, {
        label: translateCell('Diagnostics.update'),
        value: 1
    }
];

const Diagnostics = (props) => {
    const [tabValue, setTabValue] = useState(tabList[0].value);

    const tabChangeHandler = (event, value) => {
        setTabValue(value);
    }

    const a11yProps = (index) => {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`,
        };
    }

    const classes = useStyles();

    return (
        <div className={ classes.root }>
            <Tabs
                value={ tabValue }
                indicatorColor="primary"
                textColor="primary"
                onChange={ tabChangeHandler }>
                { tabList.map((tab, index) => (
                    <Tab
                        className={ classes.tab }
                        key={ index }
                        label={ tab.label }
                        { ...a11yProps(index) } />
                )) }
            </Tabs>
            <TabPanel value={ tabValue } index={ 0 }>
                <DiagnosticsTab />
            </TabPanel>
            <TabPanel value={ tabValue } index={ 1 }>
                <UpdateTab />
            </TabPanel>
        </div>
    );
}

export default Diagnostics;
