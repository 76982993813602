import React, { useState, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { faSignInAlt, faEdit, faTrashAlt, faSpinner, faCheck, faTimes, faUserLock, faFileSignature, faFileCode, faFileImage, faAngleDown } from "@fortawesome/free-solid-svg-icons";

import { routePaths } from "../../routePaths";
import withCentral from "./withCentral";
import PageLayout from "../Layout/PageLayout/PageLayout";
import ItemTable from "../ItemTable/ItemTable";
import { withConfirmation, useConfirmation } from "../../services/ConfirmationService";
import axios from "../../axios";
import storeColumns from "./storeColumns";
import StoreInfo from "./StoreInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const useStyles = makeStyles({
    buttonContainer: {
        textAlign: "right",
        marginBottom: 40
    },
    menuImage: {
        marginRight: 20
    },
    buttonIcon: {
        marginLeft: 20
    },
    loadButton: {
        width: 250,
        marginRight: 30
    }
});

const Stores = (props) => {
    const [stores, setStores] = useState([]);
    const [focusedRow, setFocusedRow] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [refreshDate, setRefreshDate] = useState(new Date());

    useEffect(() => {
        axios.get('/store/list')
            .then((response) => {
                const stores = [...response.data.data];
                stores.forEach(store => {
                    store.state = "loading";
                    store.eslCount = null;
                    store.lastImport = null;
                    store.picture_state = getStateIcon(store.state);
                    axios.get(`/store/${store.id}/statistics`, { handlerEnabled: false })
                        .then((response) => {
                            const storesTmp = [...stores];
                            const index = storesTmp.findIndex((s) => s.id === store.id);
                            if (index !== -1) {
                                if (response.status === 200 && response.data.result === 100) {
                                    storesTmp[index].state = "pwd";
                                }
                                else if (response.status === 200 && response.data.result === 1) {
                                    storesTmp[index].state = "error";
                                }
                                else {
                                    storesTmp[index].state = "ok";
                                    storesTmp[index].statistics = response.data.data;
                                    storesTmp[index].eslCount = response.data.data.status.linked + response.data.data.status.notLinked;
                                    storesTmp[index].lastImport = response.data.data.import;
                                }
                                storesTmp[index].picture_state = getStateIcon(storesTmp[index].state);
                                setStores(storesTmp);
                            }
                        })
                        .catch(() => {
                            const storesTmp = [...stores];
                            const index = stores.findIndex((s) => s.id === store.id);
                            if (index !== -1) {
                                storesTmp[index].state = "error";
                                storesTmp[index].picture_state = getStateIcon(storesTmp[index].state);
                            }
                            setStores(storesTmp);
                        });
                });
                if (stores.length > 0) {
                    setFocusedRow(stores[0].id);
                }
                setStores(stores);
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false);
            });
    }, [refreshDate]);

    const anchorRef = useRef(null);
    const loadImageRef = useRef(null);
    const loadScriptRef = useRef(null);
    const loadStyleRef = useRef(null);

    const confirm = useConfirmation();
    const { t } = useTranslation();
    const classes = useStyles();

    const toggleMenuHandler = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const closeMenuHandler = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const refreshHandler = () => {
        setIsLoading(true);
        setRefreshDate(new Date());
    }

    const focusedRowChangedHandler = (id) => {
        setFocusedRow(id);
    }

    const goHandler = (id) => {
        const index = stores.findIndex((store) => store.id === id);
        if (index !== -1 && stores[index].state === "ok")
            axios.get(`/store/${id}/settings/namestore`)
                .then((response) => {
                    if (response.data.data && response.data.data.length > 0 && response.data.data[0].key === "namestore")
                        sessionStorage.setItem("namestore", response.data.data[0].value);
                    // save store id, go to main page
                })
                .finally(() => {
                    sessionStorage.setItem("store", id);
                    props.history.push(routePaths.Main);

                });
        else
            alert('Магазин недоступен!');
    }

    const addHandler = () => {
        props.history.push(routePaths.StoresNew);
    }

    const editHandler = (id) => {
        const path = routePaths.StoresEdit.replace(":id", id.toString());
        props.history.push(path);
    }

    const removeHandler = (id) => {
        confirm({
            variant: "danger",
            catchOnCancel: false,
            title: t('Stores.storeremovetitle'),
            description: t('Stores.storeremovetext')
        })
            .then(() => {
                axios.delete('/store', { params: { id: id } })
                    .then(() => {
                        const storesTmp = [...stores];
                        const index = storesTmp.findIndex((store) => store.id === id);
                        if (index !== -1) {
                            storesTmp.splice(index, 1);
                            setStores(storesTmp);
                        }
                    });
            });
    }

    const loadImageClickHandler = () => {
        loadImageRef.current.click();
    }

    const loadScriptClickHandler = () => {
        loadScriptRef.current.click();
    }

    const loadStyleClickHandler = () => {
        loadStyleRef.current.click();
    }

    const loadImageHandler = (e) => {
        loadFile(e, '/resource/import_images', loadImageRef, t('Library.image_imported'));
    }

    const loadScriptHandler = (e) => {
        loadFile(e, '/resource/import_js', loadScriptRef, t('Library.script_imported'));
    }

    const loadStyleHandler = (e) => {
        loadFile(e, '/resource/import_css', loadStyleRef, t('Library.style_imported'));
    }

    const loadFile = (e, url, ref, message) => {
        if (e.target.files) {
            setIsFileLoading(true);
            const file = e.target.files[0];
            const body = new FormData();
            body.append('file', file);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            axios.post(url, body, config)
                .then(() => {
                    setIsFileLoading(false);
                    // for the second load
                    ref.current.value = "";
                    alert(message);
                })
                .catch(() => {
                    ref.current.value = "";
                    setIsFileLoading(false);
                });
        }
    }

    const getStoreInfo = (id) => {
        const index = stores.findIndex((store) => store.id === id);
        if (index === -1)
            return null;

        let result = {
            name: stores[index].name
        };

        if (stores[index].statistics)
            result = {
                ...result,
                isLoaded: true,
                linked: stores[index].statistics.status.linked,
                notLinked: stores[index].statistics.status.notLinked,
                online: stores[index].statistics.connection.ok,
                offline: stores[index].statistics.connection.offline,
                queue: stores[index].statistics.queue,
                import: stores[index].statistics.import
                // test mock
                //import: {label_redraw_count:48,full_num:77,new_num:76,time_start:1578994738,time_end:1645204176,queue:{ok:46,waiting:0,error:0}}
            };
        return result;
    }

    const getStateIcon = (state) => {
        let stateImage = null;
        if (state === 'loading')
            stateImage = { icon: faSpinner, color: "orange", hint: t('Stores.stateLoading') };
        else if (state === 'ok')
            stateImage = { icon: faCheck, color: "green", hint: t('Stores.stateOk') };
        else if (state === 'error')
            stateImage = { icon: faTimes, color: "red", hint: t('Stores.stateError') };
        else if (state === 'pwd')
            stateImage = { icon: faUserLock, color: "red", hint: t('Stores.statePwd') };

        return stateImage;
    }

    const menu = [
        {
            text: t('Stores.go'),
            icon: faSignInAlt,
            onAction: goHandler,
            isShortcut: true
        }, {
            text: t('Stores.edit'),
            icon: faEdit,
            onAction: editHandler
        }, {
            text: t('Stores.remove'),
            icon: faTrashAlt,
            onAction: removeHandler
        }
    ];

    return (
        <>
            <PageLayout
                date={refreshDate}
                onRefresh={refreshHandler}
                name={t('Stores.stores')}>
                {isLoading
                    ?
                    <CircularProgress />
                    :
                    <>
                        <div className={classes.buttonContainer}>
                            <Button
                                className={classes.loadButton}
                                variant="outlined"
                                color="primary"
                                ref={anchorRef}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={toggleMenuHandler}>
                                {t('Stores.loadMaterials')}
                                <FontAwesomeIcon icon={faAngleDown} className={classes.buttonIcon} />
                            </Button>
                            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={closeMenuHandler}>
                                                <MenuList autoFocusItem={open} id="menu-list-grow">
                                                    <MenuItem onClick={loadImageClickHandler}>
                                                        <FontAwesomeIcon icon={faFileImage} className={classes.menuImage} />
                                                        {t('Stores.image')}</MenuItem>
                                                    <input
                                                        ref={loadImageRef}
                                                        accept="image/*"
                                                        type="file"
                                                        style={{ display: "none" }}
                                                        onChange={loadImageHandler} />

                                                    <MenuItem onClick={loadScriptClickHandler}>
                                                        <FontAwesomeIcon icon={faFileCode} className={classes.menuImage} />
                                                        {t('Stores.script')}</MenuItem>
                                                    <input
                                                        ref={loadScriptRef}
                                                        accept=".js"
                                                        type="file"
                                                        style={{ display: "none" }}
                                                        onChange={loadScriptHandler} />

                                                    <MenuItem onClick={loadStyleClickHandler}>
                                                        <FontAwesomeIcon icon={faFileSignature} className={classes.menuImage} />
                                                        {t('Stores.style')}</MenuItem>
                                                    <input
                                                        ref={loadStyleRef}
                                                        accept=".css"
                                                        type="file"
                                                        style={{ display: "none" }}
                                                        onChange={loadStyleHandler} />
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                            <Button
                                onClick={addHandler}
                                variant="contained"
                                color="primary">
                                {t('Stores.add')}</Button>
                            {isFileLoading &&
                                <div>
                                    <CircularProgress />
                                </div>
                            }
                        </div>
                        <Grid container>
                            <Grid item xs={12} md={7}>
                                <ItemTable
                                    onDblClick={goHandler}
                                    columns={storeColumns}
                                    data={stores}
                                    focusedRowEnabled={true}
                                    focusedRowKey={focusedRow}
                                    onFocusedRowChanged={focusedRowChangedHandler}
                                    keyExpr="id"
                                    menu={menu} />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <StoreInfo
                                    data={getStoreInfo(focusedRow)}
                                />
                            </Grid>
                        </Grid>
                    </>
                }
            </PageLayout>
        </>
    )
}

export default withCentral(
    withConfirmation(Stores)
);
