import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';

import axios, { getStoreLink } from '../../axios';
import ItemTable from '../ItemTable/ItemTable';
import { translateCell } from '../../const/i18n';
import linkHistoryColumns from './linkHistoryColumns';

const useStyles = makeStyles({
    rangeDiv: {
        paddingTop: 15,
        paddingBottom: 10
    },
    rangeHeaderDiv: {
        paddingBottom: 10
    },
    leftPicker: {
        width: 200,
        marginRight: 55
    },
    rightPicker: {
        width: 200
    },
    loadDiv: {
        marginTop: 28,
        marginBottom: 44,
    }
});

const LinkHistory = (props) => {
    const [startDate, setStartDate] = useState(moment().subtract(1, 'days'));
    const [endDate, setEndDate] = useState(moment());
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadHandler = () => {
        if (!startDate.isValid() || !endDate.isValid()) {
            alert(translateCell('ImportHistory.incorrect'));
            return;
        }
        setIsLoading(true);
        const start = startDate.utc().format("YYYY-MM-DDTHH:mm:ss");
        const end = endDate.utc().format("YYYY-MM-DDTHH:mm:ss");
        axios.get(`${getStoreLink()}/history`, { params: { time_start: start, time_end: end } })
            .then((response) => {
                setItems(response.data.data);
                setIsLoading(false);                
            })
            .catch(() => {
                setIsLoading(false);
            });
    }

    const startDateChangeHandler = (date) => {
        setStartDate(date);
    }

    const endDateChangeHandler = (date) => {
        setEndDate(date);
    }

    const classes = useStyles();
    const { t } = useTranslation();
    const updItems = items.map((item) => (
        {
            id: item.id,
            operation: item.operation === "0" ? t('History.link') : t('History.unlink'),
            serial: item.serial,
            item: item.name,
            code: item.code,
            time: +item.time * 1000
        }
    ));

    return (
        <>
            <div className={ classes.rangeDiv }>
                <KeyboardDateTimePicker
                    className={ classes.leftPicker }
                    variant="dialog"
                    ampm={ false }
                    disableToolbar={ false }
                    label={ t("ImportHistory.from") }
                    value={ startDate.local() }
                    onChange={ startDateChangeHandler }
                    format="YYYY-MM-DD HH:mm"
                />
                <KeyboardDateTimePicker
                    className={ classes.rightPicker }
                    variant="dialog"
                    ampm={ false }
                    label={ t("ImportHistory.to") }
                    value={ endDate.local() }
                    onChange={ endDateChangeHandler }
                    format="YYYY-MM-DD HH:mm"
                />
                <div className={ classes.loadDiv }>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={ loadHandler }>
                        { t('History.load') }</Button>
                </div>
            </div>
            { isLoading
                ?
                <CircularProgress />
                :
                <ItemTable
                    onDoubleClick={ null }
                    columns={ linkHistoryColumns }
                    data={ updItems }
                    keyExpr="id"
                    menu={ null } />
            }
        </>
    );
}

export default LinkHistory;
