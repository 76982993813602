import { translateCell } from '../../const/i18n';

const ledFilterValues = [
    { value: 5, name: translateCell('Leds.stateNone') },
    { value: 0, name: translateCell('Leds.stateDelivery') },
    { value: 1, name: translateCell('Leds.stateWaiting') },
    { value: 2, name: translateCell('Leds.stateStarted') },
    { value: 3, name: translateCell('Leds.stateFinished') },
    { value: 4, name: translateCell('Leds.stateError') },
];

const ledColumns = [
    {
        name: translateCell('Leds.ccode'),
        field: "serial",
        type: "string"
    }, {
        name: translateCell('Leds.cmode'),
        field: "led_mode_name",
        type: "string"
    }, {
        name: translateCell('Leds.cstatus'),
        field: "picture_state",
        display: "state_image",
        type: "icon",
        filterValues: ledFilterValues
    }, {
        name: translateCell('Leds.cstart'),
        field: "led_mode_start_time",
        type: "datetime"
    }, {
        name: translateCell('Leds.cstop'),
        field: "led_mode_stop_time",
        type: "datetime"
    }, {
        name: translateCell('Leds.cmotorhours'),
        field: "motorhours",
        type: "number"
    }, {
        name: translateCell('Leds.citem'),
        field: "item_name",
        type: "string"
    }
];

export default ledColumns;

