import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles({
    icon: {
        paddingRight: 8,
        paddingLeft: 8,
        paddingTop: 2,
        paddingBottom: 2,
    }
});

const MenuCell = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const buttonClickHandler = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const menuCloseHandler = () => {
        setAnchorEl(null);
    }

    const menuItemClickHandler = (id, element) => (event) => {
        menuCloseHandler();
        element.onAction(id);
    }

    const classes = useStyles();

    return (
        <div className="menu-cell">
            <IconButton
                className={ classes.icon }
                aria-owns={ anchorEl ? 'list-menu' : undefined }
                onClick={ buttonClickHandler }>
                <FontAwesomeIcon icon={ faEllipsisV } size="xs" />
            </IconButton >

            <Menu
                id="list-menu"
                anchorEl={ anchorEl }
                open={ Boolean(anchorEl) }
                onClose={ menuCloseHandler }>
                { props.menu.map((el, index) => (
                    <MenuItem key={ index } onClick={ menuItemClickHandler(props.cellData.key, el) }>
                        <FontAwesomeIcon icon={ el.icon } size="1x" />&nbsp;{ el.text }
                    </MenuItem>
                )) }
            </Menu>
        </div>
    );
}

export default MenuCell;
