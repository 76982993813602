import React, { useEffect } from 'react';

const HtmlView = (props) => {

    useEffect(() => {
        window.document.write(sessionStorage.getItem("html_page_data"));
        sessionStorage.removeItem("html_page_data");

    }, []);

    return (
        <div>
        </div >
    );
}

export default HtmlView;
