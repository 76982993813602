import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios, { getStoreLink } from '../../../axios';
import PageLayout from '../../Layout/PageLayout/PageLayout';
import { useConfirmation, withConfirmation } from '../../../services/ConfirmationService';
import withLibrary from '../withLibrary';
import TemplateCard from './TemplateCard';
import { routePaths } from '../../../routePaths';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    paper: {
        padding: 20,
    },
    header: {
        marginTop: 15
    },
    grid: {
        marginTop: 15,
        marginBottom: 40,
    }
});

const Templates = (props) => {
    const [templates, setTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [refreshDate, setRefreshDate] = useState(new Date());

    const confirm = useConfirmation();

    useEffect(() => {
        axios.get(`${getStoreLink()}/template`, {
            params: {
                type: "preview"
            }
        })
            .then((response) => {
                setTemplates(response.data.data);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, [refreshDate]);

    const addHandler = () => {
        props.history.push(routePaths.TemplatesNew);
    }

    const refreshHandler = () => {
        setIsLoading(true);
        setRefreshDate(new Date());
    }

    const removeHandler = (id) => {
        confirm({
            variant: "danger",
            catchOnCancel: false,
            title: t('Templates.removetitle'),
            description: t('Templates.removetext')
        })
            .then(() => {

                axios.delete(`${getStoreLink()}/template/` + id)
                    .then(() => {
                        const nTemplates = [...templates];
                        const index = nTemplates.findIndex((template) => template.id === id);
                        if (index !== -1) {
                            nTemplates.splice(index, 1);
                            setTemplates(nTemplates);
                        }
                    });
            })
    }

    const changeHandler = (id) => {
        const path = routePaths.TemplatesEdit.replace(":id", id.toString());
        props.history.push(path);
    }

    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div>
            <Button
                onClick={ addHandler }
                variant="contained"
                color="primary">
                { t('Templates.addTemplate') }</Button>
            <PageLayout
                date={ refreshDate }
                onRefresh={ refreshHandler }
                name={ t('Templates.templates') }>
                { isLoading
                    ?
                    <CircularProgress />
                    :
                    <Grid
                        className={ classes.grid }
                        container
                        spacing={ 2 }>
                        { templates.map(template => (
                            <Grid item key={ template.id }>
                                <TemplateCard
                                    id={ template.id }
                                    default={ template.default }
                                    rotation={ template.rotation }
                                    name={ template.name }
                                    width={ template.width }
                                    height={ template.height }
                                    color={ template.color }
                                    img={ template.generatedata }
                                    count={ template.count }
                                    onRemove={ removeHandler }
                                    onChange={ changeHandler } />
                            </Grid>
                        )) }
                    </Grid>
                }
            </PageLayout>
        </div>
    );
}

export default withLibrary(
    withConfirmation(Templates)
);
