import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import axios, { getStoreLink } from '../../axios';
import PageLayout from '../Layout/PageLayout/PageLayout';
import ItemTable from './../ItemTable/ItemTable';
import withLibrary from './withLibrary';
import fontColumns from './fontColumns';

const Fonts = (props) => {
    const [refreshDate, setRefreshDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(true);
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [fonts, setFonts] = useState([]);

    const loadRef = useRef();

    useEffect(() => {
        loadFonts();

    }, [refreshDate]);

    const loadFonts = () => {
        axios.get(`${getStoreLink()}/renderers`)
            .then((response) => {
                const fontsTmp = response.data.data.flatMap((renderer) => {
                    if (renderer.fonts && renderer.fonts.installed)
                        return renderer.fonts.installed.map((font) => ({ ...font, renderer: renderer.path }));
                    return [];
                });
                setFonts(fontsTmp);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }

    const refreshHandler = () => {
        setIsLoading(true);
        setRefreshDate(new Date());
    }

    const loadFileClickHandler = () => {
        loadRef.current.click();
    }

    const loadFileHandler = (e) => {
        if (e.target.files) {
            setIsFileLoading(true);
            const file = e.target.files[0];
            const body = new FormData();
            body.append('file', file);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            axios.post(`${getStoreLink()}/resource/fonts`, body, config)
                .then(() => {
                    setIsFileLoading(false);
                    refreshHandler();
                    // for the second load
                    loadRef.current.value = "";
                    alert(t('Library.font_imported'));
                })
                .catch(() => {
                    setIsFileLoading(false);
                });
        }
    }

    const { t } = useTranslation();

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={loadFileClickHandler}>
                {t('Library.loadFont')}</Button>
            <input
                ref={loadRef}
                accept=".otf,.ttf"
                id="input-button-file"
                type="file"
                style={{ display: "none" }}
                onChange={loadFileHandler} />
            {isFileLoading &&
                <div>
                    <CircularProgress />
                </div>
            }
            <PageLayout
                date={refreshDate}
                onRefresh={refreshHandler}
                name={t('Library.fonts')}>
                {isLoading
                    ?
                    <CircularProgress />
                    :
                    <ItemTable
                        onDoubleClick={null}
                        columns={fontColumns}
                        data={fonts}
                        keyExpr="path"
                        menu={null} />
                }
            </PageLayout>
        </>
    )
}

export default withLibrary(Fonts);
