import React from 'react';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import SideMenu from '../../SideMenu/SideMenu';

const useStyles = makeStyles((theme) => ({
    main: {
        flexGrow: 1,
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 90,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
        display: "block"
    },
}));

const FullLayout = (props) => {

    const { component: Component, ...rest } = props;
    const classes = useStyles();

    return (
        <Route
            { ...rest }
            render={ (props) => (
                <>
                    <SideMenu
                        withoutMenu={ true }
                    />
                    <main className={ classes.main } >
                        <Component { ...props } />
                    </main>
                </>
            ) }
        />
    )
};

export default FullLayout;