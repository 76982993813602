import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import '@polymer/font-roboto';
import ruMessages from "devextreme/localization/messages/ru.json";
import { locale, loadMessages } from "devextreme/localization";

import FullLayout from './components/Layout/FullLayout/FullLayout';
import DefaultLayout from './components/Layout/DefaultLayout/DefaultLayout';
import Login from './components/Login/Login';
import Users from '../src/components/Users/Users';
import Groups from '../src/components/Groups/Groups';
import Stores from '../src/components/Central/Stores';
import StoreView from '../src/containers/Stores/StoreView';
import Renderers from '../src/components/Central/Renderers';
import Main from '../src/components/Main/Main';
import Esls from '../src/containers/Esls/EslsNew';
import EslView from '../src/containers/Esls/EslView';
import Leds from '../src/components/Leds/Leds';
import Controllers from '../src/components/Controllers/Controllers';
import Items from '../src/containers/Items/Items';
import ItemView from '../src/containers/Items/ItemView';
import Templates from './components/Library/Templates/Templates';
import TemplateView from './components/Library/Templates/TemplateView';
import Scripts from './components/Library/Scripts';
import Styles from './components/Library/Styles';
import Fonts from './components/Library/Fonts';
import Images from './components/Library/Images';
import LedModes from './components/Library/LedModes/LedModes';
import LedModeView from './components/Library/LedModes/LedModeView';
import History from '../src/components/History/History';
import Diagnostics from '../src/components/Diagnostics/Diagnostics';
import HtmlView from './components/Html/HtmlView';
import { routePaths } from './routePaths';
import withRoot from './hoc/withRoot/withRoot';

const App = () => {
  loadMessages(ruMessages);
  locale('ru');

  return (    
    <Switch>
      <Route exact path={ routePaths.SignIn } component={ Login } />
      <FullLayout exact path={ routePaths.Central } component={ Stores } />
      <FullLayout exact path={ routePaths.Stores } component={ Stores } />
      {<FullLayout path={ routePaths.StoresNew } component={ StoreView } />}
      {<FullLayout path={ routePaths.StoresEdit } component={ StoreView } />}
      <FullLayout path={ routePaths.Renderers } component={ Renderers } />   

      <DefaultLayout path={ routePaths.Users } component={ Users } />
      <DefaultLayout path={ routePaths.Groups } component={ Groups } />
      <DefaultLayout path={ routePaths.Main } component={ Main } />

      <DefaultLayout exact path={ routePaths.Esls } component={ Esls } />
      <DefaultLayout path={ routePaths.EslsNew } component={ EslView } />
      <DefaultLayout path={ routePaths.EslsEdit } component={ EslView } />

      <DefaultLayout exact path={ routePaths.Leds } component={ Leds } />

      <DefaultLayout exact path={ routePaths.Controllers } component={ Controllers } />

      <DefaultLayout exact path={ routePaths.Items } component={ Items } />
      <DefaultLayout path={ routePaths.ItemsNew } component={ ItemView } />
      <DefaultLayout path={ routePaths.ItemsEdit } component={ ItemView } />

      <DefaultLayout exact path={ routePaths.Library } component={ Templates } />
      <DefaultLayout path={ routePaths.Scripts } component={ Scripts } />
      <DefaultLayout path={ routePaths.Styles } component={ Styles } />
      <DefaultLayout path={ routePaths.Fonts } component={ Fonts } />
      <DefaultLayout path={ routePaths.Images } component={ Images } />
      <DefaultLayout exact path={ routePaths.LedModes } component={ LedModes } />
      <DefaultLayout path={ routePaths.LedModesNew } component={ LedModeView } />
      <DefaultLayout path={ routePaths.LedModesEdit } component={ LedModeView } />
      <DefaultLayout exact path={ routePaths.Templates } component={ Templates } />
      <DefaultLayout path={ routePaths.TemplatesNew } component={ TemplateView } />
      <DefaultLayout path={ routePaths.TemplatesEdit } component={ TemplateView } />      

      <DefaultLayout path={ routePaths.History } component={ History } />
      <DefaultLayout path={ routePaths.Diagnostics } component={ Diagnostics } />
      <Route exact path={ routePaths.HtmlView } component={ HtmlView } />
    </Switch>
  );
}

export default withRoot(App);
