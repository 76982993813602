import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const AddAttributeDialog = (props) => {
    const { t } = useTranslation();
    
    return (
        <Dialog
            open={ props.open }
            onClose={ props.onCloseDialog }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            { props.isEdit
                ?
                <DialogTitle id="alert-dialog-title">{ t('AddAttributeDialog.edit') }</DialogTitle>
                :
                <DialogTitle id="alert-dialog-title">{ t('AddAttributeDialog.add') }</DialogTitle>
            }
            <DialogContent>
                <DialogContentText>
                    { t('AddAttributeDialog.input') }</DialogContentText>
                <TextField
                    disabled={ props.isEdit }
                    required
                    id="name"
                    name="name"
                    label={ t('AddAttributeDialog.name') }
                    value={ props.name }
                    onChange={ props.onChange }
                    margin="normal"
                    fullWidth />
                <TextField
                    required
                    id="value"
                    name="value"
                    label={ t('AddAttributeDialog.value') }
                    value={ props.value }
                    onChange={ props.onChange }
                    margin="normal"
                    fullWidth />
            </DialogContent>
            <DialogActions>
                <Button onClick={ props.onSave } variant="contained" color="primary">
                    { t('AddAttributeDialog.save') }</Button>
                <Button onClick={ props.onCloseDialog } variant="contained" color="primary">
                    { t('AddAttributeDialog.cancel') }</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddAttributeDialog;
