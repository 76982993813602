import React from 'react';
import LinkDialog from '../components/Dialogs/LinkDialog';

const LinkSearchServiceContext = React.createContext(Promise.reject);

export const useLinkSearch = () =>
    React.useContext(LinkSearchServiceContext);

export const LinkSearchServiceProvider = ({ children }) => {
    const [linkSearchState, setLinkSearchState] = React.useState(null);

    const awaitingPromiseRef = React.useRef();

    const openLinkSearch = (options) => {
        setLinkSearchState(options);
        
        return new Promise((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    };

    const handleClose = () => {
        if (linkSearchState.catchOnCancel && awaitingPromiseRef.current) {
            awaitingPromiseRef.current.reject();
        }

        setLinkSearchState(null);
    };

    const handleSubmit = (code) => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve(code);
        }

        setLinkSearchState(null);
    };

    return (
        <>
            <LinkSearchServiceContext.Provider
                value={ openLinkSearch }
                children={ children }
            />

            <LinkDialog
                open={ Boolean(linkSearchState) }
                onSubmit={ handleSubmit }
                onClose={ handleClose }
                { ...linkSearchState }
            />
        </>
    );
};

export const withLinkSearch = (Component) => {
    const WithLinkSearch = (props) => {
        return (
            <LinkSearchServiceProvider>
                <Component { ...props } />
            </LinkSearchServiceProvider>
        );
    }

    return WithLinkSearch;
};
