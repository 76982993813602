import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    root: {
        cursor: 'pointer'
    },
    cell: {
        padding: "4px 10px 4px 10px",
        whiteSpace: "pre"
    }
});

class ItemTableRowBase extends Component {
    state = {
        anchorEl: null
    };

    handleButtonClick = (e) => {
        this.setState({ anchorEl: e.currentTarget });
    }

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    }

    handleMenuItemClick = (e, element) => {        
        this.handleMenuClose();
        element.onAction(this.props.rowId);
    }

    handleSelect = row => event => {
        this.props.onSelect(row);
    }

    render() {
        const { classes, isItemSelected } = this.props;
        const { data, menu, onDoubleClick } = this.props;
        const { anchorEl } = this.state;

        return (
            <TableRow
                onDoubleClick={ onDoubleClick }
                className={ classes.root }
                role="checkbox"
                aria-checked={isItemSelected}
                hover>
                { this.props.isSelectable &&
                    <TableCell padding="checkbox">
                        <Checkbox                            
                            onClick={ this.handleSelect(data[0]) }
                            checked={ isItemSelected }
                        />
                    </TableCell>
                }
                { this.props.menu &&
                    <TableCell className={ classes.cell }>
                        <div>
                            <IconButton
                                aria-owns={ anchorEl ? 'list-menu' : undefined }
                                onClick={ this.handleButtonClick }
                            >
                                <FontAwesomeIcon icon={ faEllipsisV } size="xs" />
                            </IconButton >

                            <Menu
                                id="list-menu"
                                anchorEl={ anchorEl }
                                open={ Boolean(anchorEl) }
                                onClose={ this.handleMenuClose }
                            >
                                { menu.map((el, index) => (
                                    <MenuItem key={ index } onClick={ (e) => this.handleMenuItemClick(e, el) }>
                                        <FontAwesomeIcon icon={ el.icon } />
                                        &nbsp;{ el.text }
                                    </MenuItem>
                                )) }
                            </Menu>
                        </div>
                    </TableCell>
                }
                { this.props.shortcuts.length > 0 &&
                    <TableCell className={ classes.cell }>
                        { this.props.shortcuts.map((sc, index) => (
                            <IconButton key={ index } onClick={ (e) => this.handleMenuItemClick(e, sc) }>
                                <FontAwesomeIcon icon={ sc.icon } size="xs" />
                            </IconButton>
                        )) }
                    </TableCell>
                }
                { data.map((value, valIndex) => (
                    <TableCell className={ classes.cell } key={ valIndex }>{ value }</TableCell>
                )) }
            </TableRow>
        );
    }
}

export default withStyles(styles)(ItemTableRowBase);