import React from 'react';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import CssBaseline from '@material-ui/core/CssBaseline';

const theme = createTheme({
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
        borderRadius: "16px",
        paddingTop: 4,
        paddingBottom: 4,
        width: 200
      },
      outlined: {
        borderRadius: "16px",
        paddingTop: 4,
        paddingBottom: 4,
        width: 200
      },
      outlinedPrimary: {
        color: "#212121"
      }
    },
  },
  palette: {
    primary: {
      light: "#ff6f60",
      main: "#E53935",
      dark: "#ab000d",
      contrastText: "#fff"
    },
    secondary: {
      light: green[300],
      main: green[500],
      dark: green[700],
    },
    background: {
      default: "#fff"
    }
  },
  typography: {
    useNextVariants: true,
    h1: {
      fontSize: 28,
      fontWeight: "normal",
      fontFamily: "Roboto"
    },
    h2: {
      fontSize: 20,
      fontWeight: "normal",
      fontFamily: "Roboto"
    },
    h3: {
      fontSize: 18,
      fontWeight: "normal",
      fontFamily: "Roboto"
    },
    body2: {
      fontSize: 16,      
      fontWeight: 300,
      fontFamily: "Roboto"
    },
    button: {
      fontSize: 14,
      textTransform: "none"
    }
  }
});

function withRoot(Component) {
  function WithRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <ThemeProvider theme={ theme }>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */ }
        <CssBaseline />
        <Component { ...props } />
      </ThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
