import React, { Component } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { translateCell } from '../../const/i18n';
import { isHandlerEnabled } from '../../axios';


const withErrorHandler = (WrappedComponent, axios) => {
    return class extends Component {
        state = {
            error: null
        }

        componentDidMount() {
            this.reqInterceptor = axios.interceptors.request.use(req => {
                this.setState({ error: null });
                return req;
            });
            this.resInterceptor = axios.interceptors.response.use(res => res, error => {
                if (isHandlerEnabled(error.config)) {
                    this.setState({ error: error });
                }

                return Promise.reject(error);
            });
        }

        componentWillUnmount() {
            axios.interceptors.request.eject(this.reqInterceptor);
            axios.interceptors.response.eject(this.resInterceptor);
        }

        errorConfirmedHandler = () => {
            this.setState({ error: null });
        }

        render() {
            return (
                <>
                    <Dialog
                        open={ this.state.error !== null }
                        onClose={ this.errorConfirmedHandler }
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{ translateCell("withErrorHandler.error") }</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                { this.state.error ? this.state.error.message : "" }
                            </DialogContentText>
                            <DialogActions>
                                <Button onClick={ this.errorConfirmedHandler } color="primary">
                                    { translateCell("withErrorHandler.ok") }</Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                    <WrappedComponent { ...this.props } />
                </>
            );
        }
    }
}

export default withErrorHandler;
