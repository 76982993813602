import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const InputDialog = ({
    open,
    previous,
    title,
    message,
    isNumeric,
    onSubmit,
    onClose
}) => {
    const [value, setValue] = useState('');

    useEffect(() => {
        if (isNumeric)
            setValue(0);
        if (previous)
            setValue(previous);
    }, [isNumeric, previous]);

    const { t } = useTranslation();

    const changeHandler = (e) => {
        setValue(isNumeric ? +e.target.value : e.target.value);
    }

    const closeHandler = () => {
        onClose();
        setValue(isNumeric ? 0 : '');
    }

    const submitHandler = () => {
        onSubmit(value);
        setValue(isNumeric ? 0 : '');
    }

    return (
        <Dialog
            open={ open }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{ title }</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    { message }
                </DialogContentText>
                <TextField
                    value={ isNumeric ? value.toString() : value }
                    onChange={ changeHandler }
                    margin="normal"
                    type={ isNumeric ? "number" : null }
                    fullWidth />
            </DialogContent>
            <DialogActions>
                <Button onClick={ submitHandler } variant="contained" color="primary">
                    { t('InputDialog.set') }</Button>
                <Button onClick={ closeHandler } variant="contained" color="primary">
                    { t('InputDialog.cancel') }</Button>
            </DialogActions>
        </Dialog>
    )
}

export default InputDialog;
