import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { isCentralServer } from '../../const';
import axios from '../../axios';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import SignIn from './SignIn';
import { routePaths } from '../../routePaths';
import logo from '../../assets/icon2.png';

const useStyles = makeStyles({
    header: {
        textAlign: "center",
        paddingTop: "50px"
    },
});

const Login = (props) => {
    const [data, setData] = useState({
        domain: "",
        login: "",
        password: ""
    });

    const [isLoading, setIsLoading] = useState(false);

    const submitHandler = (e) => {
        e.preventDefault();
        setIsLoading(true);
        axios.post("/login", { login: data.login, passwd: data.password })
            .then(resp => {
                setIsLoading(false);
                sessionStorage.setItem("qpstkn", resp.data.data.qpstkn);
                sessionStorage.setItem("user_id", resp.data.data.user_id);
                sessionStorage.setItem("name", resp.data.data.name);
                sessionStorage.setItem("version", resp.data.data.version);
                if (resp.data.data.namestore)
                    sessionStorage.setItem("namestore", resp.data.data.namestore);

                if (isCentralServer)
                    props.history.push(routePaths.Central);
                else
                    props.history.push(routePaths.Main);
            }).catch(error => {
                setIsLoading(false);
            });
    }

    const changeHandler = (name) => (e) => {
        setData({ ...data, [name]: e.target.value });
    }

    const classes = useStyles();

    return (
        <>
            <div className={ classes.header }>
                <img src={ logo } alt="logo" />
                { isLoading &&
                    <div>
                        <CircularProgress />
                    </div>
                }
                <SignIn
                    data={ data }
                    onChange={ changeHandler }
                    onSubmit={ submitHandler } />
            </div>
        </>
    );
}

export default withErrorHandler(Login, axios);
