import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../locale/en';
import ru from '../locale/ru';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en,
            ru
        },
        lng: 'ru',
        fallbackLng: 'ru',
        debug: true,
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
        },
        react: {
            wait: true,
        },
    });


export const translateCell = (cell) => {
    return (i18n.t(cell));
}

export const translateLang = () => {
    return (i18n.language);
}

export default i18n;
