import React from "react";
import moment from 'moment';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faLink, faUnlink, faWifi } from "@fortawesome/free-solid-svg-icons";


const useStyles = makeStyles(theme => ({
    header: {
        paddingTop: 3,
        paddingLeft: 20,
        height: 30,
        width: "auto",
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        borderLeft: "1px solid white"
    },
    paper: {
        margin: 20,
        padding: 10,
        backgroundColor: "#F2F2F2"
    },
    subheader: {
        textTransform: "uppercase",
        color: theme.palette.primary.main,
        marginBottom: 20
    },
    icon: {
        marginRight: 15
    },
    rightAlign: {
        textAlign: "right"
    },
    centerAlign: {
        textAlign: "center"
    },
    leftAlign: {
        textAlign: "left"
    },
    lastImport: {
        fontSize: 14,
        marginTop: 25,
        marginBottom: 25,
    },
    importHeaderCell: {
        color: "#718792"
    },
    importDateCell: {
        color: "#718792",
        fontSize: 10
    },
    head: {
        fontSize: 12,
        padding: 5
    },
    cell: {
        fontSize: 14,
        padding: 5
    }
}));

const StoreInfo = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <div className={classes.header}>
                {props.data &&
                    <Typography color="inherit">{props.data.name}</Typography>
                }
            </div>
            {props.data && props.data.isLoaded &&
                <Paper className={classes.paper}>
                    <Typography className={classes.subheader} color="inherit">{t('Stores.storeInfo')}</Typography>
                    <Grid container>
                        <Grid item xs={3}>
                            <FontAwesomeIcon className={classes.icon} icon={faLink} color="#409FCA" />{props.data.linked}</Grid>
                        <Grid className={classes.leftAlign} item xs={3}>
                            <FontAwesomeIcon className={classes.icon} icon={faUnlink} color="#455A64" />{props.data.notLinked}</Grid>
                        <Grid className={classes.rightAlign} item xs={3}>
                            <FontAwesomeIcon className={classes.icon} icon={faWifi} color="#80E27E" />{props.data.online}</Grid>
                        <Grid className={classes.rightAlign} item xs={3}>
                            <FontAwesomeIcon className={classes.icon} icon={faBan} color="#E53935" />{props.data.offline}</Grid>
                    </Grid>
                    {props.data.import &&
                        <Grid container className={classes.lastImport}>
                            <Grid item xs={6} className={classes.importHeaderCell}>{t('Stores.lastImport')}</Grid>
                            <Grid item xs={4}>{t('Stores.items')}</Grid>
                            <Grid item xs={2} className={classes.rightAlign}>{props.data.import.full_num}</Grid>
                            <Grid item xs={6} className={classes.importDateCell}>
                                {`${moment.unix(props.data.import.time_start).format('DD.MM.YY HH:mm')} - \
                                ${moment.unix(props.data.import.time_end).format('DD.MM.YY HH:mm')}`}
                            </Grid>
                            <Grid item xs={4}>{t('Stores.changedItems')}</Grid>
                            <Grid item xs={2} className={classes.rightAlign}>{props.data.import.new_num}</Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={4}>{t('Stores.eslsToDraw')}</Grid>
                            <Grid item xs={2} className={classes.rightAlign}>{props.data.import.label_redraw_count}</Grid>
                        </Grid>
                    }
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.head}>{t('Stores.status')}</TableCell>
                                <TableCell className={classes.head}>{t('Stores.all')}</TableCell>
                                {props.data.import && <TableCell className={classes.head}>{t('Stores.import')}</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.cell}>{t('Stores.statusOk')}</TableCell>
                                <TableCell className={classes.cell}>{props.data.queue.ok}</TableCell>
                                {props.data.import && <TableCell className={classes.cell}>{props.data.import.queue.ok}</TableCell>}
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>{t('Stores.statusRendererWaiting')}</TableCell>
                                <TableCell className={classes.cell}>{props.data.queue.renderer_waiting}</TableCell>
                                {props.data.import && <TableCell className={classes.cell}>{props.data.import.queue.renderer_waiting}</TableCell>}
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>{t('Stores.statusStarWaiting')}</TableCell>
                                <TableCell className={classes.cell}>{props.data.queue.star_waiting}</TableCell>
                                {props.data.import && <TableCell className={classes.cell}>{props.data.import.queue.star_waiting}</TableCell>}
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>{t('Stores.statusRendererError')}</TableCell>
                                <TableCell className={classes.cell}>{props.data.queue.renderer_error}</TableCell>
                                {props.data.import && <TableCell className={classes.cell}>{props.data.import.queue.renderer_error}</TableCell>}
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>{t('Stores.statusStarError')}</TableCell>
                                <TableCell className={classes.cell}>{props.data.queue.star_error}</TableCell>
                                {props.data.import && <TableCell className={classes.cell}>{props.data.import.queue.star_error}</TableCell>}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            }
        </>
    );
}

export default StoreInfo;
