import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Typography,
    Button,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    LinearProgress
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import axios, { getStoreLink } from '../../axios';

const useStyles = makeStyles({
    root: {
        marginTop: 40
    },
    rightGrid: {
        textAlign: "right"
    },
    updated: {
        color: "#718792"
    },
    grid: {
        marginBottom: 6
    },
    subhead: {
        fontSize: 24,
        marginBottom: 21
    },
    span: {
        fontSize: 16,
    }
});

const UpdateState = (props) => {
    const [data, setData] = useState([]);
    const [refreshDate, setRefreshDate] = useState(new Date());
    const [status, setStatus] = useState({
        startedUser: "",
        stoppedUser: "",
        persentage: 0,
        status: "",
        message: "",
        updateTime: ""
    })

    const { t, i18n } = useTranslation();

    useEffect(() => {
        axios.get(`${getStoreLink()}/firmware/` + props.updateId)
            .then((response) => {
                const manifest = response.data.data.manifest;
                if (manifest) {
                    let total = 0;
                    let tCompleted = 0;
                    const updateData = Object.keys(manifest).map((key) => {
                        const state = response.data.data[manifest[key].hw_version];
                        const completed = state && state.completed ? state.completed : 0;
                        const waiting = state && state.waiting ? state.waiting : 0;
                        const error = state && state.error ? state.error : 0;
                        total += completed + waiting + error;
                        tCompleted += completed;
                        return {
                            hw_type: manifest[key].hw_type,
                            version: manifest[key].version,
                            filename: key,
                            completed,
                            waiting,
                            error
                        }
                    });
                    setData(updateData);
                    let persentage = total !== 0 ? tCompleted * 1.0 / total * 100 : 0;
                    if (response.data.data.status === "ok")
                        persentage = 100;
                    setStatus({
                        persentage: +persentage.toFixed(1),
                        startedUser: response.data.data.start_user_name,
                        stoppedUser: response.data.data.stop_user_name,
                        status: response.data.data.status,
                        message: response.data.data.message,
                        updateTime: new Date(response.data.data.update_tm * 1000).toLocaleString(i18n.language === 'ru' ? 'ru-RU' : 'en-US')
                    });
                }
                else {
                    setStatus({
                        persentage: 100,
                        startedUser: response.data.data.start_user_name,
                        status: response.data.data.status,
                        message: response.data.data.message,
                        updateTime: new Date(response.data.data.update_tm * 1000).toLocaleString(i18n.language === 'ru' ? 'ru-RU' : 'en-US')
                    });
                }
            });
    }, [refreshDate, props.updateState, props.updateId]);

    const refreshHandler = () => {
        setRefreshDate(new Date());
    }

    const classes = useStyles();


    return (
        <div className={ classes.root }>
            <Typography variant="h2" className={ classes.subhead }>${ t('UpdateState.update') } <span className={ classes.span }>{ status.updateTime }</span>
                <Button onClick={ refreshHandler }>
                    <FontAwesomeIcon className={ classes.refresh } icon={ faSyncAlt } />
                </Button>
            </Typography>
            <Grid container className={ classes.grid }>
                <Grid item xs={ 6 }>
                    <Typography variant="body2" className={ classes.updated }>
                        { status.status !== "error"
                            ?
                            `${t('UpdateState.updated')} ${status.persentage}%`
                            :
                            `${t('UpdateState.error')} ${status.message} `
                        }
                    </Typography>
                </Grid>
                <Grid item xs={ 6 } className={ classes.rightGrid }>
                    <Typography variant="body2" className={ classes.updated }>
                        { status.status === "stopped"
                            ?
                            `${t('UpdateState.stoppedBy')} ${status.stoppedUser}`
                            :
                            `${t('UpdateState.updatedBy')} ${status.startedUser}`
                        }
                    </Typography>
                </Grid>
            </Grid>
            <LinearProgress variant="determinate" value={ status.persentage } />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{ t('Diagnostics.file') }</TableCell>
                        <TableCell>{ t('Diagnostics.version') }</TableCell>
                        <TableCell>{ t('Diagnostics.type') }</TableCell>
                        <TableCell>{ t('Diagnostics.total') }</TableCell>
                        <TableCell>{ t('Diagnostics.success') }</TableCell>
                        <TableCell>{ t('Diagnostics.failed') }</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { data.map((d) => (
                        <TableRow key={ d.filename }>
                            <TableCell>{ d.filename }</TableCell>
                            <TableCell>{ d.version }</TableCell>
                            <TableCell>{ d.hw_type }</TableCell>
                            <TableCell>{ d.completed + d.waiting + d.error }</TableCell>
                            <TableCell>{ d.completed }</TableCell>
                            <TableCell>{ d.error }</TableCell>
                        </TableRow>
                    ))
                    }
                </TableBody>
            </Table>
        </div >
    )
}

export default UpdateState;
