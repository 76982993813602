import { translateCell } from '../../const/i18n';

const linkHistoryColumns = [
    {
        name: translateCell('History.num'),
        field: "id",
        type: "number"
    },
    {
        name: translateCell('History.action'),
        field: "operation",
        type: "string"
    },
    {
        name: translateCell('History.esl'),
        field: "serial",
        type: "number"
    },
    {
        name: translateCell('History.item'),
        field: "item",
        type: "string"
    },
    {
        name: translateCell('History.vendorcode'),
        field: "code",
        type: "string"
    },
    {
        name: translateCell('History.time'),
        field: "time",
        type: "datetime"
    },
];

export default linkHistoryColumns;