import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import axios, { getStoreLink } from '../../axios';
import ItemTable from '../ItemTable/ItemTable';
import renderHistoryColumns from './renderHistoryColumns';

const useStyles = makeStyles({
    container: {
        paddingBottom: 24
    },
    rightGrid: {
        textAlign: "right"
    },
    refresh: {
        color: "#718792"
    }
});

const RenderHistory = (props) => {
    const [refreshDate, setRefreshDate] = useState(new Date());
    const [renders, setRenders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        axios.get(`${getStoreLink()}/custom_rasterization`)
            .then((response) => {
                setRenders(response.data.data);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, [refreshDate]);

    const refreshHandler = () => {
        setRefreshDate(new Date());
    }

    const classes = useStyles();
    const { i18n } = useTranslation();

    const updRenders = renders.map((item) => {
        let stateImage = null;
        if (item.count < item.full)
            stateImage = { icon: faSpinner, color: "orange" };
        else
            stateImage = { icon: faCheck, color: "green" };

        return {
            path: item.path,
            count: item.count,
            full: item.full,
            image: stateImage,
            status: item.count < item.full
        }
    });

    return (
        <>
            <Grid container className={ classes.container }>
                <Grid item xs={ 6 }>
                </Grid>
                <Grid item xs={ 6 } className={ classes.rightGrid }>
                    <Typography variant="body2">{ refreshDate.toLocaleString(i18n.language === 'ru' ? 'ru-RU' : 'en-US') }
                        <Button onClick={ refreshHandler }>
                            <FontAwesomeIcon className={ classes.refresh } icon={ faSyncAlt } />
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
            { isLoading
                ?
                <CircularProgress />
                :
                <ItemTable
                    onDoubleClick={ null }
                    columns={ renderHistoryColumns }
                    data={ updRenders }
                    keyExpr="path"
                    menu={ null } />
            }
        </>
    );
}

export default RenderHistory;
