const fileSave = (name, data) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(link.href);
}

export default fileSave;