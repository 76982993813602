import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo, faUndo } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import eslImage from '../../assets/esl.png';

const useStyles = makeStyles({
    buttons: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    image: {
        textAlign: 'center',
        height: 270,
        paddingTop: 65
    }
})

const RotateDialog = ({
    open,
    onSubmit,
    onClose,
    title,
    previousAngle,
    defaultRotate
}) => {
    const [angle, setAngle] = useState(0);

    useEffect(() => {
        if (previousAngle !== null && previousAngle !== undefined) {
            setAngle(previousAngle);
        }
    }, [previousAngle]);

    const { t } = useTranslation();
    const classes = useStyles();

    const leftRotateHandler = () => {
        setAngle(prev => (prev === 0 ? 270 : prev - 90));
    }

    const rightRotateHandler = () => {
        setAngle(prev => (prev === 270 ? 0 : prev + 90));
    }

    const submitHandler = () => {
        onSubmit(angle);
    }

    const closeHandler = () => {
        onClose();
    }

    const drawAngle = angle + defaultRotate > 270 ? 0 : angle + defaultRotate;

    return (
        <Dialog
            open={ open }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{ title }</DialogTitle>
            <DialogContent>
                <div className={ classes.buttons }>
                    <Button onClick={ leftRotateHandler }>
                        <FontAwesomeIcon icon={ faUndo } size="lg" />
                    </Button>
                    <Button onClick={ rightRotateHandler }>
                        <FontAwesomeIcon icon={ faRedo } size="lg" />
                    </Button>
                </div>
                <div className={ classes.image }>
                    <img style={ { transform: `rotate(${drawAngle}deg)` } } src={ eslImage } alt="esl" />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={ submitHandler } variant="contained" color="primary">
                    { t('LinkDialog.save') }</Button>
                <Button onClick={ closeHandler } variant="contained" color="primary">
                    { t('LinkDialog.cancel') }</Button>
            </DialogActions>
        </Dialog>
    )

}

export default RotateDialog;
