import React from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles({
    root: {
        marginBottom: 70
    },
    paper: {
        padding: "24px 32px",
        margin: "21px 0",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        boxShadow: "-2px 2px 4px rgba(0, 0, 0, 0.12), 2px 0px 4px rgba(0, 0, 0, 0.12)",
        borderRadius: 5
    },
    refresh: {
        color: "#718792"
    },
    children: {
        paddingTop: 24
    }
});


const PageLayout = (props) => {

    const classes = useStyles();

    const refreshHandler = () => {
        props.onRefresh();
    }

    return (
        <Paper className={ classes.paper }>
            <Grid container>
                <Grid item xs={ 6 }>
                    <Typography variant="h1">
                        { props.name }</Typography>
                </Grid>
                <Grid item xs={ 6 } style={ { textAlign: "right" } }>
                    { !props.withoutRefresh &&
                        <Typography variant="body2">
                            { props.date.toLocaleString('ru-RU') }
                            <Button onClick={ refreshHandler }>
                                <FontAwesomeIcon className={ classes.refresh } icon={ faSyncAlt } />
                            </Button>
                        </Typography>
                    }
                </Grid>
            </Grid>
            <div className={ classes.children }>
                { props.children }
            </div>
        </Paper>
    );
}

export default PageLayout;