import React, { useState, useEffect, useRef } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import moment from 'moment';

import PageLayout from "../Layout/PageLayout/PageLayout";
import ItemTable from "../ItemTable/ItemTable";
import withCentral from "./withCentral";
import rendererColumns from "./rendererColumns";
import axios from "../../axios";
import FontsList from "./FontsList";

const useStyles = makeStyles({
    buttonContainer: {
        textAlign: "right",
        marginBottom: 40
    }
});

const Renderers = () => {
    const [renderers, setRenderers] = useState([]);
    const [focusedRow, setFocusedRow] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [refreshDate, setRefreshDate] = useState(new Date());

    const loadRef = useRef();

    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        axios.get('/renderers')
            .then((response) => {
                const renderers = [...response.data.data];
                renderers.forEach(renderer => {
                    renderer.fontsCount = renderer.fonts.installed.length;
                    if (renderer.statistics) {
                        renderer.workers = `${renderer.statistics.busyWorkers} / ${renderer.statistics.totalWorkers}`;
                        renderer.pendingTasks = renderer.statistics.pendingTasks;
                        const tm = moment.duration(renderer.statistics.uptime, 'seconds');
                        renderer.uptime = `${tm.days()} ${tm.hours()}:${tm.minutes()}:${tm.seconds()}`;
                        renderer.updateTime = renderer.statistics.lastTm * 1000;
                        renderer.status = renderer.statistics.status;
                    }
                });
                if (renderers.length > 0) {
                    setFocusedRow(renderers[0].path);
                }
                setRenderers(renderers);
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false);
            });
    }, [refreshDate]);

    const refreshHandler = () => {
        setIsLoading(true);
        setRefreshDate(new Date());
    }

    const focusedRowChangedHandler = (id) => {
        setFocusedRow(id);
    }

    const loadFileClickHandler = () => {
        loadRef.current.click();
    }

    const loadFileHandler = (e) => {
        if (e.target.files) {
            setIsFileLoading(true);
            const file = e.target.files[0];
            const body = new FormData();
            body.append('file', file);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            axios.post(`/resource/fonts`, body, config)
                .then(() => {
                    setIsFileLoading(false);
                    // for the second load
                    loadRef.current.value = "";
                    alert(t('Library.font_imported'));
                })
                .catch(() => {
                    loadRef.current.value = "";
                    setIsFileLoading(false);
                });
        }
    }

    const getFonts = () => {
        const index = renderers.findIndex((r) => r.path === focusedRow);
        if (index === -1)
            return [];

        return renderers[index].fonts.installed;
    }

    return (
        <>
            <PageLayout
                date={refreshDate}
                onRefresh={refreshHandler}
                name={t('Renderers.renderers')}>
                {isLoading
                    ?
                    <CircularProgress />
                    :
                    <>
                        <div className={classes.buttonContainer}>
                            <Button variant="contained" color="primary" onClick={loadFileClickHandler}>{t('Renderers.loadFont')}</Button>
                            <input
                                ref={loadRef}
                                accept=".otf,.ttf"
                                id="input-button-file"
                                type="file"
                                style={{ display: "none" }}
                                onChange={loadFileHandler} />
                            {isFileLoading &&
                                <div>
                                    <CircularProgress />
                                </div>
                            }
                        </div>
                        <Grid container>
                            <Grid item xs={12} md={7}>
                                <ItemTable
                                    onDblClick={null}
                                    columns={rendererColumns}
                                    data={renderers}
                                    focusedRowEnabled={true}
                                    focusedRowKey={focusedRow}
                                    onFocusedRowChanged={focusedRowChangedHandler}
                                    keyExpr="path"
                                    menu={null} />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <FontsList
                                    name={focusedRow}
                                    fonts={getFonts()}
                                />
                            </Grid>
                        </Grid>
                    </>
                }
            </PageLayout>
        </>
    )
}

export default withCentral(Renderers);
