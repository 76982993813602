import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles({
    paper: {
        height: 330,
        minWidth: 310,
        padding: 15
    },
    centeredWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    wrapper: {
        position: 'relative',
        height: 150,
        width: 150,
        margin: "20px auto"
    },
    mainText: {
        position: 'absolute',
        textAlign: 'center',
        top: 45,
        left: 35,
        width: 80
    },
    resourcesCount: {
        fontSize: "32px",
        fontWeight: 300
    },
    progress1: {
        color: "#1E88E5",
        position: 'absolute',
        zIndex: 2,
    },
    progress2: {
        color: "#000",
        position: 'absolute',
        zIndex: 1,
    },
    row: {
        height: 30
    },
    cell: {
        padding: 4
    }
});

const StatusCard = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const all = props.linked + props.notLinked;
    const val = all === 0 ? 0 : props.linked / all * 100;


    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Typography variant="h1">{t('StatusCard.state')}</Typography>
                <div className={classes.wrapper}>
                    <div color="primary" className={classes.circularText} >
                        <div className={classes.mainText}>
                            <Typography className={classes.resourcesCount} variant="h1">
                                {all}
                            </Typography>
                            <Typography variant="body1">
                                {t('StatusCard.total')}</Typography>
                        </div>
                    </div>
                    <CircularProgress
                        size={150}
                        variant="determinate"
                        className={classes.progress1}
                        value={val} />
                    <CircularProgress
                        size={150}
                        variant="determinate"
                        className={classes.progress2}
                        value={100} />
                </div>
                <Table>
                    <TableBody>
                        <TableRow className={classes.row}>
                            <TableCell className={classes.cell}><FontAwesomeIcon icon={faCircle} color="#1E88E5" /></TableCell>
                            <TableCell className={classes.cell}>
                                <Button onClick={(e) => props.onFilter('type', 'linked')} size="small">
                                    {t('StatusCard.linked')}
                                </Button>
                            </TableCell>
                            <TableCell className={classes.cell}>{props.linked}</TableCell>
                        </TableRow>
                        <TableRow className={classes.row}>
                            <TableCell className={classes.cell}><FontAwesomeIcon icon={faCircle} color="#000" /></TableCell>
                            <TableCell className={classes.cell}>
                                <Button onClick={(e) => props.onFilter('type', 'notlinked')} size="small">
                                    {t('StatusCard.notlinked')}
                                </Button>
                            </TableCell>
                            <TableCell className={classes.cell}>{props.notLinked}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        </div>
    )
}

export default StatusCard;
