import { translateCell } from '../../../const/i18n';

const ledModesColumns = [
    {
        name: translateCell('LedModes.cid'),
        field: "id",
        type: "number"
    }, {
        name: translateCell('LedModes.cname'),
        field: "name",
        type: "string"
    }, {
        name: translateCell('LedModes.csteps'),
        field: "step_count",
        type: "number"
    }, {
        name: translateCell('LedModes.ccreationTime'),
        field: "create_tm",
        type: "datetime"
    }
];

export default ledModesColumns;
