import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';

import axios, { getStoreLink } from '../../axios';
import ItemTable from '../ItemTable/ItemTableBase';
import { CircularProgress } from '@material-ui/core';
import { translateCell } from '../../const/i18n';

const useStyles = makeStyles({
    header: {
        textAlign: "center",
        paddingTop: "50px"
    },
    paper: {
        padding: 20,
        marginTop: 15
    }
});

const headers = [translateCell('Groups.id'), translateCell('Groups.name'),
translateCell('Groups.description'), translateCell('Groups.group')];

const Groups = () => {
    const [groups, setGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios.get(`${getStoreLink()}/usergroup`)
            .then(resp => {
                setGroups(resp.data.data.usergroups);
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false);
            });
    }, []);

    const classes = useStyles();
    const { t } = useTranslation();
    const items = groups.map((group) => (
        [
            group.id, group.name, group.description, group.ldap_group
        ]
    ));

    return (
        <div className={ classes.root }>
            <Paper className={ classes.paper }>
                <Typography variant="h1">
                    { t('Groups.groups') }</Typography>
                { isLoading
                    ?
                    <CircularProgress />
                    :
                    <ItemTable
                        onDoubleClick={ null }
                        headers={ headers }
                        data={ items } />
                }
            </Paper>
        </div>
    );
}

export default Groups;
