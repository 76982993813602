import React from 'react';
import ViewDialog from '../components/Dialogs/ViewDialog';

const ViewServiceContext = React.createContext(Promise.reject);

export const useView = () =>
    React.useContext(ViewServiceContext);

export const ViewServiceProvider = ({ children }) => {
    const [viewState, setViewState] = React.useState(null);

    const awaitingPromiseRef = React.useRef();

    const openView = (options) => {
        setViewState(options);

        return new Promise((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    };

    const handleClose = () => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve();
        }

        setViewState(null);
    };

    return (
        <>
            <ViewServiceContext.Provider
                value={ openView }
                children={ children }
            />

            <ViewDialog
                open={ Boolean(viewState) }                
                onClose={ handleClose }
                { ...viewState }
            />
        </>
    );
};

export const withView = (Component) => {
    const WithView = (props) => {
        return (
            <ViewServiceProvider>
                <Component { ...props } />
            </ViewServiceProvider>
        );
    }

    return WithView;
};
