import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/styles';

import { routePaths } from '../../routePaths';
import { translateCell } from '../../const/i18n';

const useStyles = makeStyles({
    tabs: {
        marginBottom: 50
    }
});

const libraryTabList = [
    {
        label: translateCell('Library.templates'),
        link: routePaths.Templates
    }, {
        label: translateCell('Library.images'),
        link: routePaths.Images
    }, {
        label: translateCell('Library.leds'),
        link: routePaths.LedModes
    }, {
        label: translateCell('Library.fonts'),
        link: routePaths.Fonts
    }, {
        label: translateCell('Library.scripts'),
        link: routePaths.Scripts
    }, {
        label: translateCell('Library.styles'),
        link: routePaths.Styles
    }
];

const withLibrary = (Component) => {
    const WithLibrary = (props) => {

        const changeTabHandler = (event, value) => {
            props.history.push(libraryTabList[value].link);
        }

        const a11yProps = (index) => {
            return {
                id: `tab-${index}`,
                'aria-controls': `tabpanel-${index}`,
            };
        }

        const classes = useStyles();

        const path = props.history.location.pathname.length > 3
            ?
            `/${props.history.location.pathname.split('/')[1]}/${props.history.location.pathname.split('/')[2]}`
            :
            "";

        const index = libraryTabList.findIndex((t) => t.link === path);

        return (
            <div>
                <Tabs
                    className={classes.tabs}
                    value={index !== -1 ? index : 0}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={changeTabHandler}>
                    {libraryTabList.map((tab, index) => (
                        <Tab
                            label={tab.label}
                            key={index}
                            {...a11yProps(index)} />
                    ))
                    }
                </Tabs>
                <Component {...props} />
            </div>
        );
    }

    return WithLibrary;
};

export default withLibrary;
