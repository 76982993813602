import {
    faHome, faRubleSign, faDollarSign, faCubes, faCalendarCheck, faBatteryHalf,
    faLandmark, faUser, faUsers, faThLarge, faImages, faLightbulb
} from '@fortawesome/free-solid-svg-icons';

import { isCentralServer } from '../../const';
import { routePaths } from '../../routePaths';
import { translateCell, translateLang } from '../../const/i18n';


export const rightsMenuItems = [
    {
        name: translateCell('MenuItem.stores'),
        path: routePaths.Stores,
        icon: faLandmark,
        disabled: !isCentralServer
    }, {
        name: translateCell('MenuItem.groups'),
        path: routePaths.Groups,
        icon: faUsers
    }, {
        name: translateCell('MenuItem.users'),
        path: routePaths.Users,
        icon: faUser
    },
];

export const menuItems = [
    {
        name: translateCell('MenuItem.main'),
        path: routePaths.Main,
        icon: faHome
    }, {
        name: translateCell('MenuItem.esls'),
        path: routePaths.Esls,
        icon: translateLang() === 'ru' ? faRubleSign : faDollarSign
    }, {
        name: translateCell('MenuItem.leds'),
        path: routePaths.Leds,
        icon: faLightbulb
    }, {
        name: translateCell('MenuItem.controllers'),
        path: routePaths.Controllers,
        icon: faThLarge
    }, {
        name: translateCell('MenuItem.items'),
        path: routePaths.Items,
        icon: faCubes
    }, {
        name: translateCell('MenuItem.history'),
        path: routePaths.History,
        icon: faCalendarCheck
    }, {
        name: translateCell('MenuItem.diagnostics'),
        path: routePaths.Diagnostics,
        icon: faBatteryHalf
    }, {
        name: translateCell('MenuItem.library'),
        path: routePaths.Library,
        icon: faImages
    }
];
