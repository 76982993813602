import { translateCell } from './../../../const/i18n';

//цвета: red, green, blue, yellow, purple, turquoise, white
//яркость: 0, 10, 20, 40, 50, 60, 80, 100

export const ledColors = [
    {
        value: "none",
        description: translateCell('Colors.none')
    }, {
        value: "red",
        description: translateCell('Colors.red')
    }, {
        value: "green",
        description: translateCell('Colors.green')
    }, {
        value: "blue",
        description: translateCell('Colors.blue')
    }, {
        value: "yellow",
        description: translateCell('Colors.yellow')
    }, {
        value: "purple",
        description: translateCell('Colors.purple')
    }, {
        value: "turquoise",
        description: translateCell('Colors.turquoise')
    }, {
        value: "white",
        description: translateCell('Colors.white')
    }
];

export const ledBrightness = [0, 10, 20, 40, 50, 60, 80, 100];
