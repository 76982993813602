import { translateCell } from '../../const/i18n';

const renderHistoryColumns = [
    {
        name: translateCell('RenderHistory.folder'),
        field: "path",
        type: "string"
    },
    {
        name: translateCell('RenderHistory.generated'),
        field: "count",
        type: "number"
    },
    {
        name: translateCell('RenderHistory.total'),
        field: "full",
        type: "number"
    },
    {
        name: translateCell('RenderHistory.status'),
        display: "image",
        type: "icon",
        field: "status"
    },
];

export default renderHistoryColumns;