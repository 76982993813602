import React from 'react';
import LedDialog from '../components/Dialogs/LedDialog';

const LedSelectionServiceContext = React.createContext(Promise.reject);

export const useLedSelection = () =>
    React.useContext(LedSelectionServiceContext);

export const LedSelectionServiceProvider = ({ children }) => {
    const [ledSelectionState, setLedSelectionState] = React.useState(null);

    const awaitingPromiseRef = React.useRef();

    const openLedSelection = (options) => {
        setLedSelectionState(options);
        
        return new Promise((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    };

    const handleClose = () => {
        if (ledSelectionState.catchOnCancel && awaitingPromiseRef.current) {
            awaitingPromiseRef.current.reject();
        }

        setLedSelectionState(null);
    };

    const handleSubmit = (state) => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve(state);
        }

        setLedSelectionState(null);
    };

    return (
        <>
            <LedSelectionServiceContext.Provider
                value={ openLedSelection }
                children={ children }
            />

            <LedDialog
                open={ Boolean(ledSelectionState) }
                onSubmit={ handleSubmit }
                onClose={ handleClose }
                { ...ledSelectionState }
            />
        </>
    );
};

export const withLedSelection = (Component) => {
    const WithLedSelection = (props) => {
        return (
            <LedSelectionServiceProvider>
                <Component { ...props } />
            </LedSelectionServiceProvider>
        );
    }

    return WithLedSelection;
};
