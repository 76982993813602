import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import axios, { getStoreLink } from '../../../axios';
import { routePaths } from '../../../routePaths';
import { Typography } from '@material-ui/core';
import colors from '../../../const/Colors';

const useStyles = makeStyles({
    paper: {
        margin: "30px",
        padding: "30px"
    },
    centered: {
        textAlign: "center",
        marginTop: 30
    },
    button: {
        marginLeft: 15,
        marginRight: 15,
    },
    default: {
        marginLeft: 0,
        marginTop: 40,
        marginBottom: 50
    },
    container: {

    },
    addButton: {
        marginTop: 15,
        marginBottom: 50,
        borderRadius: 5,
        paddingTop: 8,
        paddingBottom: 8,
        color: "#E53935",
    },
    hint: {
        lineHeight: 1.5,
        fontSize: "10px",
        whiteSpace: 'pre-line'
    }
});

const TemplateView = (props) => {
    const [template, setTemplate] = useState({
        id: null,
        name: "",
        color: "bwr",
        diagonal: "",
        height: "",
        width: "",
        content: "",
        rotation: 0,
        default: 0
    });
    const [withoutRedraw, setWithoutRedraw] = useState(false);

    useEffect(() => {
        if (props.match.path === routePaths.TemplatesEdit) {
            axios.get(`${getStoreLink()}/template/` + props.match.params.id)
                .then((response) => {
                    setTemplate(response.data.data);
                });
        }
    }, []);

    const submitHandler = (e) => {
        e.preventDefault();
        const postfix = withoutRedraw ? '?type=only_save' : '';
        if (props.match.path === routePaths.TemplatesNew) {
            axios.post(`${getStoreLink()}/template` + postfix, template)
                .then(() => props.history.push(routePaths.Templates));
        } else {
            axios.put(`${getStoreLink()}/template/` + props.match.params.id + postfix, template)
                .then(() => props.history.push(routePaths.Templates));
        }
    }

    const changeHandler = (prop) => (e) => {
        setTemplate({
            ...template,
            [prop]: e.target.value
        });
    }

    const checkboxHandler = (e) => {
        setWithoutRedraw(e.target.checked);
    }

    const switchHandler = (e) => {
        setTemplate({
            ...template,
            default: e.target.checked === true ? 1 : 0
        });
    }

    const changeNumericHandler = (prop) => (e) => {
        setTemplate({
            ...template,
            [prop]: +e.target.value
        });
    }

    /*
    const addHandler = () => {
        const nMap = [...template.map];
        nMap.push({ html_number: nMap.length + 1, position_name: "" });
        setTemplate({
            ...template,
            map: nMap
        });
    }

    const removeItemHandler = (index) => () => {
        const nMap = [...template.map];
        nMap.splice(index, 1);
        for (var i = index; i < nMap.length; i++)
            nMap[i].html_number -= 1;

        setTemplate({
            ...template,
            map: nMap
        });
    }

    const changeItemHandler = (index, e) => {
        const nMap = [...template.map];
        nMap[index].position_name = e.target.value;
        setTemplate({
            ...template,
            map: nMap
        });
    }
*/
    const classes = useStyles();
    const { t } = useTranslation();

    const isNew = props.match.path !== routePaths.TemplatesEdit;
    const CancelLink = (props) => <Link to={ routePaths.Templates } { ...props } />;

    return (
        <Paper className={ classes.paper }>
            { isNew
                ?
                <Typography variant="h1">{ t('TemplateView.adding') }</Typography>
                :
                <Typography variant="h1">{ t('TemplateView.editing') }</Typography>
            }
            <form onSubmit={ submitHandler } noValidate>
                <TextField
                    required
                    id="name"
                    name="name"
                    label={ t('TemplateView.name') }
                    value={ template.name }
                    onChange={ changeHandler('name') }
                    margin="normal"
                    fullWidth />
                <TextField
                    select
                    id="color"
                    name="color"
                    label={ t('TemplateView.color') }
                    value={ template.color }
                    onChange={ changeHandler('color') }
                    margin="normal"
                    fullWidth>
                    { colors.map(color => (
                        <MenuItem key={ color.value } value={ color.value }>
                            { `${color.value} (${color.description})` }
                        </MenuItem>
                    )) }
                </TextField>
                <Grid container className={ classes.container } spacing={ 2 }>
                    <Grid item xs={ template.default === 1 ? 4 : 6 }>
                        <TextField
                            required
                            id="height"
                            name="height"
                            label={ t('TemplateView.height') }
                            value={ template.height }
                            onChange={ changeNumericHandler('height') }
                            margin="normal"
                            fullWidth />
                    </Grid>
                    <Grid item xs={ template.default === 1 ? 4 : 6 }>
                        <TextField
                            required
                            id="width"
                            name="width"
                            label={ t('TemplateView.width') }
                            value={ template.width }
                            onChange={ changeNumericHandler('width') }
                            margin="normal"
                            fullWidth />
                    </Grid>
                    { template.default === 1 &&
                        <Grid item xs={ 4 }>
                            <TextField
                                select
                                id="rotation"
                                name="rotation"
                                label={ t('TemplateView.orientation') }
                                value={ template.rotation }
                                onChange={ changeNumericHandler('rotation') }
                                margin="normal"
                                fullWidth>
                                { [0, 90, 180, 270].map(r => (
                                    <MenuItem key={ r } value={ r }>
                                        { `${r} °` }
                                    </MenuItem>
                                )) }
                            </TextField>
                        </Grid>
                    }
                </Grid>
                <FormControlLabel
                    className={ classes.default }
                    control={
                        <Switch color="primary" checked={ template.default === 1 } onChange={ switchHandler } />
                    }
                    label={ t('TemplateView.default') }
                    labelPlacement="start" />
                <br />
                <Typography className={ classes.hint } variant="overline">
                    { t('TemplateView.hints') +
                        '\n\r- ' + t('TemplateView.overflow') + ' - overflow: hidden' +
                        '\n\r- ' + t('TemplateView.parameters') + ' - $(name)' +
                        '\n\r- ' + t('TemplateView.parameters2') + ' - $(name_1),$(name_1)...' +
                        '\n\r- ' + t('TemplateView.values') + ' - name/currentPrice/barcode128/' + t('TemplateView.attributeNames')
                    }
                </Typography>
                <TextField
                    required
                    multiline
                    rows="30"
                    id="content"
                    name="content"
                    label="HTML"
                    value={ template.content }
                    onChange={ changeHandler('content') }
                    margin="normal"
                    fullWidth />
                <FormControlLabel
                    control={ <Checkbox
                        color="primary"
                        checked={ withoutRedraw }
                        onChange={ checkboxHandler }
                        name="without" /> }
                    label={ t('TemplateView.redraw') }
                />
                <div className={ classes.centered }>
                    <Button
                        className={ classes.button }
                        color="primary"
                        type="submit"
                        variant="contained">
                        { t('TemplateView.save') }</Button>
                    <Button
                        className={ classes.button }
                        color="primary"
                        component={ CancelLink }
                        variant="contained">
                        { t('TemplateView.cancel') }</Button>
                </div>
            </form>
        </Paper >
    )
}

export default withRouter(TemplateView);
