import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
    header: {
        paddingTop: 3,
        paddingLeft: 20,
        height: 30,
        width: "auto",
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        borderLeft: "1px solid white"
    },
    paper: {
        margin: 20,
        padding: 10,
        backgroundColor: "#F2F2F2"
    },
    subheader: {
        textTransform: "uppercase",
        color: theme.palette.primary.main,
        marginBottom: 20
    },
    container: {
        maxHeight: 400
    },
    head: {
        fontSize: 12,
        padding: 5
    },
    body: {
        fontSize: 14,
        padding: 5
    }
}));

const FontsList = (props) => {

    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <>
            <div className={classes.header}>
                <Typography color="inherit">{props.name}</Typography>
            </div>
            <Paper className={classes.paper}>
                <Typography className={classes.subheader} color="inherit">{t('Renderers.fonts')}</Typography>
                <TableContainer className={classes.container}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.head}>{t('Renderers.family')}</TableCell>
                                <TableCell className={classes.head}>{t('Renderers.style')}</TableCell>
                                <TableCell className={classes.head}>{t('Renderers.path')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.fonts.map((font) => (
                                <TableRow>
                                    <TableCell className={classes.body}>{font.fonts}</TableCell>
                                    <TableCell className={classes.body}>{font.style}</TableCell>
                                    <TableCell className={classes.body}>{font.path}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}

export default FontsList;
