import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import DataGrid, {
    Column,
    FilterRow,
    Paging,
    Pager,
    Selection,
    StateStoring,
    Lookup
} from 'devextreme-react/data-grid';

import MenuCell from '../shared/Cells/MenuCell';
import ShortcutCell from '../shared/Cells/ShortcutCell';
import ItemTableToolbar from './ItemTableToolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles({
    grid: {
        "& .dx-header-row": {
            color: "#fff",
            backgroundColor: "#E53935",
            fontFamily: "Roboto",
            fontWeight: 500,
            fontSize: "12px"
        },
        "& .dx-datagrid-headers .dx-datagrid-table .dx-header-row > td": {
            borderRight: "3px double",
        },
        "& .dx-datagrid-headers .dx-datagrid-table .dx-header-row > td:last-child": {
            borderRight: 0,
        },
        "& .dx-freespace-row td": {
            borderLeft: 0,
            borderRight: 0
        },
        "& .dx-data-row td": {
            borderBottom: "1px solid #E0E0E0",
            borderLeft: 0,
            borderRight: 0,
            whiteSpace: "pre-line"
        }
    },
    first: {
        "& .dx-datagrid-headers .dx-datagrid-table .dx-header-row > td:first-child": {
            borderRight: 0,
        }
    },
    second: {
        "& .dx-datagrid-headers .dx-datagrid-table .dx-header-row > td:nth-child(2)": {
            borderRight: 0,
        }
    },
    third: {
        "& .dx-datagrid-headers .dx-datagrid-table .dx-header-row > td:nth-child(3)": {
            borderRight: 0,
        }
    },
    center: {
        textAlign: "center"
    }
});

const ItemTable = (props) => {
    const [selected, setSelected] = useState([]);

    const gridRef = useRef();

    const selectionChangedHandler = (e) => {
        const { selectedRowKeys } = e;
        setSelected(selectedRowKeys);
    }

    const clearSelection = () => {
        gridRef.current.instance.clearSelection();
    }

    const dblClickHandler = (e) => {
        const { key } = e;
        if (props.onDblClick) props.onDblClick(key);
    }

    const clickHandler = (e) => {
        const { key, column } = e;
        if (props.onClick) props.onClick(key, column.dataField);
    }

    const focusedRowChangedHandler = (e) => {
        if (props.onFocusedRowChanged)
            props.onFocusedRowChanged(e.component.option('focusedRowKey'));
    }

    let massMenu = [];
    let shortcutMenu = [];
    if (props.menu)
        props.menu.forEach((item) => {
            if (item.isMass)
                massMenu.push(item);
            if (item.isShortcut)
                shortcutMenu.push(item);
        });

    const classes = useStyles();
    const menuCell = data => <MenuCell menu={props.menu} cellData={data} />;
    const shortcutCell = data => <ShortcutCell menu={shortcutMenu} cellData={data} />;
    const iconCell = data => {
        if (data.displayValue) {
            return <Tooltip title={data.displayValue.hint}>
                <div className={classes.center}>
                    <FontAwesomeIcon
                        icon={data.displayValue.icon} color={data.displayValue.color} size={data.displayValue.size ? data.displayValue.size : "1x"} />
                </div>
            </Tooltip>;
        }
        else
            return <div></div>;
    }

    let cnt = 0;
    if (props.isSelectable) cnt++;
    if (props.menu) cnt++;
    if (props.menu && props.menu.findIndex((el) => el.isShortcut === true) !== -1) cnt++;

    return (
        <>
            <ItemTableToolbar
                onClear={clearSelection}
                selected={selected}
                menu={massMenu} />
            <DataGrid
                className={classNames(classes.grid, {
                    [classes.first]: cnt >= 1,
                    [classes.second]: cnt >= 2,
                    [classes.third]: cnt >= 3,
                })}
                ref={gridRef}
                dataSource={props.data}
                keyExpr={props.keyExpr}
                allowColumnResizing={true}
                columnResizingMode='widget'
                columnMinWidth={37}
                columnAutoWidth={true}
                hoverStateEnabled={true}
                onRowDblClick={dblClickHandler}
                onCellClick={clickHandler}
                onSelectionChanged={selectionChangedHandler}
                focusedRowEnabled={props.focusedRowEnabled ? props.focusedRowEnabled : false}
                focusedRowKey={props.focusedRowKey ? props.focusedRowKey : null}
                onFocusedRowChanged={focusedRowChangedHandler}
                autoNavigateToFocusedRow={true}>
                <FilterRow visible={true} />
                {props.isSelectable &&
                    <Selection
                        mode="multiple"
                        selectAllMode="allPages"
                        showCheckBoxesMode="always"
                    />
                }
                {props.menu &&
                    <Column
                        width={37}
                        allowResizing={false}
                        focusStateEnabled={false}
                        cellRender={menuCell} />
                }
                {shortcutMenu.length > 0 &&
                    <Column
                        allowResizing={false}
                        focusStateEnabled={false}
                        cellRender={shortcutCell} />
                }
                {props.columns.map((column) => {
                    if (column.type === "icon")
                        return <Column
                            //allowFiltering={false}
                            key={column.field}
                            caption={column.name}
                            dataField={column.field}
                            cellRender={iconCell}
                            calculateSortValue={column.field}
                            calculateDisplayValue={column.display}>
                            {column.filterValues &&
                                <Lookup
                                    dataSource={column.filterValues}
                                    valueExpr="value"
                                    displayExpr="name"
                                />
                            }
                        </Column>;
                    else
                        return <Column
                            key={column.field}
                            caption={column.name}
                            dataField={column.field}
                            dataType={column.type} />;
                })}
                <Paging defaultPageSize={20} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true} />
                {props.storageKey &&
                    <StateStoring enabled={true} type="sessionStorage" storageKey={props.storageKey} />
                }
            </DataGrid>
        </>
    );
}

export default ItemTable;
