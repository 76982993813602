import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes, faEdit } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';

import { routePaths } from '../../routePaths';
import AddAttributeDialog from '../../components/Dialogs/AddAttributeDialog';
import axios, { getStoreLink } from '../../axios';

const styles = () => ({
    paper: {
        margin: 30,
        padding: 30
    },
    subheader: {
        marginTop: 10
    },
    centered: {
        textAlign: "center",
        marginTop: 30
    },
    button: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: 10
    }
});

class ItemView extends Component {
    state = {
        item: {
            code: "",
            barcode: "",
            name: "",
            category: "",
            price: "",
            attributes: []
        },
        isDialogOpen: false,
        isEdit: false,
        name: "",
        value: "",
        editedIndex: -1
    };

    componentDidMount() {
        if (this.props.match.path === routePaths.ItemsEdit) {
            axios.get(`${getStoreLink()}/item/` + this.props.match.params.id)
                .then((response) => {
                    this.setState({ item: response.data.data });
                });
        }
    }

    submitHandler = (e) => {
        e.preventDefault();
        if (this.props.match.path === routePaths.ItemsNew) {
            axios.post(`${getStoreLink()}/item`, this.state.item)
                .then(() => this.props.history.push(routePaths.Items));
        } else {
            axios.put(`${getStoreLink()}/item/` + this.props.match.params.id, this.state.item)
                .then(() => this.props.history.push(routePaths.Items));
        }
    }

    changeHandler = (e) => {
        this.setState({
            item: {
                ...this.state.item,
                [e.target.name]: e.target.value
            }
        });
    }

    addHandler = () => {
        this.setState({ editedIndex: -1, name: "", value: "", isEdit: false, isDialogOpen: true });
    }

    editHandler = (name) => {
        const attributes = this.state.item.attributes;
        const attrIndex = attributes.findIndex((attr) => attr.name === name);
        if (attrIndex !== -1) {
            this.setState({
                isEdit: true,
                editedIndex: attrIndex,
                name: attributes[attrIndex].name,
                value: attributes[attrIndex].value,
                isDialogOpen: true
            });
        }
    }

    removeHandler = (name) => {
        const attributes = [...this.state.item.attributes];
        const attrIndex = attributes.findIndex((attr) => attr.name === name);
        if (attrIndex !== -1) {
            attributes.splice(attrIndex, 1);
            this.setState({
                item: {
                    ...this.state.item,
                    attributes
                }
            });
        }
    }

    closeDialogHandler = () => {
        this.setState({ isDialogOpen: false });
    }

    dlgChangeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    saveAttributeHandler = () => {
        if (!this.state.name || !this.state.value)
            return;

        const attributes = [...this.state.item.attributes];
        if (this.state.editedIndex === -1)
            attributes.push({ name: this.state.name, value: this.state.value });
        else
            attributes[this.state.editedIndex].value = this.state.value;

        this.setState({
            item: {
                ...this.state.item,
                attributes
            },
            isDialogOpen: false
        });
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;

        const isNew = this.props.match.path !== routePaths.ItemsEdit;
        const { item } = this.state;
        const CancelLink = (props) => <Link to={ routePaths.Items } { ...props } />;

        return (
            <div className={ classes.root }>
                <AddAttributeDialog
                    isEdit={ this.state.isEdit }
                    open={ this.state.isDialogOpen }
                    onCloseDialog={ this.closeDialogHandler }
                    name={ this.state.name }
                    value={ this.state.value }
                    onChange={ this.dlgChangeHandler }
                    onSave={ this.saveAttributeHandler } />
                <Paper className={ classes.paper }>
                    { isNew
                        ?
                        <Typography variant="h1">{ t('ItemView.add') }</Typography>
                        :
                        <Typography variant="h1">{ t('ItemView.edit') }</Typography>
                    }
                    <form onSubmit={ this.submitHandler } noValidate>
                        <TextField
                            disabled={ !isNew }
                            required
                            id="code"
                            name="code"
                            label={ t('ItemView.vendorcode') }
                            value={ item.code }
                            onChange={ this.changeHandler }
                            margin="normal"
                            fullWidth />
                        <TextField
                            id="barcode"
                            name="barcode"
                            label={ t('ItemView.barcode') }
                            value={ item.barcode }
                            onChange={ this.changeHandler }
                            margin="normal"
                            fullWidth />
                        <TextField
                            required
                            id="name"
                            name="name"
                            label={ t('ItemView.name') }
                            value={ item.name }
                            onChange={ this.changeHandler }
                            margin="normal"
                            fullWidth />
                        <TextField
                            required
                            id="price"
                            name="price"
                            label={ t('ItemView.price') }
                            value={ item.price }
                            onChange={ this.changeHandler }
                            margin="normal"
                            fullWidth />
                        <TextField
                            id="category"
                            name="category"
                            label={ t('ItemView.category') }
                            value={ item.category }
                            onChange={ this.changeHandler }
                            margin="normal"
                            fullWidth />
                        <Typography className={ classes.subheader } variant="h2">{ t('ItemView.attributes') }</Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{ t('ItemView.attrname') }</TableCell>
                                    <TableCell>{ t('ItemView.attrvalue') }</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { item.attributes.map((attr) => (
                                    <TableRow key={ attr.name }>
                                        <TableCell>{ attr.name }</TableCell>
                                        <TableCell>{ attr.value }</TableCell>
                                        <TableCell>
                                            <Button onClick={ (e) => this.editHandler(attr.name) }>
                                                <FontAwesomeIcon icon={ faEdit } />
                                            </Button>
                                            <Button onClick={ (e) => this.removeHandler(attr.name) }>
                                                <FontAwesomeIcon icon={ faTimes } />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )) }
                            </TableBody>
                        </Table>
                        <Button
                            onClick={ this.addHandler }
                            fullWidth
                            className={ classes.button }
                            color="primary"
                            variant="outlined">
                            <FontAwesomeIcon icon={ faPlus } />&nbsp;{ t('ItemView.addattr') }</Button>
                        <div className={ classes.centered }>
                            <Button
                                className={ classes.button }
                                color="primary"
                                type="submit"
                                variant="contained">
                                { t('ItemView.save') }</Button>
                            <Button
                                className={ classes.button }
                                color="primary"
                                component={ CancelLink }
                                variant="contained">
                                { t('ItemView.cancel') }</Button>
                        </div>
                    </form>
                </Paper >
            </div>
        )
    }
}

export default withTranslation()(withRouter(withStyles(styles)(ItemView)));
