import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    grid: {
        padding: 10
    }
});

const LedDialog = ({
    open,
    modes,
    onSubmit,
    onClose
}) => {
    const [modeId, setModeId] = useState(null);
    const [startDate, setStartDate] = useState(moment());
    const [stopDate, setStopDate] = useState(moment());
    const [now, setNow] = useState(false);
    const [withoutStop, setWithoutStop] = useState(false);

    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        if (modes && modes.length > 0) {
            setModeId(modes[0].id);
            setStartDate(moment());
            setStopDate(moment());
            setNow(false);
            setWithoutStop(false);
        }
    }, [modes]);

    const checkboxHandler = (name) => (e) => {
        if (name === 'now')
            setNow(e.target.checked);
        else if (name === 'withoutStop')
            setWithoutStop(e.target.checked);
    }

    const changeModeHandler = (e) => {
        setModeId(e.target.value);
    }

    const dateChangeHandler = (name) => (date) => {
        if (name === 'start')
            setStartDate(date);
        else if (name === 'stop')
            setStopDate(date);
    }

    const setHandler = () => {
        onSubmit({
            modeId,
            startDate: now ? 0 : startDate.utc().valueOf() / 1000,
            stopDate: withoutStop ? 0 : stopDate.utc().valueOf() / 1000,
        });
    }

    const closeHandler = () => {
        onClose();
    }

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{t('LedDialog.schedule')}</DialogTitle>
            <DialogContent>
                <Typography variant='body1'>{t('LedDialog.select')}</Typography>
                <TextField
                    select
                    id="led_id"
                    value={modeId}
                    onChange={changeModeHandler}
                    margin="normal"
                    fullWidth>
                    {modes && modes.map((mode) => (
                        <MenuItem key={mode.id} value={mode.id}>
                            {mode.name}
                        </MenuItem>
                    ))}
                </TextField>
                <Grid container>
                    <Grid item xs={3} className={classes.grid}>{t('LedDialog.start')}</Grid>
                    <Grid item xs={5} className={classes.grid}>
                        <KeyboardDateTimePicker
                            disabled={now}
                            variant="dialog"
                            ampm={false}
                            disableToolbar={false}
                            value={startDate.local()}
                            onChange={dateChangeHandler('start')}
                            format="YYYY-MM-DD HH:mm" />
                    </Grid>
                    <Grid item xs={4} className={classes.grid}>
                        <FormControlLabel
                            control={<Checkbox
                                color="primary"
                                checked={now}
                                onChange={checkboxHandler('now')}
                                name="now" />}
                            label={t('LedDialog.now')} />
                    </Grid>

                    <Grid item xs={3} className={classes.grid}>{t('LedDialog.stop')}</Grid>
                    <Grid item xs={5} className={classes.grid}>
                        <KeyboardDateTimePicker
                            disabled={withoutStop}
                            variant="dialog"
                            ampm={false}
                            disableToolbar={false}
                            value={stopDate.local()}
                            onChange={dateChangeHandler('stop')}
                            format="YYYY-MM-DD HH:mm" />
                    </Grid>
                    <Grid item xs={4} className={classes.grid}>
                        <FormControlLabel
                            control={<Checkbox
                                color="primary"
                                checked={withoutStop}
                                onChange={checkboxHandler('withoutStop')}
                                name="withoutStop" />}
                            label={t('LedDialog.withoutStop')}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={setHandler} variant="contained" color="primary">
                    {t('LedDialog.set')}</Button>
                <Button onClick={closeHandler} variant="contained" color="primary">
                    {t('LedDialog.cancel')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default LedDialog;
