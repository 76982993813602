import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    paper: {
        height: 330,
        minWidth: 310,
        padding: 15
    },
    progressRoot: {
        height: 20,
        marginTop: 60,
        marginBottom: 70
    },
    progressBarColorPrimary: {
        background: "#80E27E"
    },
    progressColorPrimary: {
        background: "#FFEB3B"
    },
    progressDashedColorPrimary: {
        background: "#718792"
    },
    progressDashed: {
        animation: "none"
    },
    resourcesCount: {
        textAlign: "right",
        fontSize: "32px",
        fontWeight: 300
    },
    row: {
        height: 30
    },
    cell: {
        padding: 4
    }
});

const ConnectionCard = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const total = props.ok + props.low + props.offline;
    const value = total === 0 ? 0 : props.ok / total * 100;
    const valueBuffer = total === 0 ? 0 : ((props.ok + props.low) / total) * 100;


    return (
        <div className={ classes.root }>
            <Paper className={ classes.paper }>
                <Grid container>
                    <Grid item xs={ 8 }>
                        <Typography variant="h1">{ t('ConnectionCard.connection') }</Typography>
                    </Grid>
                    <Grid item xs={ 4 }>
                        <Typography className={ classes.resourcesCount } variant="body1">{ props.total }</Typography>
                    </Grid>
                </Grid>
                <LinearProgress
                    classes={ {
                        root: classes.progressRoot,
                        colorPrimary: classes.progressColorPrimary,
                        barColorPrimary: classes.progressBarColorPrimary,
                        dashedColorPrimary: classes.progressDashedColorPrimary,
                        dashed: classes.progressDashed
                    } }
                    variant="buffer"
                    color="primary"
                    value={ value }
                    valueBuffer={ valueBuffer } />
                <Table>
                    <TableBody>
                        <TableRow className={ classes.row }>
                            <TableCell className={ classes.cell }><FontAwesomeIcon icon={ faCircle } color="#80E27E" /></TableCell>
                            <TableCell className={ classes.cell }>
                                <Button onClick={ (e) => props.onFilter('connection', 'ok') } size="small">
                                    { t('ConnectionCard.online') }
                                </Button>
                            </TableCell>
                            <TableCell className={ classes.cell }>{ props.ok }</TableCell>
                        </TableRow>
                        {/*<TableRow className={ classes.row }>
                            <TableCell className={ classes.cell }><FontAwesomeIcon icon={ faCircle } color="#FFEB3B" /></TableCell>
                            <TableCell className={ classes.cell }>
                                <Button onClick={ (e) => props.onFilter('connection', 'low') } size="small">
                                    { t('ConnectionCard.lowbattery') } ({ "<" } 30%)
                                </Button>
                            </TableCell>
                            <TableCell className={ classes.cell }>{ props.low }</TableCell>
                        </TableRow>*/}
                        <TableRow className={ classes.row }>
                            <TableCell className={ classes.cell }><FontAwesomeIcon icon={ faCircle } color="#718792" /></TableCell>
                            <TableCell className={ classes.cell }>
                                <Button onClick={ (e) => props.onFilter('connection', 'offline') } size="small">
                                    { t('ConnectionCard.offline') }
                                </Button>
                            </TableCell>
                            <TableCell className={ classes.cell }>{ props.offline }</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        </div>
    )
}

export default ConnectionCard;
