import { translateCell } from '../../const/i18n';

const libraryColumns = [
    {
        name: translateCell('Library.cname'),
        field: "filename",
        type: "string"
    }, {
        name: translateCell('Library.ctime'),
        field: "time",
        type: "datetime"
    }
];

export default libraryColumns;
