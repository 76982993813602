import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { faTrashAlt, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

import { useConfirmation, withConfirmation } from '../../services/ConfirmationService';
import { translateCell } from '../../const/i18n';
import controllerColumns from './controllerColumns';
import ItemTable from '../ItemTable/ItemTable';
import PageLayout from '../Layout/PageLayout/PageLayout';
import { routePaths } from '../../routePaths';
import axios, { getStoreLink } from '../../axios';
import fileSave from '../../shared/fileSave';

const Controllers = (props) => {
    const [controllers, setControllers] = useState([]);
    const [refreshDate, setRefreshDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(true);

    const confirm = useConfirmation();

    useEffect(() => {
        axios.get(`${getStoreLink()}/controller`)
            .then((response) => {
                setIsLoading(false);
                const data = [...response.data.data];
                data.forEach((v) => (v.view_tm = v.view_tm * 1000));
                setControllers(data);
            })
            .catch(() => {
                setIsLoading(false);
            })
    }, [refreshDate]);

    const { t } = useTranslation();

    const refreshHandler = () => {
        setIsLoading(true);
        setRefreshDate(new Date());
    }

    const removeHandler = (ids) => {
        confirm({
            variant: "danger",
            catchOnCancel: false,
            title: t('Controllers.removetitle'),
            description: t('Controllers.removetext')
        })
            .then(() => {
                let identifiers = ids;
                if (!Array.isArray(ids))
                    identifiers = [ids];

                axios.delete(`${getStoreLink()}/controller`, {
                    data: { controller: identifiers },
                    responseType: 'blob'
                })
                    .then((response) => {
                        const tmpControllers = [...controllers];
                        identifiers.forEach(id => {
                            const index = tmpControllers.findIndex((c) => c.id === id);
                            if (index !== -1) {
                                tmpControllers.splice(index, 1);
                            }
                        });
                        setControllers(tmpControllers);
                        fileSave("removed_controllers.csv", response.data);
                    })
            })
    }

    const eslsHandler = (id) => {
        const index = controllers.findIndex((c) => c.id === id);
        if (index !== -1)
            props.history.push({
                pathname: routePaths.Esls,
                state: {
                    mac: controllers[index].mac
                }
            });
    }

    const menu = [
        {
            text: translateCell('Controllers.esls'),
            icon: faLongArrowAltRight,
            onAction: eslsHandler,
            isMass: false
        },
        {
            text: translateCell('Controllers.remove'),
            icon: faTrashAlt,
            onAction: removeHandler,
            isMass: true
        }
    ];

    return (
        <PageLayout
            date={ refreshDate }
            onRefresh={ refreshHandler }
            name={ t('Controllers.controllers') }>
            { isLoading &&
                <CircularProgress />
            }
            <ItemTable
                columns={ controllerColumns }
                data={ controllers }
                keyExpr="id"
                menu={ menu }
                onDblClick={ eslsHandler }
                isSelectable={ true }
                storageKey="controllerStateStorage"
            />
        </PageLayout>
    );
}

export default withConfirmation(Controllers);
