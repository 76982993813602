import { translateCell } from '../../const/i18n';

const itemColumns = [
    {
        name: translateCell('Items.vendorcode'),
        field: "code",
        type: "string"
    }, {
        name: translateCell('Items.item'),
        field: "name",
        type: "string"
    }, {
        name: translateCell('Items.esl'),
        field: "serial",
        type: "string"
    }, {
        name: translateCell('Items.price'),
        field: "price",
        type: "string"
    }, {
        name: translateCell('Items.barcode'),
        field: "barcode",
        type: "string"
    }, {
        name: translateCell('Items.category'),
        field: "category",
        type: "string"
    }, {
        name: translateCell('Items.changed'),
        field: "change_tm",
        type: "datetime"
    }
];

export default itemColumns;
