import { translateCell } from '../../const/i18n';

const eslFilterValues = [
    { value: -2, name: translateCell('QueueCard.statusStarError') },
    { value: -1, name: translateCell('QueueCard.statusRendererError') },
    { value: 0, name: translateCell('QueueCard.statusRendererWaiting') },
    { value: 3, name: translateCell('QueueCard.statusStarWaiting') },
    { value: 5, name: translateCell('QueueCard.statusOk') },
]

const eslColumns = [
    {
        name: translateCell('Esls.code'),
        field: "serial",
        type: "string"
    }, {
        name: translateCell('Esls.state'),
        display: "state_image",
        type: "icon",
        field: "picture_state",
        filterValues: eslFilterValues
    }, {
        name: translateCell('Esls.item'),
        field: "item_name",
        type: "string"
    }, {
        name: translateCell('Esls.vendorcode'),
        field: "code",
        type: "string"
    }, {
        name: translateCell('Esls.template'),
        field: "template_name",
        type: "string"
    }, {
        name: translateCell('Esls.color'),
        field: "color",
        type: "string"
    }, {
        name: translateCell('Esls.height'),
        field: "height",
        type: "number"
    }, {
        name: translateCell('Esls.width'),
        field: "width",
        type: "number"
    }, {
        name: translateCell('Esls.diagonal'),
        field: "diagonal",
        type: "string"
    }, {
        name: translateCell('Esls.label_type'),
        field: "label_type",
        type: "string"
    }, {
        name: translateCell('Esls.orientation'),
        field: "rotation",
        type: "number"
    }, {
        name: translateCell('Esls.batteryperc'),
        field: "last_bat",
        type: "number"
    }, {
        name: translateCell('Esls.batterymv'),
        field: "last_mvolt",
        type: "number"
    }, {
        name: translateCell('Esls.status'),
        field: "view_tm",
        type: "datetime"
    }, {
        name: translateCell('Esls.draw'),
        field: "update_tm",
        type: "datetime"
    }, {
        name: translateCell('Esls.labelChange'),
        field: "label_change_tm",
        type: "datetime"
    }, {
        name: translateCell('Esls.itemUpdate'),
        field: "item_change_tm",
        type: "datetime"
    }, {
        name: translateCell('Esls.version'),
        field: "last_soft_ver",
        type: "string"
    }, {
        name: translateCell('Esls.pictureCrc'),
        field: "picture_crc",
        type: "string"
    }
];

export default eslColumns;
