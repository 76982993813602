import { translateCell } from '../../const/i18n';

const importHistoryColumns = [
    {
        name: translateCell('ImportHistory.start'),
        field: "start",
        type: "datetime"
    },
    {
        name: translateCell('ImportHistory.stop'),
        field: "stop",
        type: "datetime"
    },
    {
        name: translateCell('ImportHistory.file'),
        field: "filename",
        type: "string"
    },
    {
        name: translateCell('ImportHistory.qnty'),
        field: "item_full_num",
        type: "number"
    },
    {
        name: translateCell('ImportHistory.changed'),
        field: "item_new_num",
        type: "number"
    },
    {
        name: translateCell('ImportHistory.esls'),
        field: "item_label_redraw_count",
        type: "number"
    },
    {
        name: translateCell('ImportHistory.integration'),
        field: "integration",
        type: "string"
    }
];

export default importHistoryColumns;
