export const routePaths = {
    SignIn: "/",
    Central: "/central",
    Stores: "/central/stores",
    StoresNew: "/central/stores/new",
    StoresEdit: "/central/stores/edit/:id",
    Renderers: "/central/renderers",
    Groups: "/groups",
    Users: "/users",
    Main: "/main",
    Esls: "/esls",
    EslsNew: "/esls/new",
    EslsEdit: "/esls/edit/:id",
    Leds: "/leds",
    Controllers: "/controllers",
    Items: "/items",
    ItemsNew: "/items/new",
    ItemsEdit: "/items/edit/:id",    
    Library: "/library",
    Templates: "/library/templates",
    TemplatesNew: "/library/templates/new",
    TemplatesEdit: "/library/templates/edit/:id",
    LedModes: "/library/ledmodes",
    LedModesNew: "/library/ledmodes/new",
    LedModesEdit: "/library/ledmodes/edit/:id",
    Scripts: "/library/scripts",
    Styles: "/library/styles",
    Fonts: "/library/fonts",
    Images: "/library/images",
    History: "/history",
    Diagnostics: "/diagnostics",
    HtmlView: "/html"
  };
