import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { faTrashAlt, faEye } from '@fortawesome/free-solid-svg-icons';

import axios, { getStoreLink } from '../../axios';
import { useConfirmation, withConfirmation } from '../../services/ConfirmationService';
import PageLayout from '../Layout/PageLayout/PageLayout';
import ItemTable from './../ItemTable/ItemTable';
import withLibrary from './withLibrary';
import libraryColumns from './libraryColumns';

const Scripts = (props) => {
    const [refreshDate, setRefreshDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(true);
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [scripts, setScripts] = useState([]);

    const loadRef = useRef();

    useEffect(() => {
        loadScripts();

    }, [refreshDate]);

    const loadScripts = () => {
        axios.get(`${getStoreLink()}/resource/import_js`)
            .then((response) => {
                const tmp = [...response.data.data];
                tmp.forEach((d) => d.time = +d.time * 1000);
                setScripts(tmp);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }

    const refreshHandler = () => {
        setIsLoading(true);
        setRefreshDate(new Date());
    }

    const viewHandler = (id) => {
        axios.get(`${getStoreLink()}/resource/import_js?file=${id}`)
            .then((response) => {
                confirm({
                    variant: "data",
                    catchOnCancel: false,
                    title: t('Library.scriptviewtitle'),
                    description: response.data.data.content
                })
                    .then(() => { });
            })
    }

    const removeHandler = (id) => {
        confirm({
            variant: "danger",
            catchOnCancel: false,
            title: t('Library.scriptremovetitle'),
            description: t('Library.scriptremovetext')
        })
            .then(() => {
                axios.delete(`${getStoreLink()}/resource/import_js?file=${id}`)
                    .then(() => {
                        const nScripts = [...scripts];
                        const index = nScripts.findIndex((s) => s.filename === id);
                        if (index !== -1) {
                            nScripts.splice(index, 1);
                            setScripts(nScripts);
                        }
                    });
            });
    }

    const loadFileClickHandler = () => {
        loadRef.current.click();
    }

    const loadFileHandler = (e) => {
        if (e.target.files) {
            setIsFileLoading(true);
            const file = e.target.files[0];
            const body = new FormData();
            body.append('file', file);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            axios.post(`${getStoreLink()}/resource/import_js`, body, config)
                .then(() => {
                    setIsFileLoading(false);
                    refreshHandler();
                    // for the second load
                    loadRef.current.value = "";
                    alert(t('Library.script_imported'));
                })
                .catch(() => {
                    setIsFileLoading(false);
                });
        }
    }

    const { t } = useTranslation();
    const confirm = useConfirmation();

    const menu = [
        {
            text: t('Library.view'),
            icon: faEye,
            onAction: viewHandler,
            isShortcut: true
        },
        {
            text: t('Library.remove'),
            icon: faTrashAlt,
            onAction: removeHandler,
            isShortcut: true
        }
    ];

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={ loadFileClickHandler }>
                { t('Library.loadScript') }</Button>
            <input
                ref={ loadRef }
                accept=".js"
                id="input-button-file"
                type="file"
                style={ { display: "none" } }
                onChange={ loadFileHandler } />
            {isFileLoading &&
                <div>
                    <CircularProgress />
                </div>
            }
            <PageLayout
                date={ refreshDate }
                onRefresh={ refreshHandler }
                name={ t('Library.scripts') }>
                { isLoading
                    ?
                    <CircularProgress />
                    :
                    <ItemTable
                        onDoubleClick={ null }
                        columns={ libraryColumns }
                        data={ scripts }
                        keyExpr="filename"
                        menu={ menu } />
                }
            </PageLayout>
        </>
    )
}

export default withLibrary(
    withConfirmation(Scripts)
);
