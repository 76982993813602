import { translateCell } from './i18n';

const colors = [
    {
        value: "bw",
        description: translateCell('Colors.bw')
    }, {
        value: "bwr",
        description: translateCell('Colors.bwr')
    }, {
        value: "color",
        description: translateCell('Colors.color')
    }
];

export default colors;
