import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const ConfirmationDialog = ({
    open,
    title,
    variant,
    description,
    onSubmit,
    onClose
}) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={ open }>
            <DialogTitle id="alert-dialog-title">{ title }</DialogTitle>
            <DialogContent dividers={ variant === 'data' }>
                <DialogContentText style={ { whiteSpace: 'pre-line' } }>{ description }</DialogContentText>
            </DialogContent>
            <DialogActions>
                { variant === "danger" && (
                    <>
                        <Button color="primary" onClick={ onSubmit }>
                            { t('ConfirmationDialog.yes') }</Button>
                        <Button color="primary" onClick={ onClose } autoFocus>
                            { t('ConfirmationDialog.no') }</Button>
                    </>
                ) }

                { (variant === "info" || variant === "data") && (
                    <Button color="primary" onClick={ onSubmit }>
                        Ok
                    </Button>
                ) }
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
