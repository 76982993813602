import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/styles';

import { routePaths } from '../../routePaths';
import { translateCell } from '../../const/i18n';

const useStyles = makeStyles({
    tabs: {
        marginBottom: 25
    }
});

const centralTabList = [
    {
        label: translateCell('Central.stores'),
        link: routePaths.Stores
    },
    {
        label: translateCell('Central.renderers'),
        link: routePaths.Renderers
    }
];

const withCentral = (Component) => {
    const WithCentral = (props) => {

        const changeTabHandler = (event, value) => {
            props.history.push(centralTabList[value].link);
        }

        const a11yProps = (index) => {
            return {
                id: `tab-${index}`,
                'aria-controls': `tabpanel-${index}`,
            };
        }

        const classes = useStyles();

        const path = props.history.location.pathname.length > 3
            ?
            `/${props.history.location.pathname.split('/')[1]}/${props.history.location.pathname.split('/')[2]}`
            :
            "";

        const index = centralTabList.findIndex((t) => t.link === path);

        return (
            <div>
                <Tabs
                    className={ classes.tabs }
                    value={ index !== -1 ? index : 0 }
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={ changeTabHandler }>
                    { centralTabList.map((tab, index) => (
                        <Tab
                            label={ tab.label }
                            key={ index }
                            { ...a11yProps(index) } />
                    ))
                    }
                </Tabs>
                <Component { ...props } />
            </div>
        );
    }

    return WithCentral;
};

export default withCentral;
