import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';

import axios, { getStoreLink } from '../../axios';

const useStyles = makeStyles({
    card: {
        width: 345,
    },
    wrapper: {
        height: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: 5,
        marginRight: 5
    },
    media: {
        height: "auto",
        width: "auto",
        margin: "auto",
        maxHeight: "100%",
        maxWidth: "100%",
        border: "1px solid grey"
    },
    progress: {
        margin: "auto",
        maxHeight: "100%",
        maxWidth: "100%",
    }
});

const ViewDialog = ({
    open,
    id,
    title,
    variant,
    type,
    onClose
}) => {
    const [esl, setEsl] = useState(null);

    useEffect(() => {
        // TODO: remove requests 
        if (variant === 'esl') {
            if (id)
                axios.get(`${getStoreLink()}/esl/` + id, { params: { type: "preview" } })
                    .then((response) => {
                        setEsl(response.data.data);
                    });
        }
        else {
            if (id)
                axios.get(`${getStoreLink()}/resource/import_images?file=` + id)
                    .then((response) => {
                        setEsl(response.data.data);
                    });
        }

    }, [id]);

    const classes = useStyles();
    const { t } = useTranslation();

    const closeHandler = () => {
        setEsl(null);
        onClose();
    }

    return (
        <Dialog
            open={ open }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{ title }</DialogTitle>
            <DialogContent className={ classes.card }>
                <div className={ classes.wrapper }>
                    <div className={ classes.trick }></div>
                    { !esl
                        ?
                        <CircularProgress className={ classes.progress } />
                        :
                        <img
                            src={ `data:image/${variant === 'esl' ? 'png' : type};base64,${variant === 'esl' ? esl.generatedata : esl.content}` }
                            className={ classes.media }
                            alt="esl"
                        />
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={ closeHandler } variant="text" color="primary">
                    { t('ViewDialog.close') }</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ViewDialog;
