import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from 'react-i18next';
import { useConfirmation, withConfirmation } from '../../../services/ConfirmationService';
import withLibrary from '../withLibrary';
import PageLayout from '../../Layout/PageLayout/PageLayout';
import ItemTable from '../../ItemTable/ItemTable';

import axios, { getStoreLink } from '../../../axios';
import { routePaths } from '../../../routePaths';
import ledModesColumns from './ledModesColumns';

const LedModes = (props) => {
    const [refreshDate, setRefreshDate] = useState(new Date())
    const [isLoading, setIsLoading] = useState(true);
    const [modes, setModes] = useState([])

    const { t } = useTranslation();
    const confirm = useConfirmation();

    useEffect(() => {
        axios.get(`${getStoreLink()}/led_mode`)
            .then((response) => {
                const data = [...response.data.data];
                data.forEach((m) => m.create_tm = m.create_tm * 1000);
                setModes(response.data.data);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, [refreshDate]);



    const refreshHandler = () => {
        setIsLoading(true);
        setRefreshDate(new Date());
    }

    const addClickHandler = () => {
        props.history.push(routePaths.LedModesNew);
    }

    const editHandler = (id) => {
        const path = routePaths.LedModesEdit.replace(":id", id.toString());
        props.history.push(path);
    }

    const removeHandler = (id) => {
        confirm({
            variant: "danger",
            catchOnCancel: false,
            title: t('LedModes.removetitle'),
            description: t('LedModes.removetext')
        })
            .then(() => {

                axios.delete(`${getStoreLink()}/led_mode/` + id)
                    .then(() => {
                        const nModes = [...modes];
                        const index = nModes.findIndex((mode) => mode.id === id);
                        if (index !== -1) {
                            nModes.splice(index, 1);
                            setModes(nModes);
                        }
                    });
            })
    }

    const menu = [
        {
            text: t('LedModes.edit'),
            icon: faEdit,
            onAction: editHandler
        },
        {
            text: t('LedModes.remove'),
            icon: faTrashAlt,
            onAction: removeHandler
        }
    ];

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={addClickHandler}>
                {t('LedModes.add')}
            </Button>
            <PageLayout
                date={refreshDate}
                onRefresh={refreshHandler}
                name={t('LedModes.modes')}>
                {isLoading
                    ?
                    <CircularProgress />
                    :
                    <ItemTable
                        onDblClick={editHandler}
                        columns={ledModesColumns}
                        data={modes}
                        keyExpr="id"
                        menu={menu} />
                } </PageLayout>
        </>
    )
}

export default withLibrary(
    withConfirmation(LedModes)
);
