import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';

import Colors from '../../../const/Colors';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
    card: {
        width: 345,
    },
    wrapper: {
        height: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: 5,
        marginRight: 5
    },
    media: {
        height: "auto",
        width: "auto",
        margin: "auto",
        maxHeight: "100%",
        maxWidth: "100%",
        border: "1px solid grey"
    },
    progress: {
        margin: "auto",
        maxHeight: "100%",
        maxWidth: "100%",
    },
    divider: {
        marginBottom: 15
    },
    actions: {
        display: "flex",
        float: "right"
    }
});

const TemplateCard = (props) => {
    const { classes } = props;
    const { t } = useTranslation();
    const colorIndex = Colors.findIndex((c) => c.value === props.color);
    const color = colorIndex !== -1 ? `${props.color} (${Colors[colorIndex].description})` : t('TemplateCard.notset');

    return (
        <Card className={ classes.card }>
            <CardHeader
                title={ props.name }
            />
            <div className={ classes.wrapper }>
                <div className={ classes.trick }></div>
                { props.img === ""
                    ?
                    <CircularProgress className={ classes.progress } />
                    :
                    <CardMedia
                        component="img"
                        src={ `data:image/png;base64,${props.img}` }
                        className={ classes.media }
                        title={ props.name }
                    />
                }
            </div>
            <CardContent>
                <Divider className={ classes.divider } />
                <Typography variant="body1">{ `${t('TemplateCard.hw')}: ${props.height}*${props.width}` }</Typography>
                <Typography variant="body1">{ `${t('TemplateCard.color')}: ${color}` }</Typography>
                <Typography variant="body1">{ `${t('TemplateCard.orientation')}: ${props.height > props.width ? t('TemplateCard.vertical') : t('TemplateCard.horizontal')}` }</Typography>
                {/*<Typography variant="body1">{ `${t('TemplateCard.items')}: ${props.count}` }</Typography>*/}
                { props.default !== 0 && <Typography variant="body1">{ t('TemplateCard.default') + ", " + props.rotation + "°" }</Typography> }                
            </CardContent>
            <CardActions className={ classes.actions }>
                <IconButton onClick={ () => props.onChange(props.id) }>
                    <FontAwesomeIcon icon={ faEdit } />
                </IconButton>
                <IconButton onClick={ () => props.onRemove(props.id) }>
                    <FontAwesomeIcon icon={ faTimes } />
                </IconButton>
            </CardActions>
        </Card>
    )
}

export default withStyles(styles)(TemplateCard);
