import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "@material-ui/core/Slider";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faTimes, faPlus, faEdit } from "@fortawesome/free-solid-svg-icons";

import axios, { getStoreLink } from '../../../axios';
import { ledColors, ledBrightness } from "./ledDictionary";
import { routePaths } from "../../../routePaths";

const useStyles = makeStyles({
    paper: {
        margin: 30,
        padding: 30
    },
    centered: {
        textAlign: "center",
        marginTop: 30
    },
    button: {
        marginLeft: 15,
        marginRight: 15,
    },
    addButton: {
        marginTop: 15,
        marginBottom: 50,
        borderRadius: 5,
        paddingTop: 8,
        paddingBottom: 8,

        color: "#E53935",
    },
    hint: {
        marginTop: 50,
        background: "#F8CCCA33",
        display: 'flex',
        alignItems: 'center',
        padding: 25
    },
    hintText: {
        fontSize: 18,
        whiteSpace: 'pre-line',
        marginLeft: 24
    },
    table: {
        tableLayout: "fixed"
    }
})

const LedModeView = (props) => {
    const [ledMode, setLedMode] = useState({
        id: null,
        name: "",
        steps: []
    });

    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        if (props.match.path === routePaths.LedModesEdit) {
            axios.get(`${getStoreLink()}/led_mode/` + props.match.params.id)
                .then((response) => {
                    const data = { ...response.data.data };
                    data.steps.forEach((s) => {
                        if (s.brightness === 0)
                            s.color = 'none';
                    })

                    setLedMode(response.data.data);
                });
        }
    }, []);

    const submitHandler = (e) => {
        e.preventDefault();
        const mode = { ...ledMode };

        if (mode.name === '') {
            alert('Name is empty');
            return;
        }
        if (mode.steps.length === 0) {
            alert('There are no steps');
            return;
        }

        mode.steps.forEach(s => {
            s.color = s.color === 'none' ? 'white' : s.color;
        });

        if (props.match.path === routePaths.LedModesNew) {
            axios.post(`${getStoreLink()}/led_mode`, mode)
                .then(() => props.history.push(routePaths.LedModes));
        } else {
            axios.put(`${getStoreLink()}/led_mode/` + props.match.params.id, mode)
                .then(() => props.history.push(routePaths.LedModes));
        }
    }

    const changeHandler = (prop) => (e) => {
        setLedMode({
            ...ledMode,
            [prop]: e.target.value
        });
    }

    const changeStepColorHandler = (index) => (e) => {
        const tmp = [...ledMode.steps];
        tmp[index].color = e.target.value;
        if (e.target.value === 'none')
            tmp[index].brightness = 0;
        setLedMode({ ...ledMode, steps: tmp });
    }

    const changeStepBrightnessHandler = (index) => (e, newValue) => {
        const tmp = [...ledMode.steps];
        tmp[index].brightness = ledBrightness[newValue];
        setLedMode({ ...ledMode, steps: tmp });
    }

    const changeStepDurationHandler = (index) => (e) => {
        const tmp = [...ledMode.steps];
        if (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) {
            tmp[index].duration = e.target.value;
            setLedMode({ ...ledMode, steps: tmp });
        }
    }

    const blurStepDurationHandler = (index) => () => {
        const tmp = [...ledMode.steps];
        tmp[index].duration = +tmp[index].duration;
        if (tmp[index].duration > 6000)
            tmp[index].duration = 6000;
        else if (tmp[index].duration <= 0)
            tmp[index].duration = 25;
        if (tmp[index].duration % 25 !== 0)
            tmp[index].duration = Math.ceil(tmp[index].duration / 25) * 25;
        setLedMode({ ...ledMode, steps: tmp });
    }

    const addStepHandler = () => {
        const tmp = [...ledMode.steps];
        // default values to add
        tmp.push({
            color: ledColors[0].value,
            brightness: ledBrightness[0],
            duration: 6000
        });
        setLedMode({ ...ledMode, steps: tmp });
    }

    const removeStepHandler = (index) => (e) => {
        const tmp = [...ledMode.steps];

        tmp.splice(index, 1);
        setLedMode({ ...ledMode, steps: tmp });
    }


    const isNew = props.match.path !== routePaths.LedModesEdit;
    const CancelLink = (props) => <Link to={routePaths.LedModes} {...props} />;

    const marks = [
        {
            value: 0,
            label: '0%'
        }, {
            value: ledBrightness.length - 1,
            label: '100%'
        }];

    return (
        <Paper className={classes.paper}>
            {isNew
                ?
                <Typography variant="h1">{t('LedModeView.adding')}</Typography>
                :
                <Typography variant="h1">{t('LedModeView.editing')}</Typography>
            }
            <form onSubmit={submitHandler} noValidate>
                <TextField
                    required
                    id="name"
                    name="name"
                    label={t('LedModeView.name')}
                    value={ledMode.name}
                    onChange={changeHandler('name')}
                    margin="normal"
                    fullWidth />
                <div className={classes.hint}>
                    <FontAwesomeIcon icon={faExclamationTriangle} color="#AB000D" size="2x" />
                    <Typography
                        variant="body1"
                        className={classes.hintText}>
                        {t('LedModeView.hint')}</Typography>
                </div>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('LedModeView.csteps')}</TableCell>
                            <TableCell>{t('LedModeView.ccolor')}</TableCell>
                            <TableCell>{t('LedModeView.cbrightness')}</TableCell>
                            <TableCell>{t('LedModeView.cduration')}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ledMode.steps.map((step, index) => (
                            <TableRow key={index}>
                                <TableCell>{index}</TableCell>
                                <TableCell>
                                    <TextField
                                        select
                                        value={step.color}
                                        fullWidth
                                        onChange={changeStepColorHandler(index)}>
                                        {ledColors.map((color) => (
                                            <MenuItem key={color.value} value={color.value}>
                                                {color.description}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </TableCell>
                                <TableCell>
                                    <Slider
                                        disabled={step.color === 'none'}
                                        value={ledBrightness.indexOf(step.brightness)}
                                        marks={marks}
                                        onChange={changeStepBrightnessHandler(index)}
                                        step={1}
                                        min={0}
                                        max={ledBrightness.length - 1} />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        required
                                        name="duration"
                                        value={step.duration}
                                        onChange={changeStepDurationHandler(index)}
                                        onBlur={blurStepDurationHandler(index)}
                                        margin="normal" />
                                </TableCell>
                                <TableCell>
                                    <Button onClick={removeStepHandler(index)}>
                                        <FontAwesomeIcon icon={faTimes} size="2x" color="#718792" />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Button
                    onClick={addStepHandler}
                    fullWidth
                    className={classes.button}
                    color="primary"
                    variant="outlined">
                    <FontAwesomeIcon icon={faPlus} />&nbsp;{t('LedModeView.addstep')}</Button>
                <div className={classes.centered}>
                    <Button
                        className={classes.button}
                        color="primary"
                        type="submit"
                        variant="contained">
                        {t('LedModeView.save')}</Button>
                    <Button
                        className={classes.button}
                        color="primary"
                        component={CancelLink}
                        variant="contained">
                        {t('LedModeView.cancel')}</Button>
                </div>
            </form>
        </Paper>
    )
}

export default LedModeView;
