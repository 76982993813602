import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classNames from "classnames";

const useStyles = makeStyles({
    paper: {
        padding: 15
    },
    cell: {
        padding: 5
    },
    statusOk: {
        color: "green"
    },
    statusError: {
        color: "red"
    }
});

const RenderersCard = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Typography variant="h1">{t('Renderers.renderers')}</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cell}>{t('Renderers.cpath')}</TableCell>
                            <TableCell className={classes.cell}>{t('Renderers.cworkers')}</TableCell>
                            <TableCell className={classes.cell}>{t('Renderers.ctasks')}</TableCell>
                            <TableCell className={classes.cell}>{t('Renderers.cantialiasing')}</TableCell>
                            <TableCell className={classes.cell}>{t('Renderers.cuptime')}</TableCell>
                            <TableCell className={classes.cell}>{t('Renderers.cversion')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.renderers.map((renderer) => {
                            const workers = `${renderer.statistics.busyWorkers} / ${renderer.statistics.totalWorkers}`;
                            const tm = moment.duration(renderer.statistics.uptime, 'seconds');
                            const uptime = `${tm.days()} ${tm.hours()}:${tm.minutes()}:${tm.seconds()}`;
                            const antialiasing = renderer.antialiasing ? t('Renderers.yes') : t('Renderers.no');

                            return (
                                <TableRow key={renderer.path}>
                                    <TableCell className={classNames(classes.cell, classes.statusOk, {
                                        [classes.statusError]: !renderer.statistics.status
                                    })}>{renderer.path}</TableCell>
                                    <TableCell className={classes.cell}>{workers}</TableCell>
                                    <TableCell className={classes.cell}>{renderer.statistics.pendingTasks}</TableCell>
                                    <TableCell className={classes.cell}>{antialiasing}</TableCell>
                                    <TableCell className={classes.cell}>{uptime}</TableCell>
                                    <TableCell className={classes.cell}>{renderer.version}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Paper>
        </div >
    )
}

export default RenderersCard;
