import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    faEdit, faTrashAlt, faSyncAlt, faBarcode, faUnlink,
    faEye, faLink, faSpinner, faTimes, faRedo, faPaintBrush, faTint, faTintSlash
} from '@fortawesome/free-solid-svg-icons';

import { routePaths } from '../../routePaths';
import axios, { getStoreLink } from '../../axios';
import eslColumns from './eslColumns';
import EslButtons from './EslButtons';
import EslFilter from './EslFilter';
import PageLayout from '../../components/Layout/PageLayout/PageLayout';
import ItemTable from '../../components/ItemTable/ItemTable';
import { useConfirmation, withConfirmation } from '../../services/ConfirmationService';
import { useTemplateSelection, withTemplateSelection } from '../../services/TemplateSelectionService';
import { useLinkSearch, withLinkSearch } from '../../services/LinkService';
import { useRotate, withRotate } from '../../services/RotateService';
import { useView, withView } from '../../services/ViewService';
import fileSave from '../../shared/fileSave';


const EslsNew = (props) => {
    const [esls, setEsls] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [refreshDate, setRefreshDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(true);
    const [filter, setFilter] = useState({
        status: null,
        queue: null,
        connection: null,
        import: false
    });

    const firstLoad = useRef(true);

    const { t } = useTranslation();
    const confirm = useConfirmation();
    const templateSelection = useTemplateSelection();
    const viewEsl = useView();
    const rotateEsl = useRotate();
    const linkSearch = useLinkSearch();

    useEffect(() => {
        // previously by params
        //const search = props.location.search;
        //const params = new URLSearchParams(search);
        const state = props.location.state;
        const macFilter = state && state.mac ? state.mac : null;
        let tmpFilter = {
            status: state && state.status ? state.status : null,
            queue: state && state.queue ? state.queue : null,
            connection: state && state.connection ? state.connection : null,
            import: state && state.import ? state.import : false,
        };
        // clear state to load on refresh
        props.history.replace(routePaths.Esls, null);
        if (!macFilter && !tmpFilter.status && !tmpFilter.queue && !tmpFilter.connection && tmpFilter.import === false) {
            // if it is not filter click            
            const storageFilter = sessionStorage.getItem("eslFilterStorage");
            if (storageFilter) {
                tmpFilter = JSON.parse(storageFilter);
            }
        } else
            sessionStorage.setItem("eslFilterStorage", JSON.stringify(tmpFilter));

        setFilter(tmpFilter);
        loadEsls(tmpFilter, macFilter);
    }, []);

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            return;
        }

        loadEsls(filter);

    }, [refreshDate]);

    const loadEsls = (filtered, macFilter = null) => {
        let params = {
            params: {
                type: "item"
            }
        };
        Object.keys(filtered).forEach((key) => {
            if (filtered[key])
                params.params[key] = key === "import" ? "last" : filtered[key];
        });
        if (macFilter) params.params['mac'] = macFilter;

        axios.get(`${getStoreLink()}/esl`, params)
            .then((response) => {
                const data = [...response.data.data];
                data.forEach((d) => {
                    d.view_tm = d.view_tm * 1000;
                    d.update_tm = d.update_tm * 1000;
                    if (d.label_change_tm)
                        d.label_change_tm = d.label_change_tm === 0 ? null : d.label_change_tm * 1000;
                    if (d.item_change_tm)
                        d.item_change_tm = d.item_change_tm === 0 ? null : d.item_change_tm * 1000;

                    if (d.label_type === "autonomous")
                        d.label_type = t('Esls.autonomous_type');
                    else if (d.label_type === "bus")
                        d.label_type = t('Esls.bus_type');
                    else if (d.label_type === "color")
                        d.label_type = t('Esls.color_type');
                    else if (d.label_type === "android")
                        d.label_type = t('Esls.android_type');

                    if (![-2, -1, 0, 1, 2, 3, 4, 5].includes(d.picture_state))
                        d.picture_state = 5;

                    // 0, 1, 2, 3, 4 are internal states. So, we leave only -2, -1, 0, 3, 5
                    if ([0, 1, 2].includes(d.picture_state))
                        d.picture_state = 0;
                    else if ([3, 4].includes(d.picture_state))
                        d.picture_state = 3;

                    d.state_image = getStateIcon(d.picture_state, d.picture_error);
                });
                setEsls(data);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
        axios.get(`${getStoreLink()}/template`, {
            params: {
                type: "list"
            }
        })
            .then((response) => {
                setTemplates(response.data.data);
            });
    }

    const getStateIcon = (state, error) => {
        let state_image = null;
        if (state === -2)
            state_image = { icon: faTimes, color: "#E53935", size: "2x", hint: t('QueueCard.statusStarError') };
        else if (state === -1)
            state_image = { icon: faTintSlash, color: "#FB8C00", size: "2x", hint: t('QueueCard.statusRendererError') };
        else if ([0, 1, 2].includes(state))
            state_image = { icon: faTint, color: error ? "#E53935" : "#718792", size: "2x", hint: t('QueueCard.statusRendererWaiting') };
        else if ([3, 4].includes(state))
            state_image = { icon: faSpinner, color: "#FFEB3B", size: "2x", hint: t('QueueCard.statusStarWaiting') };

        return state_image;
    }

    const refreshHandler = () => {
        setIsLoading(true);
        setRefreshDate(new Date());
    }

    const clickHandler = (key, dataField) => {
        if (dataField === "state_image") {
            const index = esls.findIndex((esl) => esl.serial === key);
            if (index !== -1 && esls[index].picture_error) {
                let result = `${t('Esls.rendererErrors')}:\n\n`;
                if (esls[index].picture_error.startsWith('[{')) {
                    try {
                        const errors = JSON.parse(esls[index].picture_error);
                        errors.forEach((error) => {
                            result += `${error.url} (${error.status} - ${error.text})\n`;
                        });

                    }
                    catch (error) { }
                } else
                    result += esls[index].picture_error;
                alert(result);
            }
        }
    }

    const changeFilterListHandler = (filtered, value) => {
        const tmpFilter = { ...filter, [filtered]: value !== '' ? value : null };
        setFilter(tmpFilter);
        sessionStorage.setItem("eslFilterStorage", JSON.stringify(tmpFilter));
        refreshHandler();
    }

    const viewHandler = (id) => {
        viewEsl({
            id: id,
            title: t('ViewDialog.view'),
            variant: 'esl'
        });
    }

    const linkHandler = (ids) => {
        linkSearch({})
            .then((code) => {
                const identifiers = Array.isArray(ids) ? ids : [ids];
                axios.post(`${getStoreLink()}/link`, identifiers.map((id) => ({
                    esl: id.toString(),
                    item: code
                })))
                    .then(() => {
                        refreshHandler();
                    });
            });
    }

    const unlinkHandler = (ids) => {
        confirm({
            variant: "danger",
            catchOnCancel: false,
            title: t('Esls.unlinktitle'),
            description: t('Esls.unlinktext')
        })
            .then(() => {
                const identifiers = Array.isArray(ids) ? ids : [ids];
                axios.delete(`${getStoreLink()}/link`, {
                    data: { esl: identifiers },
                    responseType: 'blob'
                })
                    .then(() => {
                        const tmpEsls = [...esls];
                        identifiers.forEach(e => {
                            const index = tmpEsls.findIndex((esl) => esl.serial === e);
                            if (index !== -1) {
                                tmpEsls[index].item_name = "";
                                tmpEsls[index].code = "";
                            }
                        });
                        setEsls(tmpEsls);
                    });
            });
    }

    const rotateHandler = (id) => {
        // get angle from id
        const index = esls.findIndex((esl) => esl.serial === id);
        if (index === -1) return;

        let defaultRotate = 0;
        if (esls[index].diagonal && [1.5, 4.2].includes(parseFloat(esls[index].diagonal)))
            defaultRotate += 90;
        rotateEsl({
            title: t('Esls.rotateTitle'),
            previousAngle: esls[index].rotation,
            defaultRotate
        })
            .then((angle) => {
                // send save request
                axios.put(`${getStoreLink()}/esl/` + id, { serial: id, rotation: angle })
                    .then(() => {
                        const tmp = [...esls];
                        tmp[index].rotation = angle;
                        setEsls(tmp);
                    });
            });
    }

    const templateHandler = (ids) => {
        templateSelection({
            catchOnCancel: false,
            title: t('Esls.title_template'),
            templates: templates
        })
            .then((templateId) => {
                const identifiers = Array.isArray(ids) ? ids : [ids];
                axios.put(`${getStoreLink()}/esl`, {
                    template: +templateId,
                    serials: identifiers
                })
                    .then(() => {
                        refreshHandler();
                    });
            });
    }

    const redrawHandler = (ids) => {
        const identifiers = Array.isArray(ids) ? ids : [ids];

        axios.post(`${getStoreLink()}/addqueue`, { type: 4, label: identifiers })
            .then(() => {
                const tmpEsls = [...esls];
                identifiers.forEach(e => {
                    const index = tmpEsls.findIndex((esl) => esl.serial === e);
                    if (index !== -1) {
                        tmpEsls[index].picture_state = 3;
                        tmpEsls[index].state_image = getStateIcon(3);
                    }
                });
                setEsls(tmpEsls);
            });
    }

    const editHandler = (id) => {
        const path = routePaths.EslsEdit.replace(":id", id.toString());
        props.history.push(path);
    }

    const removeHandler = (ids) => {
        confirm({
            variant: "danger",
            catchOnCancel: false,
            title: t('Esls.removetitle'),
            description: t('Esls.removetext')
        })
            .then(() => {
                const identifiers = Array.isArray(ids) ? ids : [ids];

                axios.delete(`${getStoreLink()}/esl`, {
                    data: { esl: identifiers },
                    responseType: 'blob'
                })
                    .then((response) => {
                        const tmpEsls = [...esls];
                        identifiers.forEach(e => {
                            const index = tmpEsls.findIndex((c) => c.serial === e);
                            if (index !== -1) {
                                tmpEsls.splice(index, 1);
                            }
                        });
                        setEsls(tmpEsls);
                        fileSave("removed_esls.csv", response.data);
                    });
            });
    }

    const menu = [
        {
            text: t('Esls.view'),
            icon: faEye,
            onAction: viewHandler,
            isShortcut: true
        }, {
            text: t('Esls.link'),
            icon: faLink,
            onAction: linkHandler,
            isMass: true,
            isShortcut: true
        }, {
            text: t('Esls.unlink'),
            icon: faUnlink,
            onAction: unlinkHandler,
            isMass: true
        }, {
            text: t('Esls.rotate'),
            icon: faRedo,
            onAction: rotateHandler
        }, {
            text: t('Esls.settemplate'),
            icon: faBarcode,
            onAction: templateHandler,
            isMass: true
        }, {
            text: t('Esls.redraw'),
            icon: faSyncAlt,
            onAction: redrawHandler,
            isMass: true
        }, {
            text: t('Esls.edit'),
            icon: faEdit,
            onAction: editHandler
        }, {
            text: t('Esls.remove'),
            icon: faTrashAlt,
            onAction: removeHandler,
            isMass: true
        },
    ];

    return (
        <>
            <EslButtons
                onRefresh={refreshHandler} />
            <PageLayout
                date={refreshDate}
                onRefresh={refreshHandler}
                name={t('Esls.Esls')}>
                {isLoading &&
                    <CircularProgress />
                }
                <EslFilter
                    filter={filter}
                    onChange={changeFilterListHandler}
                />
                <ItemTable
                    columns={eslColumns}
                    data={esls}
                    keyExpr="serial"
                    menu={menu}
                    onDblClick={editHandler}
                    onClick={clickHandler}
                    isSelectable={true}
                    storageKey="elsStateStorage"
                />
            </PageLayout>
        </>
    );
}

export default withTemplateSelection(
    withConfirmation(
        withView(
            withRotate(
                withLinkSearch(EslsNew)
            )
        )
    )
);
