import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import axios, { getStoreLink } from '../../axios';
import StatusCard from '../Cards/StatusCard';
import ConnectionCard from '../Cards/ConnectionCard';
import QueueCard from '../Cards/QueueCard';
import IntegrationCard from '../Cards/IntegrationCard';
import RenderersCard from '../Cards/RenderersCard';
import { routePaths } from "../../routePaths";

const useStyles = makeStyles({
    root: {
        marginBottom: 70
    },
});

const Main = (props) => {
    const [renderers, setRenderers] = useState([]);
    const [refreshDate, setRefreshDate] = useState(new Date());
    const [statistics, setStatistics] = useState(null);

    useEffect(() => {
        setStatistics(null);
        axios.get(`${getStoreLink()}/statistics`)
            .then((response) => {
                // test mock
                //response.data.data.queue = { ok: 1, renderer_waiting: 2, star_waiting: 3, renderer_error: 4, star_error: 5 };
                //response.data.data.import = { label_redraw_count: 48, full_num: 77, new_num: 76, time_start: 1578994738, time_end: 1645204176, queue: { ok: 0, renderer_waiting: 0, star_waiting: 0, renderer_error: 0, star_error: 0 } };
                setStatistics(response.data.data);
            })
            .catch(() => {
            });
        axios.get(`${getStoreLink()}/renderers`)
            .then((response) => {
                setRenderers(response.data.data);
            })
            .catch(() => {
            });
    }, [refreshDate]);

    const filterHandler = (reqType, filter) => {
        let req = {};
        if (reqType === 'queue')
            req.queue = filter;
        else if (reqType === 'connection')
            req.connection = filter;
        else if (reqType === 'type')
            req.status = filter;
        else if (reqType === 'import') {
            req.queue = filter;
            req.import = true;
        }

        // redirect to links with filters
        props.history.push({
            pathname: routePaths.Esls,
            state: req
        })
    }

    const refreshHandler = () => {
        setRefreshDate(new Date());
    }

    const classes = useStyles();
    const { i18n } = useTranslation();

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={6}>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                    <Typography variant="body1">{refreshDate.toLocaleString(i18n.language === 'ru' ? 'ru-RU' : 'en-US')}
                        <IconButton onClick={refreshHandler}>
                            <FontAwesomeIcon icon={faSyncAlt} />
                        </IconButton>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={4} md={6}>
                    <StatusCard
                        onFilter={filterHandler}
                        linked={statistics ? statistics.status.linked : 0}
                        notLinked={statistics ? statistics.status.notLinked : 0} />
                </Grid>
                <Grid item xs={12} lg={4} md={6}>
                    <ConnectionCard
                        onFilter={filterHandler}
                        ok={statistics ? statistics.connection.ok : 0}
                        //low={ statistics ? statistics.connection.low : 0 }
                        offline={statistics ? statistics.connection.offline : 0}
                        total={statistics ? statistics.connection.total : 0} />
                </Grid>
                <Grid item xs={12} lg={4} md={6}>
                    <QueueCard
                        onFilter={filterHandler}
                        time={"14.01.1999 19-00"}
                        left={2.5}
                        ok={statistics ? statistics.queue.ok : 0}
                        renderer_waiting={statistics ? statistics.queue.renderer_waiting : 0}
                        star_waiting={statistics ? statistics.queue.star_waiting : 0}
                        renderer_error={statistics ? statistics.queue.renderer_error : 0}
                        star_error={statistics ? statistics.queue.star_error : 0} />
                </Grid>
                <Grid item xs={12} lg={8}>
                    <RenderersCard
                        renderers={renderers} />
                </Grid>
                {statistics && statistics.import &&
                    <Grid item xs={12} lg={4} md={6}>
                        <IntegrationCard
                            onFilter={filterHandler}
                            startTime={statistics.import.time_start}
                            endTime={statistics.import.time_end}
                            totalCnt={statistics.import.full_num}
                            modifiedCnt={statistics.import.new_num}
                            redrawCnt={statistics.import.label_redraw_count}
                            ok={statistics.import.queue.ok}
                            renderer_waiting={statistics.import.queue.renderer_waiting}
                            star_waiting={statistics.import.queue.star_waiting}
                            renderer_error={statistics.import.queue.renderer_error}
                            star_error={statistics.import.queue.star_error} />
                    </Grid>
                }
            </Grid>
        </div>
    );
}

export default Main;
