import axios from 'axios';
import { isCentralServer } from './const';

export const isHandlerEnabled = (config={}) => {
    return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled ?
        false : true
}

const instance = axios.create({
    baseURL: isCentralServer 
    ? '/api'
    : '/api3',
    headers: {
        'Content-Type': 'application/json',
    },
});

instance.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem('qpstkn');
    config.headers.Qpstoretoken = token ? `${token}` : '';

    return config;
});

instance.interceptors.response.use(
    (response) => {
        if (isHandlerEnabled(response.config) && response.status === 200 && response.data.result === 100) {
            sessionStorage.removeItem("qpstkn");
            sessionStorage.removeItem("user_id");
            sessionStorage.removeItem("name");
            sessionStorage.removeItem("version");
            sessionStorage.removeItem("store");
            sessionStorage.removeItem("namestore");

            window.location.replace(`/?expired=1`);
        }

        return response;
    },
    (error) => {
        return Promise.reject(error);
    });

    export const getStoreLink = () => {
        return sessionStorage.getItem('store') ? `/store/${sessionStorage.getItem('store')}` : '';
    }    

export default instance;
