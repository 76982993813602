import React from 'react';
import InputDialog from '../components/Dialogs/InputDialog';

const InputServiceContext = React.createContext(Promise.reject);

export const useInput = () =>
    React.useContext(InputServiceContext);

export const InputServiceProvider = ({ children }) => {
    const [inputState, setInputState] = React.useState(null);

    const awaitingPromiseRef = React.useRef();

    const openInput = (options) => {
        setInputState(options);

        return new Promise((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    };

    const handleClose = () => {
        if (inputState.catchOnCancel && awaitingPromiseRef.current) {
            awaitingPromiseRef.current.reject();
        }

        setInputState(null);
    };

    const handleSubmit = (value) => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve(value);
        }

        setInputState(null);
    };

    return (
        <>
            <InputServiceContext.Provider
                value={ openInput }
                children={ children }
            />

            <InputDialog
                open={ Boolean(inputState) }
                onSubmit={ handleSubmit }
                onClose={ handleClose }
                { ...inputState }
            />
        </>
    );
};

export const withInput = (Component) => {
    const WithInput = (props) => {
        return (
            <InputServiceProvider>
                <Component { ...props } />
            </InputServiceProvider>
        );
    }

    return WithInput;
};
