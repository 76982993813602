import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios, { getStoreLink } from '../../axios';
import Button from '@material-ui/core/Button';

import fileSave from '../../shared/fileSave';

const useStyles = makeStyles({
    button: {
        marginRight: 25
    }
});

const EslButtons = (props) => {
    const [isImportLoading, setIsImportLoading] = useState(false);

    const importLinkRef = useRef();
    const importRef = useRef();

    const classes = useStyles();
    const { t } = useTranslation();

    const importFileClickHandler = () => {
        importRef.current.click();
    }

    const importFileHandler = (e) => {
        if (e.target.files) {
            setIsImportLoading(true);
            const file = e.target.files[0];
            const body = new FormData();
            body.append('file', file);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            axios.post(`${getStoreLink()}/labelsupload`, body, config)
                .then(() => {
                    setIsImportLoading(false);
                    props.onRefresh();
                    // for the second load
                    importRef.current.value = "";
                    alert(t('Esls.imported'));
                })
                .catch(() => {
                    setIsImportLoading(false);
                });
        }
    }

    const importLinksClickHandler = () => {
        importLinkRef.current.click();
    }

    const importLinksHandler = (e) => {
        if (e.target.files) {
            setIsImportLoading(true);
            const file = e.target.files[0];
            const body = new FormData();
            body.append('file', file);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            axios.post(`${getStoreLink()}/link_import`, body, config)
                .then(() => {
                    setIsImportLoading(false);
                    props.onRefresh();
                    // for the second load
                    importLinkRef.current.value = "";
                    alert(t('Esls.link_imported'));
                })
                .catch(() => {
                    setIsImportLoading(false);
                });
        }
    }

    const exportLinkHandler = () => {
        axios.get(`${getStoreLink()}/link`, {
            params: {
                type: "export"
            },
            responseType: 'blob'
        })
            .then((response) => {
                fileSave("links.csv", response.data);
            });
    }

    return (
        <>
            <Button
                className={ classes.button }
                variant="contained"
                color="primary"
                onClick={ importFileClickHandler }>
                { t('Esls.import') }</Button>
            <input
                ref={ importRef }
                accept=".csv"
                id="input-button-file"
                type="file"
                style={ { display: "none" } }
                onChange={ importFileHandler }
            />
            <Button
                className={ classes.button }
                variant="outlined"
                color="primary"
                onClick={ importLinksClickHandler }>
                { t('Esls.import_link') }</Button>
            <input
                ref={ importLinkRef }
                accept=".csv"
                id="input-button2-file"
                type="file"
                style={ { display: "none" } }
                onChange={ importLinksHandler }
            />
            <Button
                variant="outlined"
                color="primary"
                onClick={ exportLinkHandler }>
                { t('Esls.export_link') }
            </Button>
            { isImportLoading &&
                <div>
                    <CircularProgress />
                </div>
            }
        </>
    );
}

export default EslButtons;
