import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';

import ListRow from './ItemTableRowBase';
import ItemTableToolbar from './ItemTableToolbar';

const styles = () => ({
    root: {
        marginTop: 0
    },
    head: {
        backgroundColor: "#E53935"
    },
    headRow: {
        height: 30
    },
    headCell: {
        color: "#fff",
        paddingLeft: 14,
        paddingRight: 10,
        paddingTop: 4,
        paddingBottom: 4
    },
    sortLabel: {
        '&:hover': {
            color: "lightgrey",
        },
        '&:focus': {
            color: "lightgrey",
        },
    },
    activeSortLabel: {
        color: "lightgrey",
    },
});

class ItemTableBase extends Component {
    state = {
        page: 0,
        rowsPerPage: 25,
        order: "desc",
        orderBy: -1
    };

    changePageHandler = (event, page) => {
        this.setState({ page: +page });
    }

    changeRowsPerPageHandler = (event) => {
        this.setState({ rowsPerPage: +event.target.value });
    }

    selectAllClickHandler = (event) => {
        if (!event.target.checked) {
            this.props.setSelected([]);
        }
        else {
            const newSelecteds = this.props.data.map(n => n[0]);
            this.props.setSelected(newSelecteds);
        }
    }

    selectHandler = (id) => {
        const { selected } = this.props;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.props.setSelected(newSelected);
    }

    isSelected = id => this.props.selected ? this.props.selected.indexOf(id) !== -1 : false;

    createSortHandler = index => event => {
        let orderBy = index;
        let order = 'desc';

        if (this.state.orderBy === index && this.state.order === 'desc')
            order = 'asc';
        if (this.state.orderBy === index && this.state.order === 'asc')
            orderBy = -1;

        this.setState({ order, orderBy });
    };

    desc = (a, b, orderBy) => {
        // sort by undefined/null
        if (!b[orderBy] && a[orderBy])
            return -1;
        if (b[orderBy] && !a[orderBy])
            return 1;
        // sort by object
        if (b[orderBy] && a[orderBy] && b[orderBy].type && a[orderBy].type) {
            // HACK: add type
            if (b[orderBy].props.color < a[orderBy].props.color) {
                return 1;
            }
            if (b[orderBy].props.color > a[orderBy].props.color) {
                return -1;
            }
        }

        // base sort
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }

        return 0;
    }

    stableSort = (array, cmp) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    getSorting = (order, orderBy) => {
        return order === "desc" ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
    }

    render() {
        const { classes, t, selected } = this.props;
        const { page, rowsPerPage } = this.state;
        const rowCount = this.props.data.length;
        const numSelected = selected ? selected.length : 0;
        let shortcuts = [];
        const hasShortcuts = this.props.menu ? this.props.menu.findIndex((item) => item.isShortcut) !== -1 : null;
        if (hasShortcuts)
            this.props.menu.forEach((item) => {
                if (item.isShortcut)
                    shortcuts.push(item);
            });
        let mass = [];
        const hasMass = this.props.menu ? this.props.menu.findIndex((item) => item.isMass) !== -1 : null;
        if (hasMass)
            this.props.menu.forEach((item) => {
                if (item.isMass)
                    mass.push(item);
            });
        const emptyRows = rowCount <= rowsPerPage ? 0 : rowsPerPage - Math.min(rowsPerPage, rowCount - page * rowsPerPage);

        return (
            <>
                <ItemTableToolbar
                    numSelected={ numSelected }
                    menu={ mass } />

                <div style={ { overflowX: "scroll" } }>
                    <Table className={ classes.root }>
                        <TableHead className={ classes.head }>
                            <TableRow className={ classes.headRow }>
                                { this.props.isSelectable &&
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            indeterminate={ numSelected > 0 && numSelected < rowCount }
                                            checked={ numSelected === rowCount }
                                            onChange={ this.selectAllClickHandler }
                                        />
                                    </TableCell>
                                }
                                { this.props.menu &&
                                    <TableCell className={ classes.headCell }>
                                        <FontAwesomeIcon color="#fff" icon={ faCog } />
                                    </TableCell>
                                }
                                { this.props.menu && hasShortcuts &&
                                    <TableCell className={ classes.headCell }></TableCell>
                                }
                                { this.props.headers.map((value, index) => (
                                    <TableCell className={ classes.headCell } key={ index }>
                                        <TableSortLabel
                                            className={ classes.sortLabel }
                                            classes={ { active: classes.activeSortLabel } }
                                            active={ this.state.orderBy === index }
                                            direction={ this.state.order }
                                            onClick={ this.createSortHandler(index) }>
                                            { value }
                                        </TableSortLabel>
                                    </TableCell>
                                )) }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { this.stableSort(this.props.data, this.getSorting(this.state.order, this.state.orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    const rowId = row[0];
                                    const isItemSelected = this.isSelected(rowId);
                                    return (
                                        <ListRow
                                            key={ rowId }
                                            isItemSelected={ isItemSelected }
                                            isSelectable={ this.props.isSelectable }
                                            onSelect={ this.selectHandler }
                                            rowId={ rowId }
                                            menu={ this.props.menu }
                                            shortcuts={ shortcuts }
                                            data={ row }
                                            onDoubleClick={ this.props.onDoubleClick ? () => this.props.onDoubleClick(rowId) : null }
                                        />
                                    );
                                }) }
                            { emptyRows > 0 && (
                                <TableRow style={ { height: 51 * emptyRows } }>
                                    <TableCell colSpan={ 6 } />
                                </TableRow>
                            ) }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    labelRowsPerPage={ t('ItemTable.show') }
                                    labelDisplayedRows={ ({ from, to, count }) => `${from}-${to} ${t('ItemTable.of')} ${count}` }
                                    rowsPerPageOptions={ [5, 10, 25, 50] }
                                    colSpan={ 3 }
                                    count={ this.props.data.length }
                                    rowsPerPage={ rowsPerPage }
                                    page={ page }
                                    SelectProps={ {
                                        native: true,
                                    } }
                                    onChangePage={ this.changePageHandler }
                                    onChangeRowsPerPage={ this.changeRowsPerPageHandler }
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </div>
            </>
        );
    }
}

export default withTranslation()(withStyles(styles)(ItemTableBase));
