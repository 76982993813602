import { translateCell } from '../../const/i18n';

const rendererColumns = [
    {
        name: translateCell('Renderers.cpath'),
        field: "path",
        type: "string"
    }, {
        name: translateCell('Renderers.cstatus'),
        field: "status",
        type: "boolean"
    }, {
        name: translateCell('Renderers.cworkers'),
        field: "workers",
        type: "string"
    }, {
        name: translateCell('Renderers.ctasks'),
        field: "pendingTasks",
        type: "number"
    }, {
        name: translateCell('Renderers.cfontsCount'),
        field: "fontsCount",
        type: "number"
    }, {
        name: translateCell('Renderers.cantialiasing'),
        field: "antialiasing",
        type: "boolean"
    }, {
        name: translateCell('Renderers.cuptime'),
        field: "uptime",
        type: "string"
    }, {
        name: translateCell('Renderers.cversion'),
        field: "version",
        type: "string"
    }, {
        name: translateCell('Renderers.ctime'),
        field: "updateTime",
        type: "datetime"
    }
];

export default rendererColumns;
