import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    main: {
        width: 'auto',
        display: 'block',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(4),
        display: 'block',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    header: {
        paddingBottom: 20
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(),
    },
    submit: {
        marginTop: theme.spacing(3),
    },
}));

const SignIn = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const inputRef = React.useRef();

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            inputRef.current.focus();
        }, 100);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <div className={ classes.main }>
            <Paper className={ classes.paper }>
                <Typography className={ classes.header } variant="h1">{ t('SignIn.signin') }</Typography>
                <Typography variant="h2">{ t('SignIn.system') }</Typography>
                <form className={ classes.form } onSubmit={ props.onSubmit } noValidate>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="login">{ t('SignIn.username') }</InputLabel>
                        <Input
                            inputRef={ inputRef }
                            value={ props.data.login }
                            onChange={ props.onChange('login') }
                            id="login"
                            name="login"
                            autoComplete="login" />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="password">{ t('SignIn.password') }</InputLabel>
                        <Input
                            value={ props.data.password }
                            onChange={ props.onChange('password') }
                            name="password"
                            type="password"
                            id="password"
                            autoComplete="current-password" />
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={ classes.submit }>{ t('SignIn.signinbtn') }</Button>
                </form>
            </Paper>
        </div>
    );
}

export default SignIn;
