import { translateCell } from "../../const/i18n";

const storeFilterValues = [
    { value: "loading", name: translateCell('Stores.stateLoading') },
    { value: "ok", name: translateCell('Stores.stateOk') },
    { value: "error", name: translateCell('Stores.stateError') },
    { value: "pwd", name: translateCell('Stores.statePwd') }
];

const storeColumns = [
    {
        name: translateCell('Stores.cid'),
        field: "id",
        type: "number"
    }, {
        name: translateCell('Stores.cuserid'),
        field: "user_id",
        type: "string"
    }, {
        name: translateCell('Stores.cstate'),
        display: "picture_state",
        type: "icon",
        field: "state",
        filterValues: storeFilterValues
    }, {
        name: translateCell('Stores.cname'),
        field: "name",
        type: "string"
    }, {
        name: translateCell('Stores.ccount'),
        field: "eslCount",
        type: "number"
    }, {
        name: translateCell('Stores.cpath'),
        field: "path",
        type: "string"
    },
];

export default storeColumns;
