import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faSignOutAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useInput, withInput } from '../../services/InputService';
import { withTranslation } from 'react-i18next';

import packageJson from '../../../package.json';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import axios, { getStoreLink } from '../../axios';
import { routePaths } from '../../routePaths';
import { drawerWidth } from '../../const';
import logo from '../../assets/icon2_small.png';
import { rightsMenuItems, menuItems } from './MenuItems';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: "#000",
        backgroundColor: "#fff",
        height: 72
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    grow: {
        flexGrow: 1,
        display: 'flex'
    },
    toolbar: {
        marginLeft: 5,
        marginRight: 5,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: "#455A64",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRight: "none"
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        ...theme.mixins.toolbar,
        justifyContent: "space-between"
    },
    drawerClose: {
        margin: 15,
    },
    logo: {
        padding: 18,
        backgroundColor: "#fff"
    },
    bottomNavigation: {
        backgroundColor: "#455A64",
        color: "#fff"
    },
    selectedBottomNavigationAction: {
        color: "#fff",
        backgroundColor: "rgba(0, 0, 0, 0.14)"
    },
    list: {
        color: "#fff"
    },
    listIcon: {
        width: 30
    },
    listItem: {
        backgroundColor: "#455A64"
    },
    listItemText: {
        color: "#fff",
        textTransform: "uppercase"
    }
}));

const SideMenu = (props) => {
    const { t } = props;
    const [storeName, setStoreName] = useState(sessionStorage.getItem('namestore')
        ? sessionStorage.getItem('namestore')
        : t('SideMenu.esls'));

    const drawerOpenHandler = () => {
        props.onMenuStateChange(true);
    }

    const drawerCloseHandler = () => {
        props.onMenuStateChange(false);
    }

    const listItemClickHandler = (event, value) => {
        if (value === routePaths.Stores)
            sessionStorage.removeItem("namestore");
        props.history.push(value);
    }

    const storeNameHandler = () => {
        //axios.get(`${getStoreLink()}/settings/namestore`)
        let storeName = '';

        if (sessionStorage.getItem('namestore'))
            storeName = sessionStorage.getItem('namestore');

        inputService({
            catchOnCancel: false,
            title: t('SideMenu.storeName'),
            message: t('SideMenu.inputStoreName'),
            previous: storeName,
            isNumeric: false
        }).then((value) => {
            axios.put(`${getStoreLink()}/settings`, [{ key: "namestore", value: value }])
                .then(() => {
                    sessionStorage.setItem('namestore', value);
                    setStoreName(value);
                });
        });
    }

    const logoutHandler = () => {
        sessionStorage.removeItem("qpstkn");
        sessionStorage.removeItem("user_id");
        sessionStorage.removeItem("name");
        sessionStorage.removeItem("version");
        sessionStorage.removeItem("store");
        sessionStorage.removeItem("namestore");
        props.history.push(routePaths.SignIn, { signedOut: true });
    }

    const classes = useStyles();
    const inputService = useInput();
    const route = '/' + props.history.location.pathname.split('/')[1];

    let menuItemName = "";
    let menuIndex = menuItems.findIndex((item) => item.path === route);
    if (menuIndex !== -1)
        menuItemName = menuItems[menuIndex].name;
    else {
        menuIndex = rightsMenuItems.findIndex((item) => item.path === route);
        if (menuIndex !== -1)
            menuItemName = rightsMenuItems[menuIndex].name;
    }

    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                className={classNames(classes.appBar, {
                    [classes.appBarShift]: props.isMenuOpen,
                })}>
                <Toolbar className={classes.toolbar} disableGutters={!props.isMenuOpen}>
                    {!props.withoutMenu &&
                        <IconButton
                            color="inherit"
                            aria-label={t('SideMenu.openMenu')}
                            onClick={drawerOpenHandler}
                            className={classNames(classes.menuButton, props.isMenuOpen && classes.hide)}>
                            <FontAwesomeIcon icon={faBars} size="xs" />
                        </IconButton>
                    }
                    <div className={classes.grow}>
                        <Typography variant="h6" color="inherit" noWrap>{storeName}</Typography>
                        { /* TODO: Нормальный чек */}
                        {route !== routePaths.Central &&
                            <>
                                <Button onClick={storeNameHandler}>
                                    <FontAwesomeIcon icon={faEdit} size="sm" />
                                </Button>
                            </>
                        }
                        <Typography variant="h6" color="inherit" noWrap>{menuItemName ? `-> ${menuItemName}` : ''}</Typography>
                    </div>
                    <Typography variant="body1" color="inherit" noWrap>
                        {t('SideMenu.user')}: {sessionStorage.getItem("name")}
                        <br />
                        {t('SideMenu.version')}: {sessionStorage.getItem("version")}/{packageJson.version}</Typography>
                    <Button onClick={logoutHandler}>
                        <FontAwesomeIcon icon={faSignOutAlt} size="lg" />
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={props.isMenuOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}>
                <div className={classes.drawerHeader}>
                    <IconButton className={classes.drawerClose} onClick={drawerCloseHandler}>
                        <FontAwesomeIcon color="#fff" icon={faTimes} size="xs" />
                    </IconButton>
                    <img className={classes.logo} src={logo} alt="logo" />
                </div>
                <Divider />
                <BottomNavigation value={route}
                    onChange={listItemClickHandler}
                    showLabels={false}
                    className={classes.bottomNavigation} >
                    {rightsMenuItems.map((item) => (
                        <BottomNavigationAction
                            disabled={item.disabled}
                            classes={{ selected: classes.selectedBottomNavigationAction }}
                            key={item.name}
                            value={item.path}
                            icon={<FontAwesomeIcon size="lg" color="#fff" icon={item.icon} />} />
                    ))}
                </BottomNavigation>
                <Divider />
                <List className={classes.list} component="nav">
                    {menuItems.map((item) => (
                        <ListItem
                            className={classes.listItem}
                            key={item.name}
                            selected={route === item.path}
                            onClick={(e) => listItemClickHandler(e, item.path)}
                            button>
                            <ListItemIcon className={classes.listIcon}>
                                <FontAwesomeIcon color="white" icon={item.icon} size="lg" />
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.listItemText }} primary={item.name} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </div>
    );
}

export default withTranslation()(
    withErrorHandler(
        withRouter(
            withInput(SideMenu)
        ), axios)
);
