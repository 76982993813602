import React, { useState } from 'react';
import classNames from 'classnames';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { drawerWidth } from '../../../const';
import SideMenu from '../../SideMenu/SideMenu';

const useStyles = makeStyles((theme) => ({
    main: {
        flexGrow: 1,
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 90,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
        display: "block"
    },
    mainShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
    },
}));

const DefaultLayout = (props) => {
    const [isMenuOpen, setMenuOpenValue] = useState(false);

    const { component: Component, ...rest } = props;
    const classes = useStyles();

    return (
        <Route
            { ...rest }
            render={ (props) => (
                <>
                    <SideMenu
                        isMenuOpen={ isMenuOpen }
                        onMenuStateChange={ setMenuOpenValue }
                    />
                    <main className={ classNames(classes.main, {
                        [classes.mainShift]: isMenuOpen,
                    }) } >
                        <Component { ...props } />
                    </main>
                </>
            ) }
        />
    )
};

export default DefaultLayout;