import React from 'react';
import RotateDialog from '../components/Dialogs/RotateDialog';

const RotateServiceContext = React.createContext(Promise.reject);

export const useRotate = () =>
    React.useContext(RotateServiceContext);

export const RotateServiceProvider = ({ children }) => {
    const [rotateState, setRotateState] = React.useState(null);

    const awaitingPromiseRef = React.useRef();

    const openRotate = (options) => {
        setRotateState(options);

        return new Promise((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    };

    const handleClose = () => {
        if (rotateState.catchOnCancel && awaitingPromiseRef.current) {
            awaitingPromiseRef.current.reject();
        }

        setRotateState(null);
    };

    const handleSubmit = (value) => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve(value);
        }

        setRotateState(null);
    };

    return (
        <>
            <RotateServiceContext.Provider
                value={ openRotate }
                children={ children }
            />

            <RotateDialog
                open={ Boolean(rotateState) }
                onSubmit={ handleSubmit }
                onClose={ handleClose }
                { ...rotateState }
            />
        </>
    );
};

export const withRotate = (Component) => {
    const WithRotate = (props) => {
        return (
            <RotateServiceProvider>
                <Component { ...props } />
            </RotateServiceProvider>
        );
    }

    return WithRotate;
};
